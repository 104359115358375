<template>
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.0017 4C19.6817 4 17.455 5.08 16.0017 6.78667C14.5483 5.08 12.3217 4 10.0017 4C5.895 4 2.66833 7.22667 2.66833 11.3333C2.66833 16.3733 7.20167 20.48 14.0683 26.72L16.0017 28.4667L17.935 26.7067C24.8017 20.48 29.335 16.3733 29.335 11.3333C29.335 7.22667 26.1083 4 22.0017 4ZM16.135 24.7333L16.0017 24.8667L15.8683 24.7333C9.52167 18.9867 5.335 15.1867 5.335 11.3333C5.335 8.66667 7.335 6.66667 10.0017 6.66667C12.055 6.66667 14.055 7.98667 14.7617 9.81333H17.255C17.9483 7.98667 19.9483 6.66667 22.0017 6.66667C24.6683 6.66667 26.6683 8.66667 26.6683 11.3333C26.6683 15.1867 22.4817 18.9867 16.135 24.7333Z" fill="#C90D46"/>
  </svg>
</template>

<script>
export default {
  name: 'favoriteBorderIcon'
}
</script>
