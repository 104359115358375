<template>
  <v-modal @closeModal="$emit('resetModal')" @submitModal="onSubmit">
    <div class="modal__container">
      <div class="modal__header">
        <arrow-back-icon class="modal__header-icon" @click="$emit('showDetailModal')" />
        <v-title>Отклонить запрос</v-title>
      </div>
      <form class="modal-form" @submit.prevent="onSubmit">
        <v-textarea
          class="modal-form__field modal-form__textarea"
          label="Комментарий к запросу"
          v-model="comment"
          :error="cError"
          :handleChange="cChange"
          :mask="allSymbolsMask"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <div class="modal-form__errors" v-if="firstError">
          <v-error class="modal-form__error" :text="textError(firstError)" />
        </div>
        <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Отправить" class="modal-form__submit" />
      </form>
    </div>
  </v-modal>
</template>

<script>
import { computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { textError } from '@/utils/utils'
import { allSymbolsMask } from '@/utils/inputMasks'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'

export default {
  name: 'ClientRejectModal',
  emits: ['passValues', 'resetModal', 'showDetailModal'],
  components: { ArrowBackIcon },
  setup(_, { emit }) {
    const schema = yup.object({
      comment: yup.string()
        .trim()
        .required('Введите комментарий')
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Комментарий к запросу не может быть меньше 2 символов'
        })
    })

    const { handleSubmit, isSubmitting, errors } = useForm({ validationSchema: schema })

    const { value: comment, errorMessage: cError, handleChange: cChange } = useField('comment', undefined, { validateOnValueUpdate: false })

    const firstError = computed(() => cError.value)

    const onSubmit = handleSubmit(async values => {
      emit('passValues', values)
    })

    return {
      textError, allSymbolsMask,
      confirm, isSubmitting, errors, firstError, onSubmit,
      comment, cError, cChange
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__container {
    padding: 24px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-icon {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &-form {
    width: 880px;
    margin-top: 32px;

    &__field {
      margin-bottom: 16px;
    }

    &__loading {
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;

      &--button {
        align-self: center;
        margin-left: 8px;
      }
    }

    &__errors {
      display: flex;
      flex-wrap: wrap;
      margin-top: 36px;
      margin-bottom: -38px;
    }

    &__error {
      white-space: nowrap;
      margin-right: 4px;
    }

    &__submit {
      margin-top: 36px;
      align-self: flex-start;
    }
  }
}
</style>
