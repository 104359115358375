<template>
  <div class="cart-main">
    <div class="stepper">
      <span class="stepper__item" :class="{'stepper__item_active': component === 'cart'}">Корзина</span>
      <span class="stepper__item stepper__arrow"><img :src="arrowCart" alt=""></span>
      <span class="stepper__item" :class="{'stepper__item_active': component !== 'cart'}">Оформление заказа</span>
      <span class="stepper__item stepper__arrow"><img :src="arrowCart" alt=""></span>
      <span class="stepper__item">Согласование заказа</span>
    </div>
    <keep-alive>
      <transition :name="component === 'cart' ? 'transition' : 'reverse-transition'" mode="out-in">
        <component :is="component" @setForm="form => component = form"></component>
      </transition>
    </keep-alive>
  </div>
</template>

<script>
import { ref } from 'vue'
import Cart from '@/views/UserPages/Cart/Cart.vue'
import SoftwareHardwareForm from '@/components/CartForms/SoftwareHardwareForm.vue'
import SoftwareForm from '@/components/CartForms/SoftwareForm.vue'
import HardwareForm from '@/components/CartForms/HardwareForm.vue'
import arrowCart from '@/assets/icons/arrow_cart.svg'

export default {
  name: 'CartMain',
  components: { Cart, SoftwareHardwareForm, SoftwareForm, HardwareForm },
  setup() {
    const component = ref('cart')

    return { arrowCart, component }
  }
}
</script>

<style lang="scss" scoped>
.cart-main {
  min-width: 1100px;
  max-width: 1380px;
  min-height: calc(100vh - 272px);
  margin: auto;
  padding: 32px 100px 80px;
  background-color: $color-light-white;

  .stepper {
    display: flex;
    justify-content: center;

    &__item {
      font-size: 14px;
      line-height: 17px;
      color: $color-grey;

      &_active {
        color: $color-black;
      }
    }

    &__arrow {
      display: flex;
      align-items: center;
      margin: 0 20px;
    }
  }
}

.transition-leave-active,
.transition-enter-active {
  transition: all 0.3s ease-out;
}

.transition-enter-from {
  transform: translateX(-400px);
  opacity: 0;
}

.transition-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.reverse-transition-leave-active,
.reverse-transition-enter-active {
  transition: all 0.3s ease-out;
}

.reverse-transition-enter-from {
  transform: translateX(400px);
  opacity: 0;
}

.reverse-transition-leave-to {
  transform: translateX(-400px);
  opacity: 0;
}
</style>
