<template>
  <div class="user-block" @click="userBlockClickHandler" v-click-outside="clickOutside">
    <div class="user-block__container">
      <span class="user-block__user">{{user.firstName}} {{user.secondName}}</span>
      <drop-down-icon class="user-block__icon"/>
    </div>
    <div v-if="isActive" class="user-block__user-menu" >
      <v-user-menu :profileLink="`/user-info/${user.id}`" @closeUserMenu="isActive = false"></v-user-menu>
    </div>
  </div>
</template>

<script>
import DropDownIcon from '@/components/icons/dropdown-icon'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'

export default {
  name: 'UserBlock',
  components: { DropDownIcon },
  setup() {
    const store = useStore()
    const isActive = ref(false)
    onMounted(() => store.dispatch('auth/getInfo'))
    const user = computed(() => store.getters['auth/getUser'])
    const isAdmin = () => {
      return user.value.role === 'admin'
    }
    const userBlockClickHandler = () => {
      isActive.value = !isActive.value
    }
    const clickOutside = () => {
      isActive.value = false
    }
    return { isAdmin, isActive, user, userBlockClickHandler, clickOutside }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables";

.user-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &__user {
    max-width: 500px;
    color: $color-red;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__client {
    color: $color-grey;
    font-size: 14px;
    line-height: 17px;
  }

  &__icon {
    margin-left: 4px;
    stroke: $color-red;
  }

  &__user-menu {
    position: absolute;
    top: 125%;
  }
}
</style>
