<template>
  <form class="form" @submit.prevent="checkEmail">
    <h1>Проверка email</h1>
    <v-input
      placeholder="E-mail"
      v-model="email"
      :error="eError"
      :handleChange="eChange"
      :mask="emailMask"
      :maxlength="64"
      :class-name="'email'"
      :disabled="isSubmitting"/>
    <v-error v-if="eError" :text="eError"></v-error>
    <v-error v-if="emailError" :text="emailError"></v-error>
    <v-button
      v-if="timer === 0"
      :type="'submit'"
      :size="'l'"
      text="Отправить код для входа"
      :disabled="!email || isSubmitting">
    </v-button>
    <v-button
      v-if="timer !== 0"
      disabled
      :size="'l'"
      :text="`Повторить можно через ${minutesBlockButton === '00' && secondsBlockButton === '00' ? '' : `${minutesBlockButton}:${secondsBlockButton}`}`" />
  </form>
</template>

<script>
import { useStore } from 'vuex'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { computed, ref, watch } from 'vue'
import { emailMask } from '@/utils/inputMasks'
import { useTimer } from '@/utils/utils'

export default {
  name: 'emailForm',
  setup() {
    const { handleSubmit, isSubmitting } = useForm()
    const store = useStore()
    const emailError = computed(() => store.getters['auth/getErrors'].emailError)
    const timer = computed(() => store.getters['auth/getTimerFromServer'])
    const minutesBlockButton = ref('')
    const secondsBlockButton = ref('')
    const { value: email, errorMessage: eError, handleChange: eChange } = useField('email', yup
      .string()
      .trim()
      .required('Пожалуйста, введите email')
      .matches(/^.+@.+\..+$/, 'Невалидный email')
      .min(6, 'Email не может быть меньше 6 символов'), { validateOnValueUpdate: false })
    const isLoading = () => store.state.auth.isLoading
    const checkEmail = handleSubmit(async () => {
      try {
        await store.dispatch('auth/checkEmail', email.value)
      } catch (error) {
        console.log(error)
      }
    })
    watch(timer, () => {
      useTimer(timer.value, minutesBlockButton, secondsBlockButton)
    }, { immediate: true, deep: true })
    watch([minutesBlockButton, secondsBlockButton], () => {
      if (minutesBlockButton.value === '00' && secondsBlockButton.value === '00') {
        store.commit('auth/setTimerFromServer', 0)
      }
    })
    watch(email, () => {
      if (emailError.value) {
        store.commit('auth/setEmailError', null)
        store.commit('auth/setTimerFromServer', 0)
      }
    })
    return {
      email, eError, eChange,
      checkEmail,
      isLoading,
      emailError,
      isSubmitting,
      emailMask,
      timer, minutesBlockButton, secondsBlockButton
    }
  }
}
</script>

<style scoped lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    & > :not(&:first-child) {
        align-self: center;
    }
  }
</style>
