<template>
  <div class="edit-user">
    <div class="edit-user__header">
      <arrow-back-icon class="edit-user__header--back" @click="$router.push({ name: 'users-list', params: {clientId: $route.params.clientId} })" />
      <v-title>Редактирование пользователя</v-title>
    </div>
    <user-edit-form />
  </div>
</template>
<script>
import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import UserEditForm from '@/components/UserEditForm'
export default {
  name: 'EditAdmin',
  components: { UserEditForm, ArrowBackIcon }
}
</script>
<style scoped lang="scss">
 .edit-user {
   min-width: 900px;
   max-width: 1180px;
   padding-top: 36px;
   margin: 0 auto;

   &__header {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     margin-bottom: 25px;
     &--back {
       margin-right: 12px;
       cursor: pointer;
     }
   }
 }
</style>
