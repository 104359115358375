<template>
  <table class="admins-table">
    <thead class="admins-table__thead">
      <tr class="admins-table__thead--row">
        <td>Фамилия, Имя, Отчество</td>
        <td>E-mail (логин)</td>
        <td>Номер Телефона</td>
        <td></td>
      </tr>
    </thead>
    <tbody class="admins-table__tbody">
      <tr v-for="admin in admins" :key="admin.id" class="admins-table__tbody--row">
        <td class="admins-table__tbody--name" :class="{ 'admins-table__tbody--name_disabled': admin.status === 'blocked'  }">
          {{ admin?.secondName}} {{admin?.firstName}}{{admin?.patronymic ? ' ' + admin?.patronymic : ''}}
        </td>
        <td class="admins-table__tbody--email" :class="{ 'admins-table__tbody--email_disabled': admin.status === 'blocked'  }">
          {{ admin.email }}
        </td>
        <td class="admins-table__tbody--phone" :class="{ 'admins-table__tbody--phone_disabled': admin.status === 'blocked'  }">
          {{admin.phone}}
        </td>
        <td class="admins-table__tbody--button">
          <edit-icon class="admins-table__tbody--edit" @click="$router.push({name: 'edit-admin', params:{id: admin.id}})"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import EditIcon from '@/components/icons/edit-icon'
export default {
  name: 'AdminsTable',
  components: { EditIcon },
  props: ['admins']
}
</script>
<style scoped lang="scss">
@import "../assets/styles/variables";
.admins-table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 48px;

  tbody:before {
    content:"@";
    display:block;
    line-height:8px;
    text-indent:-99999px;
  }

  &__thead {
    height: 33px;
    font-family: "Proxima Regular",sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: $color-grey;
    background-color: $color-white;
    & td {
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
    }
    & td:first-child {
      padding-left: 32px;
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    & td:last-child {
      padding-right: 32px;
      border: 1px solid $color-border-grey;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }

  &__tbody {
    font-size: 16px;
    line-height: 19px;
    color: $color-black;
    &--name {
      font-weight: bold;
    }

    &--name {
      &_disabled {
        color: $color-grey;
      }
    }
    &--email {
      color: $color-red;

      &_disabled {
        color: $color-grey;
      }
    }
    &--phone {
      &_disabled {
        color: $color-grey;
      }
    }
    &--row {
      height: 56px;
    }
    &--edit {
    cursor: pointer;
    }

    &--button {
      text-align: right;
    }
    & td{
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
      background-color: $color-white;

      &:first-child {
        padding-left: 32px;
        border: 1px solid $color-border-grey;
        border-right: 0;
      }

      &:last-child {
        padding-right: 32px;
        border: 1px solid $color-border-grey;
        border-left: 0;
      }
    }

    & tr:first-child {
      td:first-child {
        border-radius: 4px 0 0 0;
      }
      td:last-child {
        border-radius: 0 4px 0 0;
      }
    }

    & tr:last-child {
      td:first-child {
        border-radius: 0 0 0 4px;
      }
      td:last-child {
        border-radius: 0 0 4px 0;
      }
    }

    tr:not(:last-child) td{
      border-bottom: 0;
    }

  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 30px;
    max-width: 300px;

    &:not(:last-child) {
      padding-right: 30px;
    }

    &:last-child {
      width: 5%;
    }
  }
}
</style>
