<template>
  <div class="admin-header">
    <div class="admin-header__body">
      <div class="admin-header__container">
        <img :src="require('../assets/images/header_logo.svg')" alt="header-logo" class="admin-header__logo">
        <v-navigation :navs="navs"/>
      </div>
      <admin-block/>
    </div>
  </div>
</template>
<script>

import AdminBlock from '@/components/AdminBlock'
export default {
  name: 'AdminHeader',
  components: { AdminBlock },
  data() {
    return {
      navs: [
        { id: 1, link: '/admin/clients', text: 'Клиенты' },
        { id: 2, link: '/admin/administrators', text: 'Администраторы' }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
  .admin-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2E2E2E;
    height: 70px;
    width: 100%;

    &__body {
      display: flex;
      justify-content: space-between;
      align-self: center;
      min-width: 900px;
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__logo {
      margin-right: 130px;
    }
  }
</style>
