<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <form class="user-create-form" @submit="onSubmitFromFile">
    <div class="user-create-form__container">
      <v-file-picker
        ref="olo"
        v-model="filePicker"
        name="file"
        label="Файл с пользователями"
        :error="fpError"
        :handleChange="fpChange"
        :disabled="isSubmitting"
        accept=".xls, .xlsx"
        description="Файл формата «Эксель», до 10 МБ."
        :example="require('../assets/docs/Пример_файла.xlsx')" />
      <v-button v-if="!filePicker" type="button" :size="'m'" text="Выбрать файл" @click="$refs.olo.pickerClickHandler()" class="user-create-form__choose--file"></v-button>
      <v-border-button v-else type="button" :size="'m'" text="Удалить файл" @click="$refs.olo.deleteFile()" class="user-create-form__choose--file"></v-border-button>
    </div>
    <v-error
      class="user-create-form__error"
      v-if="fpError"
      :text="textError(fpError)"/>
    <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Добавить" class="user-create-form__submit"></v-button>
  </form>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { checkFormats, maxSizeMb } from '@/utils/yup-helpers'
import { textError } from '@/utils/utils'

export default {
  name: 'UserCreateFormFromFile',
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isLoading = computed(() => store.getters['users/isLoading'])

    const { handleSubmit, isSubmitting } = useForm()

    const { value: filePicker, errorMessage: fpError, setErrors: fpSetErrors, handleChange: fpChange } = useField('filePicker', yup
      .mixed()
      .test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10))
      .test('format', 'Неверный формат', checkFormats(['xls', 'xlsx'])), { validateOnValueUpdate: false })

    const onSubmitFromFile = handleSubmit(async value => {
      const formData = new FormData()
      formData.append('employee', value.filePicker)
      const data = {
        clientId: route.params.clientId,
        formData
      }
      try {
        await store.dispatch('users/createUserFromFile', data)
        await router.push({ name: 'users-list', params: { clientId: data.clientId } })
      } catch (error) {
        fpSetErrors(error)
        console.log(error)
      }
    })

    return {
      isSubmitting, onSubmitFromFile, textError,
      filePicker, fpError, fpChange,
      isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.user-create-form {
  width: 550px;
  display: flex;
  flex-direction: column;

  &__loading {
    display: flex;
    align-items: flex-start;
  }

  &__choose--file {
    margin-top: 0;
    margin-left: 8px;
    align-self: center;
  }

  &__role {
    margin-bottom: 16px;
  }

  &__error {
    align-self: flex-start;
  }

  &__submit {
    margin-top: 20px;
    align-self: flex-start;
  }

  &__container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }
}

.v-input.v-input__field {
  margin-bottom: 16px;
}
</style>
