<template>
  <div class="edit-admin">
    <div class="edit-admin__header">
      <arrow-back-icon class="edit-admin__header--back" @click="$router.push({name: 'administrators'})"/>
      <v-title>Редактирование администратора</v-title>
    </div>
    <admin-edit-form/>
  </div>
</template>
<script>
import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import AdminEditForm from '@/components/AdminEditForm'
export default {
  name: 'EditAdmin', components: { AdminEditForm, ArrowBackIcon }
}
</script>
<style scoped lang="scss">
 .edit-admin {
   min-width: 900px;
   max-width: 1180px;
   padding-top: 36px;
   margin: 0 auto;

   &__header {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     margin-bottom: 25px;
     &--back {
       margin-right: 12px;
       cursor: pointer;
     }
   }
 }
</style>
