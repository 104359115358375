<template>
  <v-modal @closeModal="openDetailModal()" @submitModal="onConfirm">
    <p class="modal-confirm__title">{{ decision === 'agree' ? 'Согласовать запрос?' : 'Отклонить запрос?' }}</p>
    <div>
      <v-border-button class="modal-confirm__button-yes" type="button" size="m" text="Да" @click="onConfirm" />
      <v-button type="button" size="m" text="Нет" @click="openDetailModal()" />
    </div>
  </v-modal>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'UserConfirmModal',
  props: ['decision', 'choosenId', 'page', 'limit'],
  emits: ['showNoticeModal', 'setChoosenId', 'setModal'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()

    const openDetailModal = () => {
      emit('setChoosenId', route.query.number)
      emit('setModal', route.query.number)
    }

    const onConfirm = async () => {
      try {
        await store.dispatch('requests/agreement', {
          id: props.choosenId,
          decision: props.decision,
          limit: props.limit || 10,
          page: props.page || 1
        })
        emit('showNoticeModal')
      } catch (error) {
        console.log(error)
      }
    }

    return { openDetailModal, onConfirm }
  }
}
</script>

<style lang="scss" scoped>
.modal-confirm {
  &__title {
    width: 225px;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 16px;
  }

  &__button-yes {
    margin-right: 8px;
  }
}
</style>
