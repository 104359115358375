<template>
  <div class="admin-block" @click="adminBlockClickHandler" v-click-outside="clickOutside">
    <div class="admin-block__container">
      <span class="admin-block__user">{{user.firstName}} {{user.secondName}}</span>
      <drop-down-icon-white class="admin-block__icon"/>
    </div>
    <span class="admin-block__role">{{isAdmin() ? 'Администратор' : user.role}}</span>
    <div v-if="isActive" class="admin-block__user-menu" >
      <v-user-menu @closeUserMenu="isActive = false"></v-user-menu>
    </div>
  </div>
</template>
<script>
import DropDownIconWhite from '@/components/icons/dropdown-icon-white'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import clickOutside from '@/directives/click-outside'

export default {
  name: 'AdminBlock',
  components: { DropDownIconWhite },
  directives: { clickOutside },
  setup() {
    const store = useStore()
    const isActive = ref(false)
    onMounted(() => store.dispatch('auth/getInfo'))
    const user = computed(() => store.getters['auth/getUser'])
    const isAdmin = () => {
      return user.value.role === 'admin'
    }
    const adminBlockClickHandler = () => {
      isActive.value = !isActive.value
    }
    const clickOutside = () => {
      isActive.value = false
    }
    return { isAdmin, user, adminBlockClickHandler, isActive, clickOutside }
  }

}
</script>
<style scoped lang="scss">
@import "../assets/styles/variables";
  .admin-block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    &__user {
      color: $color-white;
      font-family: "Proxima Bold",sans-serif;
      font-size: 18px;
      line-height: 22px;
    }

    &__role {
      color: $color-grey;
      font-family: "Proxima Regular",sans-serif;
      font-size: 14px;
      line-height: 17px;
    }

    &__icon {
      margin-left: 4px;
    }

    &__user-menu {
      position: absolute;
      top: 125%;
    }
  }
</style>
