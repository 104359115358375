<template>
  <nav class="v-sub-navigation">
    <ul class="v-sub-navigation__list">
      <router-link v-for="nav in navs" :key="nav.id" :to="{name: nav.nameLink}" custom
                   v-slot="{ href=nav.nameLink, navigate, isActive, isExactActive }">
        <li
          :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active', 'v-sub-navigation__item']">
          <a :href="href" @click="navigate" class="v-sub-navigation__link">
            <span class="tabs__item--text">{{ nav.text }}</span>
          </a>
        </li>
      </router-link>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'vSubNavigation', props: ['navs']
}
</script>
<style scoped lang="scss">
@import '../../assets/styles/variables.scss';

.v-sub-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 36px;
    overflow: hidden;
    border-radius: 32px;
  }

  &__item {
    list-style-type: none;
    font-size: 16px;
    line-height: 19px;
    color: $color-black;
    background-color: $color-white;
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 7px 16px;
    height: 36px;
    border: 1px solid $color-red;

    &:not(:last-child) {
      border-right: none;
    }

    &:last-child {
      border-radius: 0 32px 32px 0;
      & span {
        padding-right: 24px;
      }
    }

    &:first-child {
      border-radius: 32px 0 0 32px;
      & span {
        margin-left: 24px;
      }
    }

    &.active {
      color: $color-white;
      background-color: $color-red;
      font-weight: 700;
    }
  }

  &__link {
    font-style: normal;
    text-decoration: none;
    color: $color-black;
    user-select: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .router-link-active {
    background-color: $color-red;
    font-weight: bold;

    & a {
      font-weight: bold;
      //text-shadow: 1px 0 0 $color-white;
      color: $color-white;
    }
  }
}
</style>
