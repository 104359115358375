<template>
  <li class="card" @click="cardClickHandler(product.id)">
    <modal-create-request v-if="showModal" @closeModal="showModal = false" :productName="product.name" />
    <img class="card__img" :src="product.image || emptyImage" alt="" />
    <p class="card__species">{{ product.vendor }}</p>
    <p class="card__title" v-html="product.name"></p>
    <p class="card__description" v-html="product.shortDescription"></p>
    <p class="card__price" :class="{ 'card__price_hide': !product.price }">{{ currency(product.price) }} ₽</p>
    <div class="card__empty-space"></div>
    <div>
      <v-green-button v-if="isAddedToCart" type="button" size="m" text="В корзине" :isIcon="true" @click.stop="$router.push({ name: 'cart' })"></v-green-button>
      <v-button v-else-if="!product.price && product.type === 'product'" type="button" :size="'m'" text="Запросить цену" @click.stop="showModal = true"></v-button>
      <v-button v-else-if="product.type === 'family'" type="button" :size="'m'" text="Выбрать лицензию"></v-button>
      <v-button v-else-if="product.type === 'product'" type="button" :size="'m'" text="В корзину" :disabled="disabled" @click.stop="addProductToCart(product.id, product.minQuantity)"></v-button>
    </div>
  </li>
</template>

<script>
import { onMounted, onUpdated, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import currency from '@/utils/currency-formatter.js'
import emptyImage from '../assets/images/empty.jpg'
import ModalCreateRequest from '@/components/ModalCreateRequest.vue'

export default {
  name: 'CatalogPdoductCard',
  props: ['product', 'cartIds'],
  components: { ModalCreateRequest },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const showModal = ref(false)
    const isAddedToCart = ref(false)
    const disabled = ref(false)

    const cardClickHandler = id => {
      if (props.product.type === 'product') {
        router.push({ name: 'product', params: { productId: id } })
      } else {
        router.push({ name: 'product-family', params: { productFamilyId: id } })
      }
    }

    const addProductToCart = async (id, minQuantity) => {
      disabled.value = true
      const response = await store.dispatch('cart/addProduct', { productId: id, quantity: minQuantity || 1 })
      if (response.status === 204) {
        isAddedToCart.value = true
        disabled.value = false
      }
    }

    onMounted(() => {
      if (props.cartIds.includes(props.product.id)) {
        isAddedToCart.value = true
      }
    })

    onUpdated(() => {
      if (props.cartIds.includes(props.product.id)) {
        isAddedToCart.value = true
      }
    })

    return {
      currency, cardClickHandler, emptyImage, addProductToCart, isAddedToCart, showModal, disabled
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: $color-light-white;
  }

  &__img {
    height: 170px;
    width: 100%;
    object-fit: contain;
  }

  &__species {
    font-size: 14px;
    line-height: 17px;
    color: rgba($color: $color-black, $alpha: 0.4);
    margin-top: 14px;
  }

  &__title {
    width: 100%;
    max-height: 44px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin: 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__description {
    width: 100%;
    height: 51px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__price {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;

    &_hide {
      opacity: 0;
    }
  }

  &__empty-space {
    flex-grow: 1;
  }
}
</style>
