<template>
  <div class="home">
    <admin-header />
    <router-view></router-view>
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader'

export default {
  name: 'adminHome',
  components: { AdminHeader }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/variables";
 .home {
   background-color: $color-light-white;
   min-height: 100vh;
 }
</style>
