<template>
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.5" y1="8.49918" x2="11.5" y2="8.49918" stroke-linecap="round"/>
    <line x1="0.5" y1="5.49918" x2="11.5" y2="5.49918" stroke-linecap="round"/>
    <line x1="0.5" y1="2.49918" x2="11.5" y2="2.49918" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'burgerIcon'
}
</script>
