<template>
  <modal-create-request v-if="showModal" @closeModal="showModal = false" :productName="item.name" />
  <li class="product-family__item">
    <div class="product-family__item-description">
      <p class="product-family__item-version" v-html="item.name"></p>
      <p class="product-family__item-licence">лицензия {{ item.sku }}</p>
    </div>
    <p v-if="item.price" class="product-family__item-price">{{ currency(item.price) }} ₽/шт</p>
    <v-counter-input
      v-if="item.price"
      class="product-family__item-counter"
      v-model:counter.number="counter"
      :minQuantity="item.minQuantity"
      :maxQuantity="item.maxQuantity"
    />
    <p v-if="item.price" class="product-family__item-sum">{{ currency(sum) }} ₽</p>
    <div class="product-family__item-buttons">
      <v-button v-if="!item.price" type="button" :size="'l'" text="Запросить цену" @click.stop="showModal = true"></v-button>
      <v-button v-else-if="!isAddedToCart" type="button" :size="'l'" text="В корзину" :disabled="(counter < (item.minQuantity || 1)) || (counter > (item.maxQuantity || 999999)) || disabled" @click.stop="addProductToCart"></v-button>
      <v-green-button v-else type="button" size="l" text="В корзине" :isIcon="true" @click.stop="$router.push({ name: 'cart' })"></v-green-button>
    </div>
  </li>
</template>

<script>
import { computed, onMounted, onUpdated, ref, watch } from 'vue'
import { useStore } from 'vuex'

import currency from '@/utils/currency-formatter.js'
import ModalCreateRequest from '@/components/ModalCreateRequest.vue'

export default {
  name: 'ProductFamilyItem',
  props: ['item', 'cartIds'],
  components: { ModalCreateRequest },
  setup(props) {
    const store = useStore()

    const showModal = ref(false)
    const counter = ref()
    const sum = computed(() => +props.item.price * +counter.value)
    const isAddedToCart = ref(false)
    const disabled = ref(false)

    const addProductToCart = async () => {
      disabled.value = true
      const response = await store.dispatch('cart/addProduct', { productId: props.item.id, quantity: counter.value })
      if (response.status === 204) {
        isAddedToCart.value = true
        disabled.value = false
      }
    }

    onMounted(() => {
      if (props.cartIds.includes(props.item.id)) {
        isAddedToCart.value = true
      }
    })

    onMounted(() => {
      if (props.item.quantity) {
        counter.value = props.item.quantity
        isAddedToCart.value = true
      } else if (props.item.minQuantity) {
        counter.value = props.item.minQuantity
      } else {
        counter.value = 1
      }
    })

    onUpdated(() => {
      if (props.cartIds.includes(props.item.id)) {
        isAddedToCart.value = true
      }
    })

    watch(counter, async () => {
      if (counter.value < (props.item.minQuantity || 1) || counter.value > (props.item.maxQuantity || 999999)) {
        return
      }
      if (isAddedToCart.value) {
        await store.dispatch('cart/changeQuantity', { productId: props.item.id, quantity: counter.value })
      }
    })

    return { showModal, sum, counter, isAddedToCart, currency, disabled, addProductToCart }
  }
}
</script>

<style lang="scss" scoped>
.product-family__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  font-size: 20px;
  line-height: 24px;
  color: $color-black;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border-grey;
  }

  &-description {
    min-width: 1px;
    flex-grow: 1;
  }

  &-version {
    max-height: 42px;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 4px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &-licence {
    font-size: 14px;
    line-height: 17px;
    color: $color-red;
  }

  &-price {
    min-width: 140px;
    text-align: end;
    white-space: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-counter {
    justify-content: center;
    margin: 0 12px;
  }

  &-sum {
    min-width: 115px;
    text-align: start;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    min-width: 195px;
    padding-left: 16px;
  }
}
</style>
