<template>
  <div class="edit-client">
    <div class="edit-client__header">
      <arrow-back-icon class="edit-client__header--back" @click="$router.push({ name: 'client', params: { clientId: $route.params.clientId } })"/>
      <v-title>Редактирование клиента</v-title>
    </div>
    <client-edit-form/>
  </div>
</template>

<script>
import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import ClientEditForm from '@/components/ClientEditForm'

export default {
  name: 'EditClient',
  components: { ClientEditForm, ArrowBackIcon }
}
</script>

<style scoped lang="scss">
 .edit-client {
   min-width: 900px;
   max-width: 1180px;
   margin: 0 auto;

   &__header {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     margin-bottom: 25px;

     &--back {
       margin-right: 12px;
       cursor: pointer;
     }
   }
 }
</style>
