<template>
  <table class="requests-table">
    <thead class="requests-table__thead">
      <tr class="requests-table__thead--row">
        <td>№</td>
        <td>Дата</td>
        <td>Наименование товара</td>
        <td>Комментарий</td>
        <td v-if="isCoordinating">Пользователь</td>
        <td>Файл</td>
        <td>КП</td>
        <td>Комментарий</td>
      </tr>
    </thead>
    <tbody class="requests-table__tbody">
      <tr
        v-for="request in requests"
        :key="request.number"
        class="requests-table__tbody--row"
        :class="{ 'requests-table__tbody--row_disabled': status === 'rejected' }"
        @click="openDetailModal(request.number)"
      >
        <td class="requests-table__tbody--number">{{ request.number }}</td>
        <td class="requests-table__tbody--date">{{ request.createdDate }}</td>
        <td class="requests-table__tbody--name" :class="{ 'requests-table__tbody--name_coordinator': isCoordinating }">{{ request.name }}</td>
        <td class="requests-table__tbody--comment" :class="{ 'requests-table__tbody--comment_coordinator': isCoordinating }">{{ request.comment }}</td>
        <td v-if="isCoordinating" class="requests-table__tbody--user">{{ request.userName }}</td>
        <td>
          <a class="requests-table__tbody--file" :class="{ 'requests-table__tbody--file_disabled': status === 'rejected' }" v-if="request.file" :href="request.file" target="_blank" download @click.stop>
            <attach-icon class="requests-table__tbody--file-icon" />
            <span class="requests-table__tbody--file-name">{{ request.filename }}</span>
          </a>
        </td>
        <td class="requests-table__tbody--offer">
          <p v-if="status !== 'working' && request.commercialOffer" :class="['offer-status', `offer-status_${status}`]">КП</p>
        </td>
        <td class="">
          <img v-if="status !== 'working' && request.commentAdmin" :src="CommentIcon" alt="">
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useRouter } from 'vue-router'

import AttachIcon from '@/components/icons/attach-icon'
import CommentIcon from '@/assets/icons/comment.svg'

export default {
  name: 'RequestsTable',
  components: { AttachIcon },
  emits: ['setChoosenId', 'setModal'],
  props: ['requests', 'status', 'isCoordinating'],
  setup(props, { emit }) {
    const router = useRouter()

    const openDetailModal = number => {
      emit('setChoosenId', number)
      emit('setModal', number)
      router.push({ query: { status: props.status, number } })
    }

    return { CommentIcon, openDetailModal }
  }
}
</script>

<style lang="scss" scoped>
.requests-table {
  width: 100%;
  border-spacing: 0;

  tbody:before {
    content:"@";
    display:block;
    line-height:8px;
    text-indent:-99999px;
  }

  &__thead {
    height: 33px;
    font-family: "Proxima Regular",sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: $color-grey;
    background-color: $color-white;

    & td {
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & td:first-child {
      padding-left: 20px;
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }

    & td:last-child {
      padding-right: 20px;
      border: 1px solid $color-border-grey;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }

  &__tbody {
    font-size: 14px;
    line-height: 17px;
    color: $color-black;

    &--row {
      height: 40px;
      cursor: pointer;

      &_disabled {
        .requests-table__tbody--number,
        .requests-table__tbody--date,
        .requests-table__tbody--name,
        .requests-table__tbody--user,
        .requests-table__tbody--comment {
          color: $color-grey;
          stroke: $color-grey;
          fill: $color-grey;
        }
      }
    }

    &--number,
    &--date,
    &--name,
    &--comment,
    &--user {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--date {
      width: fit-content;
    }

    &--name {
      max-width: 300px;
      font-weight: 700;

      &_coordinator {
        max-width: 250px;
      }
    }

    &--comment {
      max-width: 450px;

      &_coordinator {
        max-width: 250px;
      }
    }

    &--user {
      max-width: 250px;
    }

    &--file {
      display: flex;
      align-items: center;
      color: $color-red;
      fill: $color-red;
      text-decoration: none;
      cursor: pointer;

      &_disabled {
        color: $color-grey;
        fill: $color-grey;
      }

      &-icon {
        margin-right: 8px;
      }

      &-name {
        max-width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--offer{
      .offer-status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 23px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 12px;

        &_await_approval {
          color: $color-orange;
          border: 1px solid $color-orange;
        }

        &_agreed {
          color: $color-green;
          border: 1px solid $color-green;
        }

        &_rejected {
          color: $color-grey;
          border: 1px solid $color-grey;
        }
      }
    }

    & td {
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
      background-color: $color-white;
      padding-right: 24px;

      &:first-child {
        padding-left: 20px;
        border: 1px solid $color-border-grey;
        border-right: 0;
      }

      &:last-child {
        padding-right: 20px;
        border: 1px solid $color-border-grey;
        border-left: 0;
      }
    }

    & tr:first-child {
      td:first-child {
        border-radius: 4px 0 0 0;
      }

      td:last-child {
        border-radius: 0 4px 0 0;
      }
    }

    & tr:last-child {
      td:first-child {
        border-radius: 0 0 0 4px;
      }

      td:last-child {
        border-radius: 0 0 4px 0;
      }
    }

    tr:not(:last-child) td{
      border-bottom: 0;
    }
  }
}
</style>
