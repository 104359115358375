<template>
  <div class="page">
    <div class="iconsBlock">
      <StarIcon />
      <EditBigIcon />
      <AddIcon />
      <DropDownIcon />
      <DropDownWhiteIcon />
      <EditIcon />
      <BurgerIcon />
      <RefreshIcon />
      <CrossIcon />
      <FavoriteBorderIcon />
      <FavoriteFilledIcon />
      <DeleteIcon />
      <DotsIcon />
      <CalendarIcon />
      <DoneIcon />
      <SearchIcon />
      <AttachIcon />
      <ArrowBackIcon />
    </div>
    <form action="#" method="POST" class="form" @submit.prevent="onSubmit(callback)">
      <h3>Форма</h3>
      <v-select-mini v-model="product" :options="options" label="Выбор продукта" :error="productMeta.touched && productError"></v-select-mini>
      <v-file-picker v-model="movie" name="movie" label="Загрузи файл mp4" :error="movieError" accept=".mp4"></v-file-picker>
      <p>{{ movieError }}</p>
      <v-file-picker v-model="picture" name="picture" label="Загрузи картинку" :error="pictureMeta.touched && pictureError" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"></v-file-picker>
      <p>{{ pictureError }}</p>
      <v-file-picker v-model="picture" name="picture" label="Disabled" :error="pictureMeta.touched && pictureError" accept="" disabled="true"></v-file-picker>
      <v-radio-group v-model="radioGroup" :list="radioList_1"></v-radio-group>
      <v-textarea v-model="textarea" :error="textareaMeta.touched && textareaError" label="Юридический адрес" minlength="2" maxlength="250"></v-textarea>
      <div class="deal">
        <p class="title">Действие договора:</p>
        <div class="dataPickers">
          <v-date-picker v-model:date="dateStart" :error="dateStartMeta.touched && dateStartError" placeholder="Дата начала" className="start"></v-date-picker>
          <span class="divider">—</span>
          <v-date-picker v-model:date="dateEnd" :error="dateEndMeta.touched && dateEndError" placeholder="Дата окончания" className="end"></v-date-picker>
        </div>
      </div>
      <button class="button" type="submit">Войти</button>
    </form>

    <div>
      <button type="button" @click="isShowModal = true">Открыть модалку</button>
      <v-modal v-if="isShowModal" @closeModal="isShowModal = false">
        <p>Какой-то контент</p>
        <button type="button" @click="isShowModal = false">Закрыть модалку</button>
      </v-modal>
    </div>

    <v-radio-group :list="radioList_1"></v-radio-group>
    <br />
    <v-radio-group :list="radioList_2"></v-radio-group>

    <v-select-mini v-model="value_1" :options="options" label="Название поля 1"></v-select-mini>
    <br />
    <v-select-mini v-model="value_2" :options="options" label="Название поля 2"></v-select-mini>
    <br />
    <v-select-mini v-model="value_3" :options="options" label="Название поля 3" disabled="true"></v-select-mini>
    <br />
    <v-select-mini v-model="value_4" :options="options" label="Название поля 4" error="true"></v-select-mini>

    <div class="tabs">
      <v-tabs :list="tabs1.list" :active-index="tabs1.activeIndex" :name="tabs1.name" @change="changeActiveIndex"></v-tabs>
      <v-tabs :list="tabs2.list" :active-index="tabs2.activeIndex" :name="tabs2.name" @change="changeActiveIndex"></v-tabs>
    </div>

    <v-border-button text="Выбрать лицензию" type="button"></v-border-button>
    <v-border-button text="Не активна" type="button" disabled="true"></v-border-button>
  </div>
</template>

<script>
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { checkFormats, maxSizeMb } from '@/utils/yup-helpers'

import StarIcon from '../components/icons/star-icon.vue'
import EditBigIcon from '../components/icons/edit-big-icon.vue'
import AddIcon from '../components/icons/add-icon.vue'
import DropDownIcon from '../components/icons/dropdown-icon.vue'
import DropDownWhiteIcon from '../components/icons/dropdown-icon-white.vue'
import EditIcon from '../components/icons/edit-icon.vue'
import BurgerIcon from '../components/icons/burger-icon.vue'
import RefreshIcon from '../components/icons/refresh-icon.vue'
import CrossIcon from '../components/icons/cross-icon.vue'
import FavoriteBorderIcon from '../components/icons/favorite-border-icon.vue'
import FavoriteFilledIcon from '../components/icons/favorite-filled-icon.vue'
import DeleteIcon from '../components/icons/delete-icon.vue'
import DotsIcon from '../components/icons/dots-icon.vue'
import CalendarIcon from '../components/icons/calendar-icon.vue'
import DoneIcon from '../components/icons/done-icon.vue'
import SearchIcon from '../components/icons/search-icon.vue'
import AttachIcon from '../components/icons/attach-icon.vue'
import ArrowBackIcon from '../components/icons/arrow-back-icon.vue'

const OPTIONS = [
  { id: 1, text: 'Тип продукта_1', value: 'value_1' },
  { id: 2, text: 'Тип продукта_2', value: 'value_2' },
  { id: 3, text: 'Тип продукта_3', value: 'value_3' },
  { id: 4, text: 'Тип продукта_4', value: 'value_4' },
  { id: 5, text: 'Тип продукта_5', value: 'value_5' }
]
const LIST_1 = [
  { id: 1, title: 'О компании' },
  { id: 2, title: 'Заказы' },
  { id: 3, title: 'Пользователи' }
]
const LIST_2 = [
  { id: 1, title: 'Возвращённые' },
  { id: 2, title: 'Отправленные' },
  { id: 3, title: 'Завершённые' },
  { id: 4, title: 'Запросы' }
]
const RADIO_LIST_1 = [
  { id: 1, name: 'contact', value: 'email', text: 'Email' },
  { id: 2, name: 'contact', value: 'phone', text: 'Phone' },
  { id: 3, name: 'contact', value: 'mail', text: 'Mail' }
]
const RADIO_LIST_2 = [
  { id: 4, name: 'disabled', value: 'email', text: 'Email', disabled: true },
  { id: 5, name: 'disabled', value: 'phone', text: 'Phone', checked: true, disabled: true }
]

export default {
  components: {
    StarIcon, EditBigIcon, AddIcon, DropDownIcon, DropDownWhiteIcon, EditIcon, BurgerIcon, RefreshIcon,
    CrossIcon, FavoriteBorderIcon, FavoriteFilledIcon, DeleteIcon, DotsIcon, CalendarIcon, DoneIcon,
    SearchIcon, AttachIcon, ArrowBackIcon
  },
  setup () {
    const schema = yup.object({
      product: yup.string().required('Обязательное поле'),
      movie: yup.mixed().test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10)).test('format', 'Неверный формат', checkFormats('.mp4')),
      picture: yup.mixed().test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10)).test('format', 'Неверный формат', checkFormats('.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*')),
      radioGroup: yup.string().required('Выбери одну опцию'),
      textarea: yup.string().min(2, 'Должно быть не менее 2 символов').max(250, 'Должно быть не более 250 символов').required('Вы ничего не написали'),
      dateStart: yup.number().required('Обязательное поле').positive('Обязательное поле'),
      dateEnd: yup.number().required('Обязательное поле').positive('Обязательное поле').moreThan(yup.ref('dateStart'), 'Дата не может быть меньше начальной')
    })

    const { handleSubmit } = useForm({ validationSchema: schema })

    const { value: product, errorMessage: productError, meta: productMeta } = useField('product')
    const { value: movie, errorMessage: movieError, meta: movieMeta } = useField('movie')
    const { value: picture, errorMessage: pictureError, meta: pictureMeta } = useField('picture')
    const { value: radioGroup, errorMessage: radioGroupError, meta: radioGroupMeta } = useField('radioGroup')
    const { value: textarea, errorMessage: textareaError, meta: textareaMeta } = useField('textarea')
    const { value: dateStart, errorMessage: dateStartError, meta: dateStartMeta } = useField('dateStart')
    const { value: dateEnd, errorMessage: dateEndError, meta: dateEndMeta } = useField('dateEnd')

    const onSubmit = handleSubmit((values, event) => {
      event.evt(values)
    })

    return {
      product, productError, movie, movieError, productMeta, movieMeta, picture, pictureError, pictureMeta, onSubmit,
      radioGroup, radioGroupError, radioGroupMeta, textarea, textareaError, textareaMeta, dateStart, dateStartError,
      dateStartMeta, dateEnd, dateEndError, dateEndMeta
    }
  },

  data: () => ({
    options: OPTIONS,
    value_1: '',
    value_2: '',
    value_3: OPTIONS[4].value,
    value_4: '',
    tabs1: { name: 'tabs1', list: LIST_1, activeIndex: 0 },
    tabs2: { name: 'tabs2', list: LIST_2, activeIndex: 1 },
    radioList_1: RADIO_LIST_1,
    radioList_2: RADIO_LIST_2,
    isShowModal: false
  }),
  methods: {
    changeActiveIndex(index, name) {
      this[name].activeIndex = index
    },
    callback(data) {
      console.log(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 960px;
  margin: auto;
  padding-bottom: 100px;
}

.iconsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: lemonchiffon;
}

.form {
  padding: 30px;
  width: 800px;
  border: 1px solid royalblue;
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.deal {
  height: 49px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  line-height: 21px;
  color: $color-black;

  .dataPickers {
    display: flex;
    align-items: center;

    .divider {
      margin: 0 8px;
    }
  }
}
</style>
