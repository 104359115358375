<template>
  <div class="create-client">
    <div class="create-client__header">
      <arrow-back-icon class="create-client__header--back" @click="$router.push({ name: 'clients' })"/>
      <v-title>Создание клиента</v-title>
    </div>
    <client-create-form/>
  </div>
</template>

<script>
import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import ClientCreateForm from '@/components/ClientCreateForm'

export default {
  name: 'CreateClient',
  components: { ClientCreateForm, ArrowBackIcon }
}
</script>

<style scoped lang="scss">
 .create-client {
   min-width: 900px;
   max-width: 1180px;
   padding-top: 36px;
   margin: 0 auto;

   &__header {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     margin-bottom: 25px;
     &--back {
       margin-right: 12px;
       cursor: pointer;
     }
   }
 }
</style>
