<template>
  <v-modal @closeModal="$emit('resetModal')" @submitModal="$emit('resetModal')">
    <div class="modal">
      <div class="modal__header">
        <img class="modal__icon" :src="checkIcon" alt="">
        <h2
          class="modal__title"
          v-html="decision === 'agree' ? 'Заказ по вашему запросу будет создан в течение 1 дня. <br /> Информация будет доступна в разделе Заказы.' : 'Запрос отклонён'"
        />
      </div>
      <v-button class="modal__button" type="button" size="l" text="ОК" @click="$emit('resetModal')"></v-button>
    </div>
  </v-modal>
</template>

<script>
import checkIcon from '@/assets/icons/check.svg'

export default {
  name: 'UserNoticeModal',
  props: ['decision'],
  emits: ['resetModal'],
  setup() {
    return { checkIcon }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
  }
  &__icon {
    width: 35px;
    height: 27px;
    fill: $color-green;
  }
  &__title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    margin-left: 16px;
  }
  &__button {
    margin-top: 16px;
  }
}
</style>
