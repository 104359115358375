<template>
  <div class="user-header">
    <div class="user-header__container">
      <div class="user-header__logos" @click="$router.push({ name: 'catalog' })">
        <img class="user-header__logo" :src="mocklogo" alt="Logo"  />
        <img v-if="!isLogoLoading && logo" class="user-header__logo" :src="logo" alt="Logo" />
      </div>
      <div v-show="!isLogoLoading" class="user-header__profile">
        <user-block></user-block>
        <cart-block :amount="amount"></cart-block>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import UserBlock from '@/components/UserBlock'
import CartBlock from '@/components/CartBlock'
import mocklogo from '@/assets/images/header_logo.svg'
import currency from '@/utils/currency-formatter.js'

export default {
  name: 'UserHeader',
  components: { UserBlock, CartBlock },
  setup() {
    const store = useStore()

    const accessibleBudget = ref(1791912.50)
    const allBudget = ref(15000000)
    const amount = ref(0)

    const logo = computed(() => store.getters['auth/getLogo'])
    const isLogoLoading = computed(() => store.getters['auth/getLogoLoading'])

    watch(() => store.getters['cart/getTotalQuantity'], () => {
      amount.value = computed(() => store.getters['cart/getTotalQuantity'])
    }, { immediate: true, deep: true })

    return {
      currency, isLogoLoading, logo, mocklogo, accessibleBudget, allBudget, amount
    }
  }
}
</script>

<style lang="scss" scoped>
.user-header {
  height: 70px;
  background-color: $color-grey-bc;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-width: 900px;
    max-width: 1180px;
    margin: auto;
  }

  &__logos {
    cursor: pointer;
  }

  &__logo {
    max-height: 50px;
    max-width: 270px;
    margin-right: 40px;
  }

  &__profile {
    display: flex;
    align-items: center;
  }
}
</style>
