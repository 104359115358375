<template>
  <div class="block" @click="$router.push({ name: 'cart' })">
    <img :src="require('../assets/images/cart.svg')" alt="" />
    <div v-if="amount.value" class="block__amount">{{ amount.value > 99 ? '99+' : amount.value }}</div>
  </div>
</template>

<script>
export default {
  name: 'CartBlock',
  props: ['amount']
}
</script>

<style lang="scss" scoped>
.block {
  position: relative;
  margin-top: 5px;
  margin-left: 17px;
  cursor: pointer;

  &__amount {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid $color-red;
    border-radius: 100%;
    background-color: $color-white;
    color: $color-red;
    font-size: 11px;
    font-weight: 700;
    top: -13px;
    right: -13px;
    letter-spacing: -0.8px;
  }
}
</style>
