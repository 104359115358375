<template>
  <div class="v-year-filter" v-if="years.length > 1">
    <ul>
      <li v-for="(year, index) in years" :key="year + '-' + index" @click="onChange(year.toString())">
        <button :class="['button', {'button__active': activeYears?.includes(year)}]">{{ year }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'vYearFilter',
  props: ['years', 'activeYears'],
  emits: ['changeOptions'],
  methods: {
    onChange(year) {
      if (!this.activeYears) {
        this.$emit('changeOptions', year)
      } else {
        const activeYearsArray = this.activeYears.split(',')
        if (activeYearsArray.includes(year)) {
          this.$emit('changeOptions', activeYearsArray.filter(el => el !== year).join(','))
        } else {
          this.$emit('changeOptions', [...activeYearsArray, year].join(','))
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .v-year-filter ul {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button {
      margin-left: 8px;
      height: 35px;
      color: $color-red;
      background-color: $color-white;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      white-space: nowrap;
      border: 1px solid $color-red;
      border-radius: 32px;
      padding: 8px 20px;
      cursor: pointer;
      user-select: none;

      &__active {
        outline: none;
        border: none;
        display: inline-block;
        border-radius: 32px;
        background: #C90D46;
        color: $color-white;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;

        &:hover {
          background-color: $color-red-hover;
        }
      }
    }
  }
</style>
