<template>
  <div class="create-user">
    <div class="create-user__header">
      <arrow-back-icon class="create-user__header--back" @click="$router.push({ name: 'users-list', params: {clientId: $route.params.clientId} })"/>
      <v-title>Добавление пользователя</v-title>
    </div>
    <v-tabs class="create-user__tabs" :list="tabs.list" :active-index="tabs.activeIndex" :name="tabs.name" @change="changeActiveIndex"></v-tabs>
    <component :is="form"/>
  </div>
</template>

<script>
import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import UserCreateForm from '@/components/UserCreateForm'
import UserCreateFormFromFile from '@/components/UserCreateFormFromFile'
import { computed, reactive } from 'vue'

export default {
  name: 'CreateAdmin',
  components: { ArrowBackIcon, UserCreateForm, UserCreateFormFromFile },
  setup() {
    const TABS_LIST = [
      { id: 1, title: 'Вручную' },
      { id: 2, title: 'Загрузка файла' }
    ]
    const tabs = reactive({ list: TABS_LIST, activeIndex: 0 })
    const changeActiveIndex = index => { tabs.activeIndex = index }

    const form = computed(() => { return tabs.activeIndex === 0 ? 'user-create-form' : 'user-create-form-from-file' })

    return { TABS_LIST, tabs, changeActiveIndex, form }
  }
}
</script>

<style lang="scss" scoped>
.create-user {
  min-width: 900px;
  max-width: 1180px;
  padding-top: 36px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    &--back {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &__tabs {
    margin-bottom: 24px;
  }
}
</style>
