<template>
  <div>
    <v-modal v-if="showModal" @closeModal="$router.push({ name: 'orders' })" @submitModal="$router.push({ name: 'orders' })">
      <div class="modal">
        <div class="modal__header">
          <img class="modal__icon" :src="checkIcon" alt="">
          <h2 class="modal__title">{{ isCoordinating ? 'Заказ оформлен' : 'Заказ отправлен на согласование' }}</h2>
        </div>
        <v-button class="modal__button" type="button" size="l" text="ОК" @click="$router.push({ name: 'orders' })"></v-button>
      </div>
    </v-modal>
    <div class="title" @click="$emit('setForm', 'cart')">
      <arrow-back-icon class="title-icon" />
      <v-title>Оформление заказа</v-title>
    </div>
    <form class="form" @submit="onSubmit">
      <v-input
        placeholder="E-mail"
        v-model="email"
        :error="eError"
        :handleChange="eChange"
        :mask="emailMask"
        :class-name="'email'"
        :maxlength="64"
        :disabled="isSubmitting"/>
      <v-input
        placeholder="Страна доставки"
        v-model="country"
        :error="cError"
        :handleChange="cChange"
        :mask="nameMask"
        :class-name="'country'"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <v-input
        placeholder="Город доставки"
        v-model="city"
        :error="ciError"
        :handleChange="ciChange"
        :mask="nameMask"
        :class-name="'city'"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <v-input
        placeholder="Улица"
        v-model="street"
        :error="sError"
        :handleChange="sChange"
        :mask="allSymbolsMask"
        :class-name="'street'"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <div class="form__structure">
        <v-input
          placeholder="Дом"
          v-model="house"
          :error="hError"
          :handleChange="hChange"
          :mask="allSymbolsMask"
          :class-name="'house'"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <v-input
          placeholder="Подъезд"
          v-model="entrance"
          :error="enError"
          :handleChange="enChange"
          :mask="notZeroStart"
          :class-name="'entrance'"
          :maxlength="10"
          :disabled="isSubmitting"/>
        <v-input
          placeholder="Квартира"
          v-model="apartment"
          :error="aError"
          :handleChange="aChange"
          :mask="allSymbolsMask"
          :class-name="'apartment'"
          :maxlength="10"
          :disabled="isSubmitting"/>
      </div>
      <v-input
        placeholder="ФИО получателя"
        v-model="recipientFullName"
        :error="nError"
        :handleChange="nChange"
        :mask="nameMask"
        :class-name="'recipientFullName'"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <v-input
        placeholder="Номер телефона получателя"
        v-model="recipientPhoneNumber"
        :error="pError"
        :handleChange="pChange"
        :mask="allSymbolsMask"
        :class-name="'recipientPhoneNumber'"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <v-textarea
        class="form__comment"
        label="Комментарий к заказу"
        v-model="recipientComment"
        :error="coError"
        :handleChange="coChange"
        :mask="allSymbolsMask"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <v-error
        v-if="firstError"
        class="form__error"
        :text="textError(firstError)"/>
      <v-button
        class="form__button"
        type="submit"
        :size="'l'"
        :disabled="isSubmitting"
        :text="isCoordinating ? 'Оформить заказ' : 'Отправить на согласование'"
      ></v-button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import checkIcon from '@/assets/icons/check.svg'
import { nameMask, notZeroStart, allSymbolsMask, emailMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'

const serverErrorText = 'Ошибка сервера'

export default {
  name: 'SoftwareHardwareForm',
  components: { ArrowBackIcon },
  emits: ['setForm'],
  setup() {
    const store = useStore()

    const showModal = ref(false)
    const serverError = ref(null)
    const isCoordinating = computed(() => JSON.parse(localStorage.getItem('user')).isCoordinating)

    const schema = yup.object({
      email: yup.string()
        .trim()
        .matches(/^.+@.+\..+$/, 'Невалидный email')
        .min(6, 'Email не может быть меньше 6 символов')
        .required('Заполнены не все обязательные поля'),
      country: yup.string()
        .trim()
        .min(2, 'Название страны не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      city: yup.string()
        .trim()
        .min(2, 'Название города не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      street: yup.string()
        .trim()
        .min(2, 'Название улицы не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      house: yup.string()
        .trim()
        .required('Заполнены не все обязательные поля'),
      entrance: yup.string()
        .trim(),
      apartment: yup.string()
        .trim(),
      recipientFullName: yup.string()
        .trim()
        .min(2, 'ФИО получателя не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      recipientPhoneNumber: yup.string()
        .trim()
        .min(2, 'Номер телефона получателя не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      recipientComment: yup.string()
        .trim()
        .notRequired()
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Комментарий к заказу не может быть меньше 2 символов'
        })
    })

    const { handleSubmit, isSubmitting, setFieldValue, errors } = useForm({ validationSchema: schema })

    const { value: email, errorMessage: eError, setErrors: eSetErrors, handleChange: eChange } = useField('email', undefined, { validateOnValueUpdate: false })
    const { value: country, errorMessage: cError, handleChange: cChange } = useField('country', undefined, { validateOnValueUpdate: false })
    const { value: city, errorMessage: ciError, handleChange: ciChange } = useField('city', undefined, { validateOnValueUpdate: false })
    const { value: street, errorMessage: sError, handleChange: sChange } = useField('street', undefined, { validateOnValueUpdate: false })
    const { value: house, errorMessage: hError, handleChange: hChange } = useField('house', undefined, { validateOnValueUpdate: false })
    const { value: entrance, errorMessage: enError, handleChange: enChange } = useField('entrance', undefined, { validateOnValueUpdate: false })
    const { value: apartment, errorMessage: aError, handleChange: aChange } = useField('apartment', undefined, { validateOnValueUpdate: false })
    const { value: recipientFullName, errorMessage: nError, handleChange: nChange } = useField('recipientFullName', undefined, { validateOnValueUpdate: false })
    const { value: recipientPhoneNumber, errorMessage: pError, handleChange: pChange } = useField('recipientPhoneNumber', undefined, { validateOnValueUpdate: false })
    const { value: recipientComment, errorMessage: coError, handleChange: coChange } = useField('recipientComment', undefined, { validateOnValueUpdate: false })

    const firstError = computed(() => eError.value || cError.value || ciError.value || sError.value || hError.value || enError.value || aError.value || nError.value || pError.value || coError.value || serverError.value)

    const user = computed(() => store.getters['auth/getUser'])

    const setInitialValues = () => {
      setFieldValue('email', user.value.email)
      setFieldValue('recipientFullName', `${user.value.secondName} ${user.value.firstName} ${user.value.patronymic}`)
      setFieldValue('recipientPhoneNumber', user.value.phone)
    }

    onMounted(() => setInitialValues())

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }

    const closePageOrShowError = response => {
      const noError = serverErrorHandler(response)
      if (noError) showModal.value = true
    }

    const onSubmit = handleSubmit(async values => {
      try {
        const response = await store.dispatch('cart/createOrder', values)
        closePageOrShowError(response)
      } catch (error) {
        eSetErrors(error)
        console.log(error)
      }
    })

    return {
      checkIcon, showModal,
      isSubmitting, onSubmit, errors, firstError, isCoordinating,
      email, eError, eSetErrors, eChange,
      country, cError, cChange,
      city, ciError, ciChange,
      street, sError, sChange,
      house, hError, hChange,
      entrance, enError, enChange,
      apartment, aError, aChange,
      recipientFullName, nError, nChange,
      recipientPhoneNumber, pError, pChange,
      recipientComment, coError, coChange,
      nameMask, notZeroStart, allSymbolsMask, emailMask,
      textError
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
  }
  &__icon {
    width: 35px;
    height: 27px;
    fill: $color-green;
  }
  &__title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    margin-left: 16px;
  }
  &__button {
    margin-top: 16px;
  }
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 24px 0 36px;

  &-icon {
    margin-right: 12px;
  }
}

.form {
  width: 550px;

  &__structure {
    display: flex;
    justify-content: space-between;

    & > * {
      min-width: 0;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &__button {
    margin-top: 20px;
  }

  &__comment {
    margin-bottom: 16px;
  }

  &__error {
    width: fit-content;
  }
}

.v-input.v-input__field {
  margin-bottom: 16px;
}
</style>
