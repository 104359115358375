<template>
  <div class="admins-panel">
    <div class="admins-panel__header">
      <v-title>Администраторы</v-title>
      <v-icon-button text="Добавить администратора" @click="$router.push({name: 'create-admin'})">
        <add-icon/>
      </v-icon-button>
    </div>
    <teleport to="body" v-if="isLoading">
      <v-spinner/>
    </teleport>
    <admins-table v-else :admins="admins"/>
    <v-pagination
      @changePage=changePage
      @changeLimit=changeLimit
      :total="pages.total"
      :limitList="pages.limitList"
      :limit="$route.query.limit ? +$route.query.limit : pages.limit"
      :page="$route.query.page ? +$route.query.page : pages.page"/>
  </div>
</template>
<script>

import addIcon from '@/components/icons/add-icon'
import AdminsTable from '@/components/AdminsTable'
import { computed, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'AdminsPanel',
  components: { AdminsTable, addIcon },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const admins = computed(() => store.getters['admins/getAdmins'])
    const isLoading = computed(() => store.getters['admins/isLoading'])
    const pages = computed(() => store.getters['admins/getPages'])
    let isUpdated = false

    onUpdated(() => {
      isUpdated = true
    })

    const changePage = (value) => {
      if (isUpdated) {
        const page = value !== 1 ? value : undefined
        router.push({ query: { ...route.query, page: page } })
      }
    }

    const changeLimit = (value) => {
      if (isUpdated) {
        const limit = value !== pages.value.limitList[0] ? value : undefined
        router.push({ query: { ...route.query, limit: limit, page: undefined } })
      }
    }

    watch(
      () => route.query,
      () => {
        if (route.name === 'admins-list') {
          const params = {
            page: route.query.page || 1, limit: route.query.limit || 10
          }
          store.dispatch('admins/loadAdmins', params)
        }
      },
      { deep: true, immediate: true }
    )
    return { admins, pages, isLoading, changePage, changeLimit }
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/styles/variables";

.admins-panel {
  min-width: 900px;
  max-width: 1180px;
  padding-top: 36px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
}

</style>
