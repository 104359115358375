<template>
  <div class="create-admin">
    <div class="create-admin__header">
      <arrow-back-icon class="create-admin__header--back" @click="$router.push({name: 'administrators'})"/>
      <v-title>Добавление администратора</v-title>
    </div>
    <admin-create-form />
  </div>
</template>
<script>
import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import AdminCreateForm from '@/components/AdminCreateForm'
export default {
  name: 'CreateAdmin', components: { AdminCreateForm, ArrowBackIcon }
}
</script>
<style scoped lang="scss">
.create-admin {
  min-width: 900px;
  max-width: 1180px;
  padding-top: 36px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    &--back {
      margin-right: 12px;
      cursor: pointer;
    }
  }

}
</style>
