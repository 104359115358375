<template>
  <v-modal @closeModal="$emit('resetModal')" @submitModal="onSubmit">
    <div class="modal__container">
      <div class="modal__header">
        <arrow-back-icon class="modal__header-icon" @click="$emit('showDetailModal')" />
        <v-title>Ответить на запрос</v-title>
      </div>
      <form class="modal-form" @submit.prevent="onSubmit">
        <div class="modal-form__field modal-form__loading">
          <v-file-picker
            v-model="offerFile"
            name="file"
            label="Коммерческое предложение"
            :error="ofError"
            :handleChange="ofChange"
            :disabled="isSubmitting"
            accept=".doc, .docx, .pdf, .xls, .xlsx"
            description="Форматы: doc, pdf, xlsx. Размер файла — не более 10 МБ."
            ref="offerFileRef"/>
          <v-button v-if="!offerFile" type="button" :size="'m'" text="Выбрать файл" class="modal-form__loading--button" @click="$refs.offerFileRef.pickerClickHandler()"></v-button>
          <v-border-button v-else type="button" :size="'m'" text="Удалить файл" class="modal-form__loading--button" @click="$refs.offerFileRef.deleteFile()"></v-border-button>
        </div>
        <v-textarea
          class="modal-form__field"
          label="Комментарий к запросу"
          v-model="comment"
          :error="cError"
          :handleChange="cChange"
          :mask="allSymbolsMask"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <div class="modal-form__errors" v-if="firstError">
          <v-error class="modal-form__error" :text="textError(firstError)" />
        </div>
        <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Отправить" class="modal-form__submit" />
      </form>
    </div>
  </v-modal>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { textError } from '@/utils/utils'
import { checkFormats, maxSizeMb } from '@/utils/yup-helpers'
import { allSymbolsMask } from '@/utils/inputMasks'
import { uploadFile } from '@/api/files'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'

export default {
  name: 'ClientAgreeModal',
  emits: ['resetModal', 'showDetailModal'],
  props: ['choosenId', 'decision', 'page', 'limit'],
  components: { ArrowBackIcon },
  setup(props, { emit }) {
    const store = useStore()
    const serverError = ref(null)

    const schema = yup.object({
      offerFile: yup.mixed()
        .notRequired()
        .test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10))
        .test('format', 'Неверный формат файла', checkFormats('.doc, .docx, .pdf, .xls, .xlsx')),
      comment: yup.string()
        .trim()
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Комментарий к запросу не может быть меньше 2 символов'
        })
    })

    const { handleSubmit, isSubmitting, errors } = useForm({ validationSchema: schema })

    const { value: offerFile, errorMessage: ofError, handleChange: ofChange } = useField('offerFile', undefined, { validateOnValueUpdate: false })
    const { value: comment, errorMessage: cError, handleChange: cChange } = useField('comment', undefined, { validateOnValueUpdate: false })

    const firstError = computed(() => ofError.value || cError.value || serverError.value)

    const onSubmit = handleSubmit(async values => {
      try {
        let data = values
        if (values.offerFile) {
          const fileData = await uploadFile({ entityType: 'request', file: values.offerFile })
          if (fileData.error) {
            serverError.value = fileData.error
            return
          }
          data = { ...values, file: fileData.uid }
        }
        delete data.offerFile
        await store.dispatch('requests/respond', {
          values: data,
          id: props.choosenId,
          decision: props.decision,
          limit: props.limit || 10,
          page: props.page || 1
        })
        emit('resetModal')
      } catch (error) {
        console.log(error)
      }
    })

    return {
      textError, allSymbolsMask,
      confirm, isSubmitting, errors, firstError, onSubmit,
      offerFile, ofError, ofChange,
      comment, cError, cChange
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__container {
    padding: 24px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-icon {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &-form {
    width: 880px;
    margin-top: 32px;

    &__field {
      margin-bottom: 16px;
    }

    &__loading {
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;

      &--button {
        align-self: center;
        margin-left: 8px;
      }
    }

    &__errors {
      display: flex;
      flex-wrap: wrap;
      margin-top: 36px;
      margin-bottom: -38px;
    }

    &__error {
      white-space: nowrap;
      margin-right: 4px;
    }

    &__submit {
      margin-top: 36px;
      align-self: flex-start;
    }
  }
}
</style>
