<template>
  <div class="sub-header">
    <nav class="sub-header__container">
      <ul class="sub-header__nav-list">
        <router-link v-for="nav in navs" :key="nav.id" :to="nav.link" v-slot="{ isActive, navigate }" custom>
          <li @click="$store.commit('search/changeClearValue')">
            <a :href="nav.link" @click="navigate" class="sub-header__nav-link" :class="{ 'sub-header__nav-link--active': isActive }">
              {{ nav.text }}
            </a>
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'SubHeader',
  setup() {
    return {
      navs: [
        { id: 1, link: '/catalog', text: 'Каталог' },
        { id: 2, link: '/orders', text: 'Заказы' },
        { id: 3, link: '/requests', text: 'Запросы' },
        { id: 4, link: '/contacts', text: 'Контакты' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-header {
  height: 58px;
  background-color: $color-black-bc;
  box-shadow: $color-box-shadow;

  &__container {
    height: 100%;
    min-width: 900px;
    max-width: 1180px;
    margin: auto;
  }

  &__nav-list {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__nav-link {
    color: $color-light-white;
    font-weight: 700;
    margin-right: 40px;
    text-decoration: none;
    cursor: pointer;

    &--active {
      color: rgba($color: $color-light-white, $alpha: 0.3);
    }
  }
}
</style>
