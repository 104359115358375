<template>
  <div class="v-multi-select"
       v-click-outside="clickOutside"
       :class="{ bigSelect: text, disabled }"
       :tabindex="disabled ? -1 : 0"
       @focus.stop="isActive = true"
       ref="multiselect">
    <div class="label" :class="{ active: text }">{{ label }}</div>
    <p v-if="text" class="text">{{ text }}</p>
    <DropDownIcon class="icon" :class="{ active: isActive, disabled }"/>
    <transition :name="transitionOption">
      <ul v-if="isActive" :class="['list', {'list--down': transitionOption === 'animate-down' }, {'list--top': transitionOption === 'animate-top'} ]">
        <li
          v-for="(item, index) in options"
          :key="item.title + index"
          class="item options-title"
        >
          <div>
            {{TITLE_STATUS[item.title]}}
          </div>
          <ul>
            <li
              v-for="(option, oIndex) in item.options"
              :key="option.name + oIndex"
              class="v-multi-select__option-item">
              <v-checkbox v-model="option[option.name]" :class-name="option.name" :checked="option[option.name]" :size="'m'">
                <p>{{ALL_STATUS[option.name]}}</p>
              </v-checkbox>
            </li>
          </ul>
        </li>
        <div class="v-multi-select__buttons">
          <v-button :size="'m'" :text="'Применить'" @click=save(options)></v-button>
          <v-border-button :size="'m'" :text="'Сбросить фильтры '" @click="clear(options)"></v-border-button>
        </div>
      </ul>
    </transition>
  </div>
</template>
<script>
import DropDownIcon from '@/components/icons/dropdown-icon'
import clickOutside from '@/directives/click-outside'
import { ALL_STATUS, TITLE_STATUS } from '@/utils/constants'
export default {
  name: 'vMultiSelect',
  components: { DropDownIcon },
  props: ['options', 'label', 'modelValue', 'disabled', 'mChange'],
  directives: { clickOutside },
  emits: ['update:modelValue', 'changeOptions'],
  data: () => {
    return {
      isClear: false,
      isActive: false,
      indexActiveOption: -1,
      isMounted: false,
      wasOpened: false,
      ALL_STATUS: ALL_STATUS,
      TITLE_STATUS: TITLE_STATUS,
      checkedArray: []
    }
  },
  mounted() {
    this.isMounted = true
    this.options.forEach(item => {
      item.options.forEach(option => {
        if (option[option.name]) {
          this.checkedArray.push(ALL_STATUS[option.name])
        }
      })
    })
  },
  methods: {
    save() {
      this.isActive = false
      this.checkedArray = []
      this.options.forEach(item => {
        item.options.forEach(option => {
          if (option[option.name]) {
            this.checkedArray.push(ALL_STATUS[option.name])
          }
        })
      })
      this.$emit('changeOptions', this.options)
    },
    clear() {
      this.options.forEach(item => {
        item.options.forEach(option => {
          if (option[option.name]) {
            option[option.name] = false
          }
        })
      })
      this.checkedArray = []
      this.isActive = false
      this.$emit('changeOptions', this.options)
    },
    clickOutside() {
      this.isActive = false
    }
  },
  computed: {
    text() {
      return this.checkedArray.join(', ')
    },
    transitionOption () {
      if (this.isMounted) {
        return this.$refs.multiselect.getBoundingClientRect().y < window.innerHeight / 2 ? 'animate-down' : 'animate-top'
      } else {
        return 'animate-down'
      }
    }
  },
  watch: {
    isActive() {
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/styles/variables";
.v-multi-select {
  position: relative;
  width: 100%;
  height: 35px;
  padding: 6px 20px;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 16px;
  transition: 0.5s;

  &__option-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $color-black;

    &:hover,
    &.active {
      color: $color-red;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &.disabled {
    pointer-events: none;
    user-select: none;
  }

  .options-title {
    color: $color-grey;
    font-size: 16px;
    line-height: 130%;

    & div {
      margin-bottom: 8px;
    }
  }

  &.error {
    border-color: $color-red;
  }

  &.bigSelect {
    height: 49px;
  }

  .label {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    line-height: 20.8px;
    color: $color-grey;
    transition: 0.5s;
    cursor: pointer;

    &.active {
      height: auto;
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 20.8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.disabled {
      color: $color-grey;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: 0.5s cubic-bezier(1, -0.42, 0, 1.32);
    stroke: $color-red;

    &.active {
      transform: translateY(-50%) rotateZ(-180deg);
    }

    &.disabled {
      stroke: $color-grey;
    }
  }

  .list {
    position: absolute;
    width: 100%;
    max-height: 377px;
    left: 0;
    padding: 16px 20px;
    background: $color-white;
    border-radius: 16px;
    border: 1px solid $color-border-grey;
    z-index: 100;
    overflow-y: auto;

    &--down {
      top: 100%
    }

    &--top{
      bottom: 100%
    }

    .item {
      font-size: 16px;
      line-height: 21px;
      //color: #171C20;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &_disabled {
        color: $color-grey;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .animate-down {
    &-enter-active {
      transition: all .5s ease;
    }

    &-leave-active {
      pointer-events: none;
      transition: all .5s ease;
    }

    &-enter-from,
    &-leave-to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }

  .animate-top {
    &-enter-active {
      transition: all .5s ease;
    }

    &-leave-active {
      pointer-events: none;
      transition: all .5s ease;
    }

    &-enter-from,
    &-leave-to {
      transform: translateY(50px);
      opacity: 0;
    }
  }

}
</style>
