<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <div class="wrapper">
    <div class="catalog">
      <div class="catalog__container">
        <v-title class="catalog__title">Каталог</v-title>
        <div class="catalog__filters">
          <v-select class="catalog__select" v-model="vendorId" label="Производитель" :options="vendorsList"></v-select>
          <div class="catalog__filters-divider" />
          <v-select class="catalog__select catalog__filters-category" v-model="categoryId" label="Категория" :options="categoriesList"></v-select>
          <v-select class="catalog__select" v-model="subCategoryId" label="Подкатегория" :options="subCategoriesList" :disabled="!categoryId"></v-select>
        </div>
        <ul class="catalog__list">
          <catalog-product-card class="catalog__item" v-for="product in catalog" :key="product.id" :product="product" :cartIds="cartIds"></catalog-product-card>
        </ul>
        <v-pagination
          class="catalog__pagination"
          :total="pages.total"
          :page="pages.page"
          :limitList="pages.limitList"
          :limit="+$route.query.limit || pages.limit"
          @changeLimit="changeLimit"
          @changePage="changePage"
        ></v-pagination>
      </div>
    </div>
    <div class="hints" v-if="hints?.length">
      <ul class="hints__list">
        <search-card v-for="card in hints" :key="card.id" :card="card"></search-card>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { debounce } from 'debounce'

import SearchCard from '@/components/SearchCard'
import CatalogProductCard from '@/components/CatalogProductCard.vue'

export default {
  name: 'Catalog',
  components: { CatalogProductCard, SearchCard },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const categoryId = ref()
    const subCategoryId = ref()
    const vendorId = ref()

    const catalog = computed(() => store.getters['catalog/getCatalog'])
    const categories = computed(() => store.getters['catalog/getCategories'])
    const subCategories = computed(() => store.getters['catalog/getSubCategories'])
    const vendors = computed(() => store.getters['catalog/getVendors'])
    const isLoading = computed(() => store.getters['catalog/isLoading'])
    const pages = computed(() => store.getters['catalog/getPages'])
    const language = computed(() => store.getters['catalog/getLanguage'])

    const hints = computed(() => store.getters['search/getHints'])
    const value = computed(() => store.getters['search/getValue'])
    const abortDebounce = computed(() => store.getters['search/getAbortDebounce'])

    const cartItems = computed(() => store.getters['cart/getItems'])
    const cartIds = ref([])

    const categoriesList = computed(() => {
      const list = [{ id: -1, text: 'Все', value: 'reset' }]
      categories.value.forEach(el => {
        list.push({ id: el.id, text: el.name, value: el.id })
      })
      return list
    })

    const subCategoriesList = computed(() => {
      const list = [{ id: -1, text: 'Все', value: 'reset' }]
      subCategories.value.forEach(el => {
        list.push({ id: el.id, text: el.name, value: el.id })
      })
      return list
    })

    const vendorsList = computed(() => {
      const list = [{ id: -1, text: 'Все', value: 'reset' }]
      vendors.value.forEach(el => {
        list.push({ id: el.id, text: el.name, value: el.id })
      })
      return list
    })

    const changeLimit = limit => {
      router.push({
        query: {
          ...route.query,
          limit: limit === 12 ? undefined : limit
        }
      })
    }

    const changePage = page => {
      router.push({
        query: {
          ...route.query,
          page: page === 1 ? undefined : page
        }
      })
    }

    onMounted(() => {
      store.commit('search/setHints', [])
      store.dispatch('catalog/loadCategories', language.value)
      store.dispatch('catalog/loadSubCategories', { id: categoryId.value, language: language.value })
      store.dispatch('catalog/loadVendors', language.value)
    })

    onUnmounted(() => {
      store.commit('search/setHints', [])
    })

    const searchDebounce = debounce(cancel => {
      if (cancel) return
      store.dispatch('search/loadHints', { query: value.value, language: language.value })
    }, 700)

    watch([value, abortDebounce], () => {
      if (value.value.length === 0) {
        store.commit('search/setHints', [])
      }
      if (value.value.length > 2) {
        searchDebounce(abortDebounce.value)
      } else {
        searchDebounce(true)
      }
    }, { deep: true })

    watch(
      () => route.query,
      () => {
        if (route.fullPath.split('/').length < 3) {
          const query = {
            page: route.query.page || 1,
            limit: route.query.limit || 12,
            language: language.value,
            categoryId: route.query.categoryId || '',
            subCategoryId: route.query.subCategoryId || '',
            vendorId: route.query.vendorId || ''
          }
          categoryId.value = +route.query.categoryId || ''
          subCategoryId.value = +route.query.subCategoryId || ''
          vendorId.value = +route.query.vendorId || ''
          store.dispatch('catalog/loadCatalog', query)
        }
      },
      { deep: false, immediate: true }
    )
    watch(categoryId, () => {
      store.dispatch('catalog/loadSubCategories', { id: categoryId.value, language: language.value })
      router.push({
        query: {
          ...route.query,
          page: undefined,
          limit: undefined,
          subCategoryId: undefined,
          categoryId: categoryId.value ? categoryId.value : undefined
        }
      })
    })
    watch(subCategoryId, () => {
      router.push({
        query: {
          ...route.query,
          page: undefined,
          limit: undefined,
          subCategoryId: subCategoryId.value ? subCategoryId.value : undefined
        }
      })
    })
    watch(vendorId, () => {
      router.push({
        query: {
          ...route.query,
          page: undefined,
          limit: undefined,
          vendorId: vendorId.value ? vendorId.value : undefined
        }
      })
    })
    watch(cartItems, () => {
      cartIds.value = cartItems.value.map(el => el.productId)
    }, { immediate: true })

    return {
      catalog, isLoading, pages, cartIds, hints,
      categoriesList, subCategoriesList, vendorsList,
      categoryId, subCategoryId, vendorId,
      changeLimit, changePage
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.catalog {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 348px);
    margin: auto;
    padding: 24px 100px 80px;
    background-color: $color-light-white;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &-category {
      margin-right: 10px;
    }

    &-divider {
      align-self: stretch;
      width: 1px;
      border-radius: 1px;
      background-color: $color-grey;
      margin: 0 24px;
    }
  }

  &__select {
    height: 49px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
  }

  &__item {
    width: 100%;
    grid-column: span 3;
  }

  &__pagination {
    margin-top: 48px;
  }
}

.hints {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 348px);
  background-color: rgba($color: $color-black, $alpha: 0.2);
  padding: 16px 0;
  top: 0;
  overflow: hidden;

  &__list {
    min-width: 900px;
    max-width: 1180px;
    margin: auto;
    padding: 12px 16px;
    background-color: $color-white;
  }
}
</style>
