<template>
  <v-modal v-if="showModal" @closeModal="$router.push({ name: nextRoute })" @submitModal="$router.push({ name: nextRoute })">
    <div class="modal__header">
      <img class="modal__icon" :src="checkIcon" alt="">
      <h2 class="modal__title">Запрос отправлен</h2>
    </div>
    <p class="modal__text">Спасибо! Запрос успешно отправлен. В течение 1-3 рабочих дней ответ поступит на вашу электронную почту.</p>
    <v-button class="modal__button" type="button" size="l" text="ОК" @click="$router.push({ name: nextRoute })"></v-button>
  </v-modal>
  <div class="request-create">
    <div class="request-create__container">
      <div class="request-create__title">
        <arrow-back-icon class="request-create__title-icon" @click="$router.push({ name: nextRoute })" />
        <v-title>Создание запроса</v-title>
      </div>
      <form class="request-create-form" @submit="onSubmit">
        <v-input
          class="request-create-form__field"
          placeholder="Наименование товара"
          v-model="name"
          :error="nError"
          :handleChange="nChange"
          :mask="allSymbolsMask"
          :class-name="'name'"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <v-textarea
          class="request-create-form__field request-create-form__textarea"
          label="Комментарий к запросу"
          v-model="comment"
          :error="cError"
          :handleChange="cChange"
          :mask="allSymbolsMask"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <div class="request-create-form__field request-create-form__loading">
          <v-file-picker
            v-model="requestFile"
            name="file"
            label="Файл с описанием запроса"
            :error="rfError"
            :handleChange="rfChange"
            :disabled="isSubmitting"
            accept=".doc, .docx, .pdf, .xls, .xlsx"
            description="Форматы: doc, pdf, xlsx. Размер файла — не более 10 МБ."
            ref="requestFileRef"/>
          <v-button v-if="!requestFile" type="button" :size="'m'" text="Выбрать файл" class="request-create-form__loading--button" @click="$refs.requestFileRef.pickerClickHandler()"></v-button>
          <v-border-button v-else type="button" :size="'m'" text="Удалить файл" class="request-create-form__loading--button" @click="$refs.requestFileRef.deleteFile(), serverError = null"></v-border-button>
        </div>
        <div class="request-create-form__errors" v-if="firstError">
          <v-error class="request-create-form__error" :text="textError(firstError)" />
        </div>
        <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Отправить" class="request-create-form__submit" />
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { textError } from '@/utils/utils'
import { checkFormats, maxSizeMb } from '@/utils/yup-helpers'
import { allSymbolsMask } from '@/utils/inputMasks'
import { uploadFile } from '@/api/files'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import checkIcon from '@/assets/icons/check.svg'

const serverErrorText = 'Ошибка сервера'

export default {
  name: 'RequestCreate',
  components: { ArrowBackIcon },
  setup() {
    const store = useStore()
    const router = useRouter()

    const requestFileRef = ref(null)
    const serverError = ref(null)
    const showModal = ref(false)

    const nextRoute = computed(() => {
      if (router.options.history.state.back.split('/')[1] === 'catalog') {
        return 'catalog'
      } else {
        return 'requests'
      }
    })

    const schema = yup.object({
      name: yup.string()
        .trim()
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Наименование товара не может быть меньше 2 символов'
        }),
      comment: yup.string()
        .trim()
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Комментарий к запросу не может быть меньше 2 символов'
        }),
      requestFile: yup.mixed()
        .notRequired()
        .test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10))
        .test('format', 'Неверный формат файла', checkFormats('.doc, .docx, .pdf, .xls, .xlsx'))
    })

    const { handleSubmit, isSubmitting, errors } = useForm({ validationSchema: schema })

    const { value: name, errorMessage: nError, handleChange: nChange } = useField('name', undefined, { validateOnValueUpdate: false })
    const { value: comment, errorMessage: cError, handleChange: cChange } = useField('comment', undefined, { validateOnValueUpdate: false })
    const { value: requestFile, errorMessage: rfError, handleChange: rfChange } = useField('requestFile')

    const firstError = computed(() => nError.value || cError.value || rfError.value || serverError.value)

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }

    const closePageOrShowError = response => {
      const noError = serverErrorHandler(response)
      if (noError) showModal.value = true
    }

    const onSubmit = handleSubmit(async values => {
      let data = values
      try {
        if (values.requestFile) {
          const fileData = await uploadFile({ entityType: 'request', file: values.requestFile })
          if (fileData.error) {
            serverError.value = fileData.error
            return
          }
          data = { ...values, file: fileData.uid }
          delete data.requestFile
        }
        const response = await store.dispatch('requests/createRequest', data)
        closePageOrShowError(response)
      } catch (error) {
        console.log(error)
      }
    })

    return {
      allSymbolsMask, textError, checkIcon, showModal,
      requestFileRef, nextRoute, firstError, isSubmitting, errors, serverError, onSubmit,
      name, nError, nChange,
      comment, cError, cChange,
      requestFile, rfError, rfChange
    }
  }
}
</script>

<style lang="scss" scoped>
.request-create {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 36px 100px 80px;
    background-color: $color-light-white;
  }

  &__title {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &-form {
    width: 880px;
    margin-top: 32px;

    &__field {
      margin-bottom: 16px;
    }

    &__loading {
      display: flex;
      align-items: flex-start;
      margin-bottom: 32px;

      &--button {
        align-self: center;
        margin-left: 8px;
      }
    }

    &__errors {
      display: flex;
      flex-wrap: wrap;
      margin-top: 36px;
      margin-bottom: -38px;
    }

    &__error {
      white-space: nowrap;
      margin-right: 4px;
    }

    &__submit {
      margin-top: 36px;
      align-self: flex-start;
    }
  }
}

.modal {
  &__header {
    display: flex;
  }

  &__icon {
    width: 35px;
    margin-right: 16px;
  }

  &__title {
    font-size: 30px;
    font-weight: 400;
  }

  &__text {
    width: 500px;
    font-size: 16px;
    line-height: 21px;
    margin: 26px 0 20px;
  }

  &__button {
    display: block;
    margin: 0 auto;
  }
}
</style>
