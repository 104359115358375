<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>

  <user-detail-modal v-if="typeof showModal === 'number' && showModal > -1" :request="request" :status="status" :choosenId="choosenId" @passDecision="value => setDecision(value)" @showNoticeModal="showModal = 'noticeModal'" />
  <user-confirm-modal v-if="showModal === 'agree' || showModal === 'reject'" :decision="decision" :choosenId="choosenId" :page="prevPage" :limit="prevLimit" @showNoticeModal="showModal = 'noticeModal'" @setChoosenId="openDetailModal" @setModal="modal => showModal = modal" />
  <user-notice-modal v-if="showModal === 'noticeModal'" :decision="decision" @resetModal="setDecision(null)" />

  <div class="requests">
    <div class="requests__container">
      <div class="requests__header">
        <v-title>Запросы</v-title>
        <v-icon-button text="Создать запрос" @click="$router.push({ name: 'request-create' })">
          <add-icon/>
        </v-icon-button>
      </div>
      <v-tabs class="requests__tabs" :list="statusesList" :active-index="activeIndex < 0 ? 0 : activeIndex" @change="changeStatus"></v-tabs>
      <requests-table
        :requests="requests"
        :status="status"
        :isCoordinating="isCoordinating"
        @setChoosenId="openDetailModal"
        @setModal="modal => showModal = modal"
      />
      <v-pagination
        class="requests__pagination"
        @changePage="changePage"
        @changeLimit="changeLimit"
        :total="pages.total"
        :limitList="pages.limitList"
        :limit="$route.query.limit ? +$route.query.limit : pages.limit"
        :page="$route.query.page ? +$route.query.page : pages.page"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import addIcon from '@/components/icons/add-icon'
import RequestsTable from '@/components/RequestsTable.vue'

import UserDetailModal from '@/components/requestModals/UserDetailModal.vue'
import UserConfirmModal from '@/components/requestModals/UserConfirmModal.vue'
import UserNoticeModal from '@/components/requestModals/UserNoticeModal.vue'

export default {
  name: 'Requests',
  components: { addIcon, RequestsTable, UserDetailModal, UserConfirmModal, UserNoticeModal },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const showModal = ref(null)
    const choosenId = ref(-1)
    const decision = ref(null)
    const prevPage = ref(null)
    const prevLimit = ref(null)

    const pages = computed(() => store.getters['requests/getPages'])
    const isLoading = computed(() => store.getters['requests/isLoading'])
    const counters = computed(() => store.getters['requests/getCounters'])
    const requests = computed(() => store.getters['requests/getRequests'])
    const request = computed(() => store.getters['requests/getRequest'])
    const isCoordinating = computed(() => store.getters['auth/getUser'].isCoordinating)
    const statusesList = computed(() => ([
      { id: 1, status: 'working', title: `В работе (${counters.value.working || 0})` },
      { id: 2, status: 'await_approval', title: `Ожидают согласования (${counters.value.await_approval || 0})` },
      { id: 3, status: 'agreed', title: `Согласованные (${counters.value.agreed || 0})` },
      { id: 4, status: 'rejected', title: `Отклонённые (${counters.value.rejected || 0})` }
    ]))

    const activeIndex = ref(statusesList.value.findIndex(el => el.status === route.query.status))
    const status = ref(statusesList.value[activeIndex.value < 0 ? 0 : activeIndex.value].status)

    const openDetailModal = async id => {
      await store.dispatch('requests/loadRequestById', id)
      choosenId.value = id
      showModal.value = +id
    }

    const changeStatus = index => {
      activeIndex.value = index
      status.value = statusesList.value[index].status
      router.push({
        query: {
          ...route.query,
          status: status.value === 'working' ? undefined : status.value,
          page: undefined
        }
      })
    }

    const changeLimit = limit => {
      router.push({
        query: {
          ...route.query,
          limit: limit === 10 ? undefined : limit,
          page: +route.query.page === 1 ? undefined : route.query.page
        }
      })
    }

    const changePage = page => {
      router.push({
        query: {
          ...route.query,
          page: page === 1 ? undefined : page,
          limit: +route.query.limit === 10 ? undefined : route.query.limit
        }
      })
    }

    const setDecision = modal => {
      if (!modal) {
        const query = { status: route.query?.status === 'working' ? undefined : route.query?.status }
        if (prevPage.value) query.page = prevPage.value
        if (prevLimit.value) query.limit = prevLimit.value
        router.push({ query })
      }
      showModal.value = modal
      decision.value = modal
    }

    watch(() => route.query, async (to, prev) => {
      if (showModal.value === null) {
        prevPage.value = route.query.page ? route.query.page : 1
        prevLimit.value = route.query.limit ? route.query.limit : 10
      }
      if (prev && to?.number !== prev?.number) {
        return
      }
      const data = {
        page: route.query.page || 1,
        limit: route.query.limit || 10,
        status: route.query.status || 'working'
      }
      if (route.query.number) {
        await openDetailModal(route.query.number)
      }
      await store.dispatch('requests/loadRequests', data)
    }, { deep: false, immediate: true })

    return {
      statusesList, activeIndex, pages, isLoading, isCoordinating, requests, request, status, choosenId, showModal, decision, prevPage, prevLimit,
      openDetailModal, changeStatus, changeLimit, changePage, setDecision
    }
  }
}
</script>

<style lang="scss" scoped>
.requests {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 36px 100px 80px;
    background-color: $color-light-white;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 12px;
  }

  &__tabs {
    margin-bottom: 24px;
  }

  &__pagination {
    margin-top: 32px;
  }
}
</style>
