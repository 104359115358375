<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>

  <div v-else class="contacts">
    <div class="contacts__container">
      <v-title>Контакты</v-title>
      <v-tabs class="contacts__tabs" :list="tabs.list" :active-index="tabs.activeIndex" @change="changeTabIndex" />
      <div class="contacts__content">

        <div v-if="tabs.activeIndex === 0" class="contacts__manager">
          <p class="contacts__manager-name">{{ responsible.name }}</p>
          <a :href="`mailto:${responsible.email}`" class="contacts__item contacts__email">
            <div class="contacts__icon"><img :src="emailIcon" alt=""></div>
            <p>{{ responsible.email }}</p>
          </a>
          <a href="tel:+79999999999" class="contacts__item contacts__phone">
            <div class="contacts__icon"><img :src="phoneIcon" alt=""></div>
            <p>{{ responsible.phone }}</p>
          </a>
        </div>

        <div v-else class="contacts__softline">
          <div class="contacts__item">
            <div class="contacts__icon"><img :src="locationIcon" alt=""></div>
            <p>Юридический адрес: 119021, г. Москва, ул. Льва Толстого, дом 5, строение 1, этаж 3, помещение 1, комн. № 2, 2а (А-311)</p>
          </div>
          <a href="tel:+74952320060" class="contacts__item contacts__phone">
            <div class="contacts__icon contacts__icon_shift"><img :src="phoneIcon" alt=""></div>
            <div>
              <p>+7 (495) 232-00-23 и 8 (800) 232-00-23</p>
              <p class="contacts__phone_access">Круглосуточно, ежедневно</p>
            </div>
          </a>
          <a href="mailto:e-supply@softline.com" class="contacts__item contacts__email">
            <div class="contacts__icon contacts__icon_shift"><img :src="emailIcon" alt=""></div>
            <p>e-supply@softline.com</p>
          </a>
          <div class="contacts__item">
            <div class="contacts__icon contacts__icon_shift"><img :src="detailsIcon" alt=""></div>
            <div class="contacts__entities">
              <div class="contacts__entity">
                <p class="contacts__entity-name">ПАО «Софтлайн»</p>
                <p class="contacts__entity-inn">ИНН 7736227885</p>
                <p class="contacts__entity-kpp">КПП 774950001</p>
                <p class="contacts__entity-ogrn">ОГРН 1027736009333</p>
                <p class="contacts__entity-rs">р/с 40702810800000010453 в «Газпромбанк» (Акционерное общество)</p>
                <p class="contacts__entity-ks">к/с 30101810200000000823</p>
                <p class="contacts__entity-bik">БИК 044525823</p>
              </div>
              <!-- <div class="contacts__entity">
                <p class="contacts__entity-name">АО «Второе юрлицо»</p>
                <p class="contacts__entity-inn">ИНН 7736227885</p>
                <p class="contacts__entity-kpp">КПП 774950001</p>
                <p class="contacts__entity-ogrn">ОГРН 1027736009333</p>
                <p class="contacts__entity-rs">р/с 40702810502300000368 в ОАО «Альфа-банк» г. Москва</p>
                <p class="contacts__entity-ks">к/с 30101810200000000593</p>
                <p class="contacts__entity-bik">БИК 044525593</p>
              </div> -->
            </div>
          </div>
          <div class="contacts__item">
            <div class="contacts__icon contacts__icon_shift"><img :src="watchIcon" alt=""></div>
            <div>
              <p>Режим работы:</p>
              <p>круглосуточно, ежедневно</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

import emailIcon from '@/assets/icons/contacts/email.svg'
import phoneIcon from '@/assets/icons/contacts/phone.svg'
import locationIcon from '@/assets/icons/contacts/location.svg'
import detailsIcon from '@/assets/icons/contacts/details.svg'
import watchIcon from '@/assets/icons/contacts/watch.svg'

const CONTACTS_LIST = [
  { id: 1, status: 'manager', title: 'Ответственный менеджер' },
  { id: 2, status: 'softline', title: 'Контакты Softline' }
]

export default {
  name: 'Contacts',
  setup() {
    const store = useStore()
    const startedIndex = ref(0)

    const tabs = reactive({
      list: CONTACTS_LIST,
      activeIndex: startedIndex.value < 0 ? 0 : startedIndex.value
    })

    const isLoading = computed(() => store.getters['contacts/isLoading'])
    const responsible = computed(() => store.getters['contacts/getResponsible'])

    const changeTabIndex = index => {
      tabs.activeIndex = index
    }

    onMounted(() => store.dispatch('contacts/loadResponsible'))

    return {
      emailIcon, phoneIcon, locationIcon, detailsIcon, watchIcon,
      isLoading, tabs, responsible, changeTabIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 36px 100px 80px;
    background-color: $color-light-white;
  }

  &__tabs {
    margin: 24px 0;
  }

  &__content {
    background-color: $color-white;
    padding: 24px 32px;
    border-radius: 16px;
  }

  &__manager {
    &-name {
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 28px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__email {
    color: $color-red;
    font-weight: 700;
  }

  &__phone {
    color: $color-black;

    &_access {
      color: $color-grey;
    }
  }

  &__icon {
    width: 20px;
    display: flex;
    justify-content: center;
    align-self: flex-start;
    margin-right: 22px;

    &_shift {
      transform: translateY(3px);
    }
  }

  &__entities {
    display: flex;
    width: 100%;
  }

  &__entity {
    width: 50%;

    &-name {
      font-weight: 700;
      line-height: 21px;
    }
  }
}
</style>
