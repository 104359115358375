<template>
  <div v-if="role === 'admin'">
    <admin-header />
    <div class="notFounded-admin__container">
      <p class="notFounded-admin__text">Страница не найдена</p>
    </div>
  </div>

  <div v-else>
    <UserHeader />
    <SubHeader />
    <div class="notFounded-user">
      <div class="notFounded-user__container">
        <p class="notFounded-user__text">Страница не найдена</p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { computed } from 'vue'

import AdminHeader from '@/components/AdminHeader'
import UserHeader from '@/components/UserHeader'
import SubHeader from '@/components/SubHeader'
import Footer from '@/components/Footer'

export default {
  name: 'NotFounded',
  components: { AdminHeader, UserHeader, SubHeader, Footer },
  setup() {
    const role = computed(() => JSON.parse(localStorage.getItem('user')).role)

    return { role }
  }
}
</script>

<style lang="scss" scoped>
.notFounded-admin {
  &__container {
    min-width: 900px;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
  }

  &__text {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    margin-top: 150px;
  }
}

.notFounded-user {
  min-height: calc(100vh - 272px);
  background-image: url('../assets/images/bg-dot.jpg');
  background-size: 26px;
  background-position: 13px 21px;

  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 24px 100px 80px;
    background-color: $color-light-white;
  }

  &__text {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    margin-top: 150px;
  }
}
</style>
