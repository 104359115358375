<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <v-modal v-if="showModal === 'blocked'" @closeModal="showModal = false" @submitModal="blockUser">
    <h3 class="user-edit-modal__title">Заблокировать пользователя?</h3>
    <v-border-button type="button" :size="'m'" text="Да" class="user-edit-modal__agree" @click="blockUser"></v-border-button>
    <v-button type="button" :size="'m'" text="Нет" class="user-edit-modal__cancel" @click="showModal = null"></v-button>
  </v-modal>
  <v-modal v-if="showModal === 'delete'" @closeModal="showModal = false" @submitModal="deleteUser">
    <h3 class="user-edit-modal__title">Удалить пользователя?</h3>
    <p class="user-edit-modal__notice">В случае удаления пользователя все его заказы и запросы будут удалены.</p>
    <v-border-button type="button" :size="'m'" text="Да" class="user-edit-modal__agree" @click="deleteUser"></v-border-button>
    <v-button type="button" :size="'m'" text="Нет" class="user-edit-modal__cancel" @click="showModal = null"></v-button>
  </v-modal>
  <form class="user-edit-form" @submit="onSubmitEdit">
    <v-input
      placeholder="Фамилия"
      v-model="secondName"
      :error="sError"
      :handleChange="sChange"
      :mask="nameMask"
      :class-name="'secondName'"
      class="user-edit-form__input"
      :maxlength="250"
      :disabled="true"/>
    <v-input
      placeholder="Имя"
      v-model="firstName"
      :error="fError"
      :handleChange="fChange"
      :mask="nameMask"
      :class-name="'firstName'"
      class="user-edit-form__input"
      :maxlength="250"
      :disabled="true"/>
    <v-input
      placeholder="Отчество"
      v-model="patronymic"
      :error="lError"
      :handleChange="lChange"
      :mask="nameMask"
      :class-name="'patronymic'"
      class="user-edit-form__input"
      :maxlength="250"
      :disabled="true"/>
    <div class="user-edit-form__email">
      <v-input
        placeholder="E-mail"
        v-model="email"
        :error="eError"
        :handleChange="eChange"
        :class-name="'email'"
        :mask="emailMask"
        class="user-edit-form__input"
        :maxlength="64"
        :disabled="true"/>
      <div :class="['user-edit-form__repeat', {'user-edit-form__repeat--block': user.status === 'blocked'}]" @click="repeatEmail">
        <repeat-icon/>
        <span class="user-edit-form__repeat--text">Отправить повторно</span>
      </div>
    </div>
    <v-select
      label="Роль"
      v-model="role"
      :error="rError"
      :handleChange="rChange"
      :disabled="isSubmitting || user.status === 'blocked'"
      :options="roleList"
      class="user-edit-form__select" />
    <v-input
      placeholder="Номер телефона"
      v-model="phone"
      :error="pError"
      :handleChange="pChange"
      :mask="allSymbolsMask"
      :maxlength="250"
      :class-name="'phone'"
      class="user-edit-form__input"
      :disabled="isSubmitting || user.status === 'blocked'" />
    <v-error
      v-if="sError || fError || lError || eError || rError || pError || serverError"
      :text="textError(sError, fError, lError, eError, eError, pError, serverError)"/>
    <div class="user-edit-form__buttons">
      <v-button type="submit" :size="'l'" :disabled="isSubmitting || user.status === 'blocked'" text="Сохранить"
                class="user-edit-form__save"/>
      <div class="user-edit-form__control">
        <v-border-button type="button" :size="'m'" :disabled="isSubmitting || user.status === 'blocked'" :text="!user?.value?.disabled ? 'Заблокировать' : 'Разблокировать'"
                         class="user-edit-form__block" @click="onSubmitBlock"/>
        <!-- <v-border-button type="button" :size="'m'" :disabled="isSubmitting" text="Удалить"
                  class="user-edit-form__delete" @click="onSubmitDelete"></v-border-button> -->
      </div>
    </div>
  </form>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import RepeatIcon from '@/components/icons/repeat-icon'
import { nameMask, allSymbolsMask, emailMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'
import * as yup from 'yup'

const serverErrorText = 'Ошибка сервера'

const ROLE_LIST = [
  { id: 2, text: 'Согласующий', value: 'coordinator' },
  { id: 3, text: 'Пользователь', value: 'user' }
]

export default {
  name: 'UserEditForm',
  components: { RepeatIcon },
  setup () {
    const roleList = ref(ROLE_LIST)
    const showModal = ref(null)
    const serverError = ref(null)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { handleSubmit, isSubmitting, setFieldValue } = useForm()
    const user = computed(() => store.getters['users/getUser'])
    const isLoading = computed(() => store.getters['users/isLoading'])
    const setInitialValues = () => {
      setFieldValue('firstName', user.value.firstName)
      setFieldValue('secondName', user.value.secondName)
      setFieldValue('patronymic', user.value.patronymic || '')
      setFieldValue('email', user.value.email)
      setFieldValue('role', user.value.isCoordinating ? 'coordinator' : 'user')
      setFieldValue('phone', user.value.phone)
    }

    onMounted(() => store.dispatch('users/loadUserById', route.params.userId))
    watch(user, (values, _) => {
      setInitialValues()
    })
    const { value: secondName, errorMessage: sError, handleChange: sChange } = useField('secondName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите фамилию')
      .min(2, 'Фамилия не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: firstName, errorMessage: fError, handleChange: fChange } = useField('firstName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите имя')
      .min(2, 'Имя не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: patronymic, errorMessage: lError, handleChange: lChange } = useField('patronymic', yup
      .string()
      .trim()
      .notRequired()
      .nullable()
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Отчество не может быть меньше 2 символов'
      }), { validateOnValueUpdate: false })
    const { value: email, errorMessage: eError, handleChange: eChange } = useField('email', yup
      .string()
      .trim()
      .matches(/^.+@.+\..+$/, 'Невалидный email')
      .min(6, 'Email не может быть меньше 6 символов')
      .required('Пожалуйста, введите email'), { validateOnValueUpdate: false })
    const { value: role, errorMessage: rError, handleChange: rChange } = useField('role', yup
      .string()
      .required('Пожалуйста, выберите роль'), { validateOnValueUpdate: false })
    const { value: phone, errorMessage: pError, handleChange: pChange } = useField('phone', yup
      .string()
      .trim()
      .min(2, 'Номер телефона не может быть меньше 2 символов')
      .required('Пожалуйста, введите номер телефона'), { validateOnValueUpdate: false })

    const onSubmitBlock = () => { showModal.value = 'blocked' }
    const onSubmitDelete = () => { showModal.value = 'delete' }

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }

    const onSubmitEdit = handleSubmit(async values => {
      const user = {
        ...values,
        isCoordinating: values.role === 'coordinator',
        clientId: route.params.clientId
      }
      const userId = route.params.userId
      delete user.role
      try {
        const response = await store.dispatch('users/editUser', { user, userId })
        const noError = serverErrorHandler(response)
        noError && router.push({ name: 'users-list', params: { clientId: user.clientId } })
      } catch (error) {
        console.log(error)
      }
    })

    const blockUser = handleSubmit(async () => {
      const userId = route.params.userId
      const clientId = route.params.clientId
      try {
        const response = await store.dispatch('users/blockUser', userId)
        const noError = serverErrorHandler(response)
        noError && router.push({ name: 'users-list', params: { clientId: clientId } })
      } catch (error) {
        console.log(error)
      }
    })

    const deleteUser = handleSubmit(async () => {
      const userId = route.params.userId
      const clientId = route.params.clientId
      try {
        const response = await store.dispatch('users/deleteUser', userId)
        const noError = serverErrorHandler(response)
        noError && router.push({ name: 'users-list', params: { clientId: clientId } })
      } catch (error) {
        console.log(error)
      }
    })

    const repeatEmail = async () => {
      try {
        const response = await store.dispatch('users/repeatEmail', route.params.userId)
        serverErrorHandler(response)
      } catch (error) {
        console.log(error)
      }
    }

    return {
      roleList, user, isLoading, showModal, serverError, isSubmitting,
      secondName, sError, sChange,
      firstName, fError, fChange,
      patronymic, lError, lChange,
      email, eError, eChange,
      role, rError, rChange,
      phone, pError, pChange,
      onSubmitEdit, onSubmitBlock, onSubmitDelete,
      nameMask, allSymbolsMask, emailMask, textError,
      blockUser, deleteUser, repeatEmail
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables";

.user-edit-modal {
  &__title {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    color: $color-black;
    margin-bottom: 16px;
  }

  &__notice {
    margin-bottom: 16px;
  }

  &__agree {
    margin-right: 8px;
  }
}

.user-edit-form {
  display: flex;
  flex-direction: column;
  &__input,
  &__select {
    width: 550px;
  }

  &__email {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__repeat {
    margin-left: 20px;
    margin-bottom: 16px;
    &--block {
      filter: grayscale(1);
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }
  }

  &__repeat {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;

    &--text {
      font-weight: bold;
      color: $color-red;
      font-size: 16px;
      line-height: 19px;
      margin-left: 8px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__save, &__control {
    margin-top: 20px;
  }

  &__block {
    margin-right: 12px;
  }

  .v-error {
    align-self: flex-start;
  }

  .v-input.v-input__field,
  &__select {
    margin-bottom: 16px;
  }
}
</style>
