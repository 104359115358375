<template>
  <div>
    <v-modal v-if="showModal === 'delete'" @closeModal="showModal = false" @submitModal="deleteUser">
      <h3 class="user-edit-modal__title">Удалить пользователя?</h3>
      <p class="user-edit-modal__notice">В случае удаления пользователя все его заказы и запросы будут удалены.</p>
      <v-border-button type="button" :size="'m'" text="Да" class="user-edit-modal__agree" @click="deleteUser"></v-border-button>
      <v-button type="button" :size="'m'" text="Нет" class="user-edit-modal__cancel" @click="showModal = null"></v-button>
    </v-modal>
    <table class="users-table">

      <thead class="users-table__thead">
      <tr class="users-table__thead--row">
        <td>Фамилия, Имя, Отчество</td>
        <td>E-mail (логин)</td>
        <td>Номер Телефона</td>
        <td>Роль</td>
        <!-- <td></td> -->
        <td></td>
      </tr>
      </thead>
      <tbody class="users-table__tbody">
      <tr v-for="user in users" :key="user.id" class="users-table__tbody--row" :class="{ 'users-table__tbody--row_disabled': user?.status === 'blocked' }">
        <td class="users-table__tbody--name">{{ user?.secondName}} {{user?.firstName}} {{user?.patronymic ? ' ' + user?.patronymic : ''}}</td>
        <td class="users-table__tbody--email">{{ user.email }}</td>
        <td class="users-table__tbody--phone">{{ user.phone }}</td>
        <td v-if="user.isCoordinating ===true" class="users-table__tbody--role">
          <div><star-icon class="users-table__tbody--star" />Согласующий</div>
        </td>
        <td v-else class="users-table__tbody--role">Пользователь</td>
        <!-- <td class="users-table__tbody--orders">
          <burger-icon class="users-table__tbody--burger" />
          Заказы
        </td> -->
        <td class="users-table__tbody--button">
          <edit-icon class="users-table__tbody--edit" @click="$router.push({ name: 'edit-user', params:{ userId: user.id } })"/>
          <!-- <delete-icon class="users-table__tbody--delete" @click="onSubmitDelete(user.id )"/> -->
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import BurgerIcon from '@/components/icons/burger-icon'
// import DeleteIcon from '@/components/icons/delete-icon'
import EditIcon from '@/components/icons/edit-icon'
import StarIcon from '@/components/icons/star-icon'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'UsersTable',
  components: { EditIcon, StarIcon },
  props: ['users'],
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const showModal = ref(null)
    let userId
    const onSubmitDelete = (id) => {
      userId = id
      showModal.value = 'delete'
    }

    const deleteUser = async () => {
      const clientId = route.params.clientId
      try {
        await store.dispatch('users/deleteUser', userId)
        await router.push({ name: 'users-list', params: { clientId: clientId } })
        const params = {
          page: route.query.page || 1,
          limit: route.query.limit || 10,
          clientId: route.params.clientId
        }
        await store.dispatch('users/loadUsers', params)
      } catch (error) {
        console.log(error)
      }
    }
    return { onSubmitDelete, deleteUser, showModal }
  }
}
</script>

<style lang="scss" scoped>

.user-edit-modal {
  &__title {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    color: $color-black;
    margin-bottom: 16px;
  }

  &__notice {
    margin-bottom: 16px;
  }

  &__agree {
    margin-right: 8px;
  }
}

.users-table {
  width: 100%;
  border-spacing: 0;

  tbody:before {
    content:"@";
    display:block;
    line-height:8px;
    text-indent:-99999px;
  }

  &__thead {
    height: 33px;
    font-family: "Proxima Regular",sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: $color-grey;
    background-color: $color-white;
    & td {
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
    }
    & td:first-child {
      padding-left: 32px;
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    & td:last-child {
      padding-right: 32px;
      border: 1px solid $color-border-grey;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }

  &__tbody {
    font-size: 14px;
    line-height: 17px;
    color: $color-black;
    &--row {
      height: 40px;
      &_disabled {
        .users-table__tbody--name,
        .users-table__tbody--email,
        .users-table__tbody--phone,
        .users-table__tbody--role,
        .users-table__tbody--star,
        .users-table__tbody--orders {
          color: $color-grey;
          stroke: $color-grey;
          fill: $color-grey;
        }
      }
    }
    &--name {
      font-weight: bold;
      max-width: 465px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--email {
      color: $color-red;
      max-width: 228px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--phone {
      max-width: 133px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--role {
      div {
        display: flex;
      }
    }
    &--star {
      margin-right: 6px;
      fill: $color-red;
    }
    &--orders {
      color: $color-red;
      stroke: $color-red;
      cursor: pointer;
    }
    &--edit {
      cursor: pointer;
      margin-right: 25px;
    }
    &--delete {
      cursor: pointer;
    }
    &--button {
      text-align: right;
    }
    & td{
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
      background-color: $color-white;
      padding-right: 24px;

      &:first-child {
        padding-left: 32px;
        border: 1px solid $color-border-grey;
        border-right: 0;
      }

      &:last-child {
        padding-right: 32px;
        border: 1px solid $color-border-grey;
        border-left: 0;
      }
    }

    & tr:first-child {
      td:first-child {
        border-radius: 4px 0 0 0;
      }
      td:last-child {
        border-radius: 0 4px 0 0;
      }
    }

    & tr:last-child {
      td:first-child {
        border-radius: 0 0 0 4px;
      }
      td:last-child {
        border-radius: 0 0 4px 0;
      }
    }

    tr:not(:last-child) td{
      border-bottom: 0;
    }
  }
}
</style>
