<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <v-modal v-if="orderStatus" @closeModal="orderStatus = null" @submitModal="orderStatus = null">
    <div class="modal__header">
      <img class="modal__icon" :src="checkIcon" alt="">
      <h2 class="modal__title">Заказ {{ orderStatus === 'agree' ? 'согласован' : 'отклонён' }}</h2>
    </div>
    <p class="modal__text">
      Теперь он находится в
      <span class="modal__text_red">статусе «{{ orderStatus === 'agree' ? 'Согласован' : 'Отклонён' }}»</span>
      .
    </p>
    <v-button class="modal__button" type="button" size="l" text="ОК" @click="orderStatus = null"></v-button>
  </v-modal>
  <div v-if="!isLoading" class="order">
    <div class="order__container">
      <div class="order__header">
        <arrow-back-icon class="order__header--back" @click="$router.go(-1) || $router.push({ name: 'orders' })"/>
        <v-title>Заказ {{ order.number }} от {{ order.registrationDate }}</v-title>
      </div>
      <div class="order__user-info">
        <div class="order__user-info--main">
          <div v-if="order.customerName" class="order__user-info--item">
            <span class="order__user-info--title">Создал</span>
            <span class="order__user-info--value">{{ order.customerName }}</span>
          </div>
          <div v-if="order.coordinatorName" class="order__user-info--item">
            <span class="order__user-info--title">Согласующий</span>
            <span class="order__user-info--value">{{ order.coordinatorName }}, {{ order.coordinatingDate }}</span>
          </div>
          <div class="order__user-info--item" :class="{'order__user-info--item_wide': !order.customerName}">
            <span class="order__user-info--title">Сумма</span>
            <span class="order__user-info--value">{{currency(order.orderAmount)}} ₽</span>
          </div>
        </div>
        <div v-if="(order.deliveryStatus !== 'no_data_from_onec') || (order.paymentStatus !== 'no_data_from_onec')" class="order__user-info--status">
          <div v-if="order.deliveryStatus !== 'no_data_from_onec'" class="order__user-info--item">
            <span class="order__user-info--title">Статус заказа</span>
            <span class="order__user-info--value">{{ DELIVERY_STATUSES[order.deliveryStatus] }}</span>
          </div>
          <div v-if="order.isApproved && order.paymentStatus !== 'no_data_from_onec'" class="order__user-info--item">
            <span class="order__user-info--title">Статус оплаты</span>
            <span class="order__user-info--value">{{ PAYMENT_STATUSES[order.paymentStatus] }}</span>
          </div>
          <div class="order__user-info--item"></div>
        </div>
        <div v-if="order.deliveryAddress" class="order__user-info--address">
          <div class="order__user-info--item">
            <span class="order__user-info--title">Адрес доставки</span>
            <span class="order__user-info--value">{{ order.deliveryAddress }}</span>
          </div>
        </div>
      </div>
      <ul class="order__info">
        <li v-for="(product, index) in order.orderItems" :key="product.id" class="order__product">
            <div class="order__info-main">
              <div class="order__info-main--title"><span class="order__info-main--title-number">{{`${index+1}. `}}</span>{{product.name}}</div>
              <div class="order__info-main--status">
                <span>Доставка: {{product.deliveryType === 'physical' ? 'физическая' : 'ключ на E-mail'}}</span>
                <div v-if="order.isApproved" class="order__info-main--paymentStatus">
                  <span class="order__info-main--dot">•</span>
                  <span>{{ STATUSES[product.currentStatus] }}</span>
                  <div class="order__info-main--info">
                    <img :src="infoIcon" alt="">
                    <v-status-tooltip
                      :class="['order__info-main--tooltip', `order__info-main--tooltip_${direction}`]"
                      :info="product.statuses"
                      :deliveryType="product.deliveryType"
                      :currentStatus="product.currentStatus"
                    ></v-status-tooltip>
                  </div>
                </div>
              </div>
              <div class="order__info-main--code">
                <span v-if="product.sku">Артикул: {{product.sku}}</span>
                <span v-if="product.trackNumber"> • Трек-номер: {{product.trackNumber}}</span>
              </div>
            </div>
            <div class="order__info-sum">
              <p v-if="product.quantity > 1" class="order__info-sum--price" v-html="`${currency(product.itemAmount)} ₽ <wbr> х ${product.quantity} шт`" />
              <p v-else class="order__info-sum--price" />
              <div class="order__info-sum--total">
                <p>{{currency(product.fullItemsAmount)}} ₽</p>
                <p class="order__info-sum--nds" v-html="product.fullNdsAmount ? `включая НДС <br /> ${currency(product.fullNdsAmount)} ₽` : 'Без НДС'"></p>
              </div>
            </div>
        </li>
      </ul>
      <section class="order__chat">
        <div v-if="order.responsibleComment" class="order__message order__message--comment">
          <div class="order__message--header">
            <span class="order__message--user order__message--user_red">Согласующий {{ order.responsibleComment.commentatorName }}</span>
            <span class="order__message--date">{{ order.responsibleComment.date }} {{ order.responsibleComment.time }} (MSK)</span>
          </div>
          <p class="order__message--text">{{ order.responsibleComment.text }}</p>
        </div>
        <div v-if="order.employeeComment" class="order__message order__message--answer">
          <div class="order__message--header order__message--header_employee">
            <span class="order__message--user">{{ (order.customerName === userName || !isCoordinating) ? 'Ваше сообщение' : order.customerName }}</span>
            <span class="order__message--date">{{ order.employeeComment.date }} {{ order.employeeComment.time }} (MSK)</span>
          </div>
          <p class="order__message--text">{{ order.employeeComment.text }}</p>
        </div>
        <div v-if="!order.isReviewed && isCoordinating && !order.responsibleComment">
          <v-textarea
            class="order__chat--textarea"
            label="Ваш комментарий..."
            maxlength="250"
            v-model="textareaValue"
            :error="tError"
            :mask="allSymbolsMask"
            :handleChange="tChange"
            :disabled="isSubmitting"
          ></v-textarea>
          <v-error
            v-if="firstError"
            class="order__chat--error"
            :text="firstError"/>
          <div class="order__chat--buttons">
            <v-fill-green-button type="button" size="m" text="Согласовать" :disabled="isSubmitting" @click="buttonClickHandler('agree')" />
            <v-border-button type="button" size="m" text="Отклонить" :disabled="isSubmitting" @click="buttonClickHandler('reject')" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import infoIcon from '@/assets/icons/info.svg'
import checkIcon from '@/assets/icons/check.svg'

import currency from '@/utils/currency-formatter.js'
import { allSymbolsMask } from '@/utils/inputMasks'

import { STATUSES } from '@/utils/constants'

const DELIVERY_STATUSES = {
  no_data_from_onec: 'Нет данных',
  awaiting: 'Ожидает оплаты',
  accepted: 'Принят в работу',
  delivered: 'Доставлен',
  completed: 'Завершен'
}

const PAYMENT_STATUSES = {
  no_data_from_onec: 'Нет данных',
  paid: 'Оплачен',
  partially: 'Частично оплачен',
  overpaid: 'Переплачен',
  not_paid: 'Не оплачен'
}

export default {
  name: 'Order',
  components: { ArrowBackIcon },
  setup() {
    const store = useStore()
    const route = useRoute()

    const orderStatus = ref(null)
    const direction = ref('up')
    const serverError = ref(null)

    const isLoading = computed(() => store.getters['orders/isLoading'])
    const order = computed(() => store.getters['orders/getOrder'])
    const isCoordinating = computed(() => JSON.parse(localStorage.getItem('user')).isCoordinating)
    const userName = computed(() => {
      const firstName = JSON.parse(localStorage.getItem('user')).firstName
      const secondName = JSON.parse(localStorage.getItem('user')).secondName
      return `${secondName} ${firstName}`
    })

    const schema = yup.object({
      textareaValue: yup.string()
        .trim()
        .notRequired()
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Комментарий к заказу не может быть меньше 2 символов'
        })
    })

    const { handleSubmit, isSubmitting } = useForm({ validationSchema: schema })

    const { value: textareaValue, errorMessage: tError, handleChange: tChange } = useField('textareaValue', undefined, { validateOnValueUpdate: false })

    const firstError = computed(() => tError.value || serverError.value)

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || 'Ошибка сервера'
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = 'Ошибка сервера'
      } else {
        return true
      }
    }

    const goNextOrShowError = (response, status) => {
      const noError = serverErrorHandler(response)
      if (noError) orderStatus.value = status
    }

    const buttonClickHandler = handleSubmit(async (values, { evt: status }) => {
      const response = await store.dispatch('orders/approve', { id: order.value.id, comment: values.textareaValue, status })
      goNextOrShowError(response, status)
    })

    const setDirection = event => {
      direction.value = event.clientY > window.innerHeight * 0.6 ? 'down' : 'up'
    }

    onMounted(() => store.dispatch('orders/getOrderById', route.params.id))

    onMounted(() => document.addEventListener('mousemove', setDirection))
    onUnmounted(() => document.removeEventListener('mousemove', setDirection))

    watch(textareaValue, () => {
      serverError.value = null
    })

    return {
      DELIVERY_STATUSES, STATUSES, PAYMENT_STATUSES,
      allSymbolsMask, infoIcon, checkIcon, currency,
      textareaValue, tError, tChange,
      isLoading, order, isCoordinating, userName, orderStatus, direction, firstError, serverError, isSubmitting,
      buttonClickHandler
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  &__header {
    display: flex;
    padding-right: 195px;
  }
  &__icon {
    width: 35px;
    height: 27px;
    fill: $color-green;
  }
  &__title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    margin-left: 16px;
  }
  &__text {
    font-size: 16px;
    line-height: 21px;
    margin: 20px 0;
    &_red {
      color: $color-red;
    }
  }
  &__button {
    display: block;
    margin: 0 auto;
  }
}

.order {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 24px 100px 80px;
    background-color: $color-light-white;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    &--back {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &__user-info {
    display: flex;
    flex-direction: column;

    &--main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    &--status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    &--address {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    &--item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 30%;
      margin-bottom: 12px;
      &_wide {
        width: 65%;
      }
    }
    &--title {
      font-size: 12px;
      line-height: 14px;
      color: $color-grey
    }

    &--value {
      width: 100%;
      font-size: 16px;
      line-height:  21px;
      color: $color-black;
    }
  }
  &__info {
    margin-top: 18px;
  }
  &__product {
    background-color: $color-white;
    padding: 20px 32px;
    border-radius: 8px;
    border: 1px solid $color-border-grey;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__info-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 1px;
    width: 72%;

    &--title {
      max-height: 58px;
      font-weight: bold;
      font-size: 22px;
      line-height: 29px;
      color: $color-black;
      padding-right: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      &-number {
        font-weight: normal;
      }
    }
    &--status {
      display: flex;
      font-size: 16px;
      line-height: 21px;
      color: $color-black;
      margin: 2px 0 4px;
    }
    &--paymentStatus {
      display: flex;
    }
    &--dot {
      margin: 0 5px;
    }
    &--info {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 5px;
      cursor: pointer;
    }
    &--tooltip {
      display: none;
      position: absolute;
      transform: translateX(-50%);
      &_up {
        top: 25px;
      }

      &_down {
        bottom: 25px;
      }
    }
    &--code {
      color: $color-grey
    }
  }
  &__info-sum {
    display: flex;
    align-items: flex-start;
    font-size: 22px;
    line-height: 29px;
    color: $color-black;
    white-space: nowrap;

    &--price {
      width: 170px;
      margin-right: 28px;
      text-align: end;
    }
    &--total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-weight: bold;

      p {
        width: 120px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &--nds {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      text-align: end;
    }
  }
  &__chat {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    &--textarea {
      margin-top: 32px;
    }
    &--error {
      width: fit-content;
      margin-top: 16px;
    }
    &--buttons {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      & > button:first-child {
        margin-right: 12px;
      }
    }
  }
  &__message {
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    box-sizing: border-box;
    border-radius: 24px;
    width: 70%;
    padding: 24px;
    margin-bottom: 12px;
    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &_employee {
        background-color: transparent;
      }
    }

    &--user {
      margin-bottom: 5px;
      &_red {
        color: $color-red;
      }
    }

    &--date {
      color: $color-grey;
    }

    &--comment {
      float: left;
    }

    &--answer {
      align-self: flex-end;
    }
  }
}

.order__info-main--info:hover .order__info-main--tooltip {
  display: block;
}
</style>
