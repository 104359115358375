<template>
  <ul class="orders-list">
    <li v-for="order in orders" :key="order.id" class="order" @click.stop="$router.push({name: 'order', params: {id: order.id}})">
      <div class="order__header">
        <div class="order__main-info">
          <div class="order__title">
            <div class="order__title--number">{{`Заказ ${order.number}`}} </div>
            <div class="order__title--date"> {{`от ${order.registrationDate}`}}</div>
          </div>
          <div class="order__status">
            <div :class="['order__status--approve', isColor(order.approvalStatus)]">{{parseStatus[order.approvalStatus]}}</div>
            <div class="order__status--work" v-if="order.deliveryStatus && order.deliveryStatus !== 'no_data_from_onec' && order.approvalStatus === 'agree'">• {{parseStatus[order.deliveryStatus]}}</div>
            <div class="order__status--coordinator" v-if="$store.getters['auth/getUser'].isCoordinating" >• {{order.userName}}</div>
          </div>
        </div>
        <div class="order__pay-info">
          <div class="order__sum">{{new Intl.NumberFormat('ru-RU').format(order.orderAmount)}} ₽</div>
          <div v-if="order.payStatus !== 'no_data_from_onec'" class="order__pay-status">{{ parseStatus[order.payStatus] }}</div>
        </div>
      </div>
      <div class="order__body">
        <ul class="order__list">
          <li class="order__item" v-for="(product, index) in order.productNames" :key="index + product">
            <template v-if="index < 5">
              <span>{{`${index+1}. `}}</span>
              <span>{{product}}</span>
            </template>
          </li>
          <div v-if="order.productNames.length > 5">
            ...
          </div>
        </ul>
        <div class="order__documents">
          <a href="#" class="order__link" v-if="order.bill" @click.stop="downloadFile(order.bill)">
            <span>Счёт</span>
            <doc-icon></doc-icon>
          </a>
          <a v-for="(doc, index) in order.documents" :key="index" :href="doc" class="order__link" target="_blank" @click.stop>
            <span>{{ decodeURI(doc).split('/').at(-1) }}</span>
            <doc-icon></doc-icon>
          </a>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import DocIcon from '@/components/icons/doc-icon'
import { parseStatus } from '@/utils/constants'

export default {
  name: 'OrdersList', components: { DocIcon }, props: ['orders'],
  data() {
    return {
      parseStatus
    }
  },
  methods: {
    downloadFile(url) {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.download = 'file.pdf'
      link.dispatchEvent(new MouseEvent('click'))
    },
    isColor(value) {
      if (value === 'agree') {
        return 'green'
      } else if (value === 'reject') {
        return 'red'
      } else if (value === 'on_agreement') {
        return 'orange'
      }
    }
  }
}

</script>
<style scoped lang="scss">
@import "../assets/styles/variables";
  .orders-list {
    .order {
      border: 1px solid $color-border-grey;
      background-color: $color-white;
      border-radius: 8px;
      margin-bottom: 16px;
      cursor: pointer;

      &:hover {
        background-color: $color-light-white;
      }

      &__header {
        border-bottom: 1px solid $color-border-grey;
        padding: 20px 32px 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      &__main-info {
        display: flex;
        flex-direction: column;
      }

      &__title {
        display: flex;
        align-items: baseline;
        font-size: 22px;
        line-height: 29px;

        &--number {
          margin-right: 5px;
          font-weight: bold;
        }
      }
      &__status {
        display: flex;

        &--coordinator,
        &--work {
          margin-left: 5px;
        }

        & .green {
          color: $color-green
        }

        & .red {
          color: $color-red-status
        }

        & .orange {
          color: $color-orange
        }
      }

      &__pay-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &__sum {
        font-weight: bold;
        font-size: 22px;
        line-height: 29px;
      }
      &__body {
        padding: 12px 32px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      &__list {
        min-width: 1px;
        max-width: 83%;
        padding-right: 30px;
      }

      &__item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__documents {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        color: $color-red;
        text-decoration: none;

        & span {
          max-width: 150px;
          margin-right: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
