<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.00175 12.6667C4.00175 13.4 4.60175 14 5.33508 14H10.6684C11.4017 14 12.0017 13.4 12.0017 12.6667V4.66667H4.00175V12.6667ZM12.6684 2.66667H10.3351L9.66842 2H6.33508L5.66842 2.66667H3.33508V4H12.6684V2.66667Z" fill="#C90D46"/>
  </svg>
</template>

<script>
export default {
  name: 'deleteIcon'
}
</script>
