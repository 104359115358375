<template>
  <div class="users-panel">
    <teleport to="body" v-if="isLoading">
      <v-spinner />
    </teleport>
    <users-table v-else :users="users" />
    <v-pagination
      class="pagination"
      @changePage=changePage
      @changeLimit=changeLimit
      :total="pages.total"
      :limitList="pages.limitList"
      :limit="$route.query.limit ? +$route.query.limit : pages.limit"
      :page="$route.query.page ? +$route.query.page : pages.page"/>
  </div>
</template>

<script>
import { computed, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import UsersTable from '@/components/UsersTable'

export default {
  name: 'Users',
  components: { UsersTable },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const users = computed(() => store.getters['users/getUsers'])
    const isLoading = computed(() => store.getters['users/isLoading'])
    const pages = computed(() => store.getters['users/getPages'])

    let isUpdated = false

    onUpdated(() => {
      isUpdated = true
    })

    const changeLimit = value => {
      if (isUpdated) {
        const limit = value !== pages.value.limitList[0] ? value : undefined
        router.push({ query: { ...route.query, limit: limit, page: undefined } })
      }
    }

    const changePage = value => {
      if (isUpdated) {
        const page = value !== 1 ? value : undefined
        router.push({ query: { ...route.query, page: page } })
      }
    }

    watch(
      () => route.query,
      () => {
        if (route.name === 'users-list') {
          const params = {
            page: route.query.page || 1,
            limit: route.query.limit || 10,
            clientId: route.params.clientId
          }
          store.dispatch('users/loadUsers', params)
        }
      },
      { deep: true, immediate: true }
    )

    return {
      users, isLoading, pages,
      changeLimit, changePage
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/variables";
.users-panel {
  min-width: 900px;
  max-width: 1180px;
  padding-top: 24px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
}

.pagination {
  margin-top: 32px;
}
</style>
