<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__top">
        <div class="footer__top-info">
          <a class="link footer__conditions" :href="require('../assets/docs/Пользовательское_соглашение.pdf')" target="_blank">Пользовательское соглашение</a>
          <a class="link footer__confidentiality" href="https://softline.ru/privacy-policy" target="_blank">Политика конфиденциальности</a>
        </div>
        <a href="https://softline.ru/" target="_blank" class="link footer__site">Softline.ru</a>
      </div>
      <div class="footer__bottom">
        <img :src="require('../assets/images/footer_logo.png')" alt="Logo" />
        <p class="footer__description">Softline — лидер в области продажи оборудования и лицензионного программного обеспечения</p>
        <div class="footer__contacts">
          <p class="footer__contacts-copyright">© 1993—{{ new Date().getFullYear() }} Softline</p>
          <p class="footer__contacts-address">ПАО «Софтлайн». Юр. адрес: 119021, г. Москва, ул. Льва Толстого, дом 5, строение 1, этаж 3, помещение 1, комн. № 2, 2а (А-311)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $color-grey-bc;
  padding: 24px 0 35px;

  .link {
    font-size: 16px;
    line-height: 21px;
    color: $color-red;
    text-decoration: none;
  }

  &__container {
    min-width: 900px;
    max-width: 1180px;
    margin: auto;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    &-info {
      display: flex;
    }
  }

  &__conditions {
    margin-right: 32px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
  }

  &__description {
    width: 335px;
    color: $color-grey;
    margin-left: 32px;
  }

  &__contacts {
    color: $color-black;
    text-align: end;
    flex-grow: 1;
  }
}
</style>
