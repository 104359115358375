<template>
  <div class="client">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'Client'
}
</script>
<style lang="scss" scoped>
 .client {
   min-width: 900px;
   max-width: 1180px;
   padding: 36px 0;
   margin: 0 auto;
 }
</style>
