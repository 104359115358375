const checkFormats = validFormats => file => {
  if (!file) return true
  return validFormats.includes(file.name.split('.').slice(-1)[0].toLowerCase())
}

const maxSizeMb = Mb => file => {
  if (!file) return true
  return file.size <= 1048576 * Mb
}

export { checkFormats, maxSizeMb }
