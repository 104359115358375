const textError = (...values) => values.find(item => item)

const useTimer = (duration, minutes, seconds) => {
  let timer = duration
  const timerInterval = setInterval(function () {
    minutes.value = parseInt(timer / 60, 10)
    seconds.value = parseInt(timer % 60, 10)

    minutes.value = minutes.value < 10 ? '0' + minutes.value : minutes.value
    seconds.value = seconds.value < 10 ? '0' + seconds.value : seconds.value
    if (--timer < 0) {
      clearInterval(timerInterval)
    }
  }, 1000)
}

export { textError, useTimer }
