<template>
  <div class="home">
    <UserHeader />
    <SubHeader />
    <div class="content">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import UserHeader from '@/components/UserHeader'
import SubHeader from '@/components/SubHeader'
import Footer from '@/components/Footer'

export default {
  name: 'userHome',
  components: { UserHeader, SubHeader, Footer },
  setup() {
    const store = useStore()

    onMounted(() => {
      store.dispatch('cart/loadItems', { limit: 10000 })
    })
  }
}
</script>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 272px);
  background-image: url('../../assets/images/bg-dot.jpg');
  background-size: 26px;
  background-position: 13px 21px;
}
</style>
