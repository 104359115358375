<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7862 5.84669L11.5081 8.96867L12.285 13.3808C12.3647 13.9171 12.0272 14.1563 11.5637 13.8917L7.50868 11.8098L3.45552 13.8917C2.93636 14.1635 2.66195 13.8953 2.73426 13.3808L3.51115 8.96867L0.223747 5.84669C-0.14152 5.45893 -0.0673539 5.10742 0.503723 5.01863L5.03525 4.37358L7.06739 0.360124C7.29916 -0.120041 7.72005 -0.120041 7.95182 0.360124L9.98396 4.37358L14.5155 5.01863C15.0291 5.10198 15.1589 5.48249 14.7862 5.84669Z" />
  </svg>
</template>

<script>
export default {
  name: 'starIcon'
}
</script>
