<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <form class="user-create-form" @submit="onSubmit">
    <v-input
      placeholder="Фамилия"
      v-model="secondName"
      :error="sError"
      :handleChange="sChange"
      :mask="nameMask"
      :class-name="'secondName'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="Имя"
      v-model="firstName"
      :error="fError"
      :handleChange="fChange"
      :mask="nameMask"
      :class-name="'firstName'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="Отчество"
      v-model="patronymic"
      :error="lError"
      :handleChange="lChange"
      :mask="nameMask"
      :class-name="'patronymic'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="E-mail"
      v-model="email"
      :error="eError"
      :mask="emailMask"
      :handleChange="eChange"
      :class-name="'email'"
      :maxlength="64"
      :disabled="isSubmitting"/>
    <v-select
      label="Роль"
      v-model="role"
      :error="rError"
      :handleChange="rChange"
      :disabled="isSubmitting"
      :options="roleList"
      class="user-create-form__role" />
    <v-input
      placeholder="Моб. тел."
      v-model="phone"
      :error="pError"
      :handleChange="pChange"
      :mask="allSymbolsMask"
      :maxlength="250"
      :class-name="'phone'"
      :disabled="isSubmitting" />
    <v-error
      class="user-create-form__error"
      v-if="sError || fError || lError || eError || rError || pError"
      :text="textError(sError, fError, lError, eError, rError, pError)"/>
    <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Добавить" class="user-create-form__submit"></v-button>
  </form>

</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { nameMask, allSymbolsMask, emailMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'

const ROLE_LIST = [
  { id: 2, text: 'Согласующий', value: 'coordinator' },
  { id: 3, text: 'Пользователь', value: 'user' }
]

export default {
  name: 'UserCreateForm',
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isLoading = computed(() => store.getters['users/isLoading'])
    const roleList = ref(ROLE_LIST)
    const { handleSubmit, isSubmitting } = useForm()
    const { value: firstName, errorMessage: fError, handleChange: fChange } = useField('firstName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите имя')
      .min(2, 'Имя не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: secondName, errorMessage: sError, handleChange: sChange } = useField('secondName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите фамилию')
      .min(2, 'Фамилия не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: patronymic, errorMessage: lError, handleChange: lChange } = useField('patronymic', yup
      .string()
      .trim()
      .notRequired()
      .nullable()
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Отчество не может быть меньше 2 символов'
      }), { validateOnValueUpdate: false })
    const { value: email, errorMessage: eError, setErrors: eSetErrors, handleChange: eChange } = useField('email', yup
      .string()
      .trim()
      .required('Пожалуйста, введите email')
      .matches(/^.+@.+\..+$/, 'Невалидный email')
      .min(6, 'Email не может быть меньше 6 символов'), { validateOnValueUpdate: false })
    const { value: role, errorMessage: rError, handleChange: rChange } = useField('role', yup
      .string()
      .required('Пожалуйста, выберите роль'), { validateOnValueUpdate: false })
    const { value: phone, errorMessage: pError, handleChange: pChange } = useField('phone', yup
      .string()
      .trim()
      .required('Пожалуйста, введите номер телефона')
      .min(2, 'Номер телефона не может быть меньше 2 символов'), { validateOnValueUpdate: false })

    const onSubmit = handleSubmit(async values => {
      const user = {
        ...values,
        isCoordinating: values.role === 'coordinator',
        clientId: route.params.clientId
      }
      delete user.role
      try {
        const response = await store.dispatch('users/createUser', user)
        if (response?.status === 400) {
          eSetErrors(response.data.message)
        } else {
          await router.push({ name: 'users-list', params: { clientId: user.clientId } })
        }
      } catch (error) {
        console.log(error)
      }
    })

    return {
      roleList, isSubmitting, onSubmit, nameMask, allSymbolsMask, emailMask, textError,
      firstName, fError, fChange,
      secondName, sError, sChange,
      patronymic, lError, lChange,
      email, eError, eChange,
      role, rError, rChange,
      phone, pError, pChange,
      isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.user-create-form {
  width: 550px;
  display: flex;
  flex-direction: column;

  &__loading {
    display: flex;
    align-items: flex-start;
  }

  &__choose--file {
    margin-top: 0;
    margin-left: 8px;
  }

  &__role {
    margin-bottom: 16px;
  }

  &__error {
    align-self: flex-start;
  }

  &__submit {
    margin-top: 20px;
    align-self: flex-start;
  }

  &__container {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }
}

.v-input.v-input__field {
  margin-bottom: 16px;
}
</style>
