<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <modal-create-request v-if="showModal" @closeModal="showModal = false" :productName="data.name" />
  <div class="product-family">
    <div class="product-family__container">
      <v-title class="product-family__title">{{ data.name }}</v-title>
      <div class="product-family__info">
        <div class="product-family__images">
          <img v-if="data.images" class="product-family__images-main" :src="data.images[activeImage] || emptyImage" alt="">
          <div class="product-family__images-secondary">
            <div class="product-family__images-small" v-for="(image, index) in data.images" :key="image" @click="changeImage(index)">
              <img :src="image" alt="">
            </div>
          </div>
        </div>
        <div class="product-family__common">
          <div class="product-family__description" v-html="data.shortDescription"></div>
          <v-button v-if="!fullSeries.length" type="button" :size="'l'" text="Запросить цену" @click.stop="showModal = true"></v-button>
        </div>
      </div>
      <v-tabs class="product-family__tabs" :list="tabs" :active-index="activeTab" @change="changeActiveTab"></v-tabs>
      <div v-if="activeTab === 0">
        <div class="product-family__block" v-for="(product, index) in fullSeries" :key="product.id">
          <div class="product-family__block-header">
            <div class="product-family__block-number">{{ index + 1 }}</div>
            <h3 class="product-family__block-title" v-html="product.name"></h3>
          </div>
          <ul class="product-family__list">
            <product-family-item v-for="item in product.products" :key="item.id" :item="item" :cartIds="cartIds"></product-family-item>
          </ul>
        </div>
      </div>
      <div v-else class="product-family__licence" v-html="data.license"></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import emptyImage from '@/assets/images/empty.jpg'
import ProductFamilyItem from '@/components/ProductFamilyItem.vue'
import ModalCreateRequest from '@/components/ModalCreateRequest.vue'

export default {
  name: 'ProductFamily',
  components: { ProductFamilyItem, ModalCreateRequest },
  setup() {
    const store = useStore()
    const route = useRoute()

    const showModal = ref(false)
    const activeTab = ref(0)
    const activeImage = ref(0)

    const data = computed(() => store.getters['products/getData'])
    const language = computed(() => store.getters['products/getLanguage'])
    const isLoading = computed(() => store.getters['products/isLoading'])

    const cartItems = computed(() => store.getters['cart/getItems'])
    const cartIds = ref([])

    const fullSeries = computed(() => {
      if (data.value.series) {
        return data.value.series.filter((el) => el.products.length)
      }
      return []
    })

    const changeActiveTab = index => {
      activeTab.value = index
    }

    const changeImage = index => {
      activeImage.value = index
    }

    onMounted(() => {
      store.dispatch('cart/loadItems', { limit: 10000 })
      store.dispatch('products/loadFamily', { id: route.params.productFamilyId, language: language.value })
    })

    onUnmounted(() => {
      store.commit('products/clearFamily')
    })

    watch(cartItems, () => {
      cartIds.value = cartItems.value.map(el => el.productId)
    })

    return {
      isLoading, data, activeTab, activeImage, fullSeries, emptyImage, cartIds, showModal,
      changeActiveTab, changeImage,
      tabs: [
        { id: 1, title: 'Товары' },
        { id: 2, title: 'Лицензирование' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.product-family {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 24px 100px 80px;
    background-color: $color-light-white;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__info {
    display: flex;
    margin-bottom: 57px;
  }

  &__images {
    min-width: 308px;
    max-width: 308px;
    margin-right: 48px;

    &-main {
      width: 100%;
      height: 308px;
      margin-bottom: 16px;
      object-fit: contain;
    }

    &-secondary {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 4px;
    }

    &-small {
      position: relative;
      padding-bottom: 100%;
      grid-column: span 1;
      cursor: pointer;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__common {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__description {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-right: 70px;
    padding-top: 8px;
  }

  &__tabs {
    margin: 0 auto 24px;
  }

  &__block {
    margin-bottom: 24px;

    &-header {
      display: flex;
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      height: 24px;
      background-color: $color-border-grey;
      border-radius: 100%;
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
      color: $color-white;
      margin-right: 8px;
    }

    &-title {
      font-size: 22px;
      line-height: 29px;
      font-weight: 700;
      margin-bottom: 12px;
    }
  }

  &__list {
    background: $color-white;
    border: 1px solid $color-border-grey;
    border-radius: 12px;
    padding: 5px 32px 9px;
  }

  &__licence {
    width: 67%;
    margin: auto;
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
