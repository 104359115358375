<template>
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="11" rx="2.5" stroke="#4F4F4F"/>
    <path d="M4.5 4.5L8 8" stroke="white" stroke-linecap="round"/>
    <path d="M11.5 4.5L8 8" stroke="white" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DropDownIconWhite'
}
</script>
