<template>
  <div>
    <v-modal v-if="showModal" @closeModal="$router.push({ name: 'orders' })" @submitModal="$router.push({ name: 'orders' })">
      <div class="modal">
        <div class="modal__header">
          <img class="modal__icon" :src="checkIcon" alt="">
          <h2 class="modal__title">{{ isCoordinating ? 'Заказ оформлен' : 'Заказ отправлен на согласование' }}</h2>
        </div>
        <v-button class="modal__button" type="button" size="l" text="ОК" @click="$router.push({ name: 'orders' })"></v-button>
      </div>
    </v-modal>
    <div class="title" @click="$emit('setForm', 'cart')">
      <arrow-back-icon class="title-icon" />
      <v-title>Оформление заказа</v-title>
    </div>
    <form class="form" @submit="onSubmit">
      <v-input
        placeholder="E-mail"
        v-model="email"
        :error="eError"
        :handleChange="eChange"
        :mask="emailMask"
        :class-name="'email'"
        :maxlength="64"
        :disabled="isSubmitting"/>
      <v-textarea
        class="form__comment"
        label="Комментарий к заказу"
        v-model="recipientComment"
        :error="coError"
        :handleChange="coChange"
        :mask="allSymbolsMask"
        :maxlength="250"
        :disabled="isSubmitting"/>
      <v-error
        v-if="firstError"
        class="form__error"
        :text="textError(firstError)"/>
      <v-button
        class="form__button"
        type="submit"
        :size="'l'"
        :disabled="isSubmitting"
        :text="isCoordinating ? 'Оформить заказ' : 'Отправить на согласование'"
      ></v-button>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import checkIcon from '@/assets/icons/check.svg'
import { allSymbolsMask, emailMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'

const serverErrorText = 'Ошибка сервера'

export default {
  name: 'SoftwareForm',
  components: { ArrowBackIcon },
  emits: ['setForm'],
  setup() {
    const store = useStore()

    const showModal = ref(false)
    const serverError = ref(null)
    const isCoordinating = computed(() => JSON.parse(localStorage.getItem('user')).isCoordinating)

    const schema = yup.object({
      email: yup.string()
        .trim()
        .matches(/^.+@.+\..+$/, 'Невалидный email')
        .min(6, 'Email не может быть меньше 6 символов')
        .required('Заполнены не все обязательные поля'),
      recipientComment: yup.string()
        .trim()
        .notRequired()
        .matches(/.{2,}/, {
          excludeEmptyString: true,
          message: 'Комментарий к заказу не может быть меньше 2 символов'
        })
    })

    const { handleSubmit, isSubmitting, setFieldValue, errors } = useForm({ validationSchema: schema })

    const { value: email, errorMessage: eError, setErrors: eSetErrors, handleChange: eChange } = useField('email', undefined, { validateOnValueUpdate: false })
    const { value: recipientComment, errorMessage: coError, handleChange: coChange } = useField('recipientComment', undefined, { validateOnValueUpdate: false })

    const firstError = computed(() => eError.value || coError.value || serverError.value)

    const user = computed(() => store.getters['auth/getUser'])

    const setInitialValues = () => {
      setFieldValue('email', user.value.email)
    }

    onMounted(() => setInitialValues())

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }

    const closePageOrShowError = response => {
      const noError = serverErrorHandler(response)
      if (noError) showModal.value = true
    }

    const onSubmit = handleSubmit(async values => {
      try {
        const response = await store.dispatch('cart/createOrder', values)
        closePageOrShowError(response)
      } catch (error) {
        eSetErrors(error)
        console.log(error)
      }
    })

    return {
      checkIcon, showModal,
      isSubmitting, onSubmit, errors, firstError, isCoordinating,
      email, eError, eSetErrors, eChange,
      recipientComment, coError, coChange,
      allSymbolsMask, emailMask,
      textError
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
  }
  &__icon {
    width: 35px;
    height: 27px;
    fill: $color-green;
  }
  &__title {
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    margin-left: 16px;
  }
  &__button {
    margin-top: 16px;
  }
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 24px 0 36px;

  &-icon {
    margin-right: 12px;
  }
}

.form {
  width: 550px;

  &__button {
    margin-top: 20px;
  }

  &__comment {
    margin-bottom: 16px;
  }

  &__error {
    width: fit-content;
  }
}

.v-input.v-input__field {
  margin-bottom: 16px;
}
</style>
