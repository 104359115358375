<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <div class="orders">
    <div class="orders__container">
      <v-title class="orders__title">Заказы</v-title>
      <div class="orders__filters">
        <v-multi-select
          class="orders__filter-status"
          label="Статус оплаты и заказа"
          v-model="multiFilter"
          :disabled="false"
          @changeOptions="changeStatus"
          :error="mError"
          :handleChange="mChange"
          :options="optionsList"/>
        <v-year-filter
          :years="years"
          :activeYears="$route.query.years"
          @changeOptions="changeYear"
        />
      </div>
      <orders-list :orders="orders"/>
      <v-pagination
        @changePage=changePage
        @changeLimit=changeLimit
        :total="pages.total"
        :limitList="pages.limitList"
        :limit="$route.query.limit ? +$route.query.limit : pages.limit"
        :page="$route.query.page ? +$route.query.page : pages.page"/>
    </div>
  </div>
</template>

<script>
import vMultiSelect from '@/components/UI/v-multi-select'
import { useField } from 'vee-validate'
import VYearFilter from '@/components/UI/v-year-filter'
import OrdersList from '@/components/OrdersList'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Orders',
  components: { OrdersList, VYearFilter, vMultiSelect },
  setup() {
    const optionsList = [
      {
        title: 'payment',
        options: [
          {
            paid: false,
            name: 'paid'
          },
          {
            overpaid: false,
            name: 'overpaid'
          },
          {
            partiallyPaid: false,
            name: 'partiallyPaid'
          },
          {
            notPaid: false,
            name: 'notPaid'
          }
        ]
      },
      {
        title: 'delivery',
        options: [
          {
            accepted: false,
            name: 'accepted'
          },
          {
            awaitingPayment: false,
            name: 'awaitingPayment'
          },
          {
            delivered: false,
            name: 'delivered'
          },
          {
            completed: false,
            name: 'completed'
          }
        ]
      }
    ]

    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const years = computed(() => store.getters['orders/getYears'])
    const isLoading = computed(() => store.getters['orders/isLoading'])
    const pages = computed(() => store.getters['orders/getPages'])
    const orders = computed(() => store.getters['orders/getOrders'])
    let isUpdated = false
    const { value: multiFilter, errorMessage: mError, handleChange: mChange } = useField('multiFilter')

    onMounted(() => {
      store.dispatch('orders/getYearsList')
    })
    onUpdated(() => {
      isUpdated = true
    })

    const changeStatus = (arr) => {
      const activeFilters = []
      if (arr.length) {
        arr.forEach(item => item.options.forEach(option => {
          if (option[option.name]) {
            activeFilters.push(option.name)
          }
        }))
      }
      router.push({ query: { ...route.query, status: activeFilters.length ? activeFilters.join(';') : undefined } })
    }

    const changeYear = value => {
      router.push({ query: { ...route.query, years: value || undefined } })
    }

    const changePage = (value) => {
      if (isUpdated) {
        const page = value !== 1 ? value : undefined
        router.push({ query: { ...route.query, page: page } })
      }
    }

    const changeLimit = (value) => {
      if (isUpdated) {
        const limit = value !== pages.value.limitList[0] ? value : undefined
        router.push({ query: { ...route.query, limit: limit, page: undefined } })
      }
    }

    watch(
      () => route.query,
      () => {
        if (route.query?.status) {
          const activeFilters = route.query.status.split(';')
          optionsList.map(item => item.options.map(option => {
            if (activeFilters.includes(option.name)) {
              option[option.name] = true
            }
          }))
        }
        const statusArr = route.query.status ? route.query.status.split(';') : []
        const params = {
          ...route.query,
          page: route.query.page || 1,
          limit: route.query.limit || 10,
          years: route.query.years || undefined,
          status: statusArr
        }
        store.dispatch('orders/getOrders', params)
      },
      { deep: true, immediate: true }
    )
    return { multiFilter, mError, mChange, optionsList, orders, years, isLoading, pages, changePage, changeLimit, changeStatus, changeYear }
  }
}
</script>

<style scoped lang="scss">
.orders {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 24px 100px 80px;
    background-color: $color-light-white;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  &__filter-status {
    min-width: 358px;
    max-width: 100%;
  }
}
</style>
