<template>
  <table class="clients-table">
    <thead class="clients-table__thead">
      <tr class="clients-table__thead--row">
        <td>Логотип</td>
        <td>Название юрлица</td>
        <td>Домен</td>
        <td>ИНН</td>
        <td>КПП</td>
        <td>ID в 1С</td>
      </tr>
    </thead>
    <tbody class="clients-table__tbody">
      <template v-for="client in clients" :key="client.id">
        <tr class="clients-table__tbody--row" :class="{'clients-table__tbody--row-disabled': client.status === 'blocked'}" @click="$router.push({ name: 'client', params: { clientId: client.id } })">
          <td class="clients-table__tbody--logo">
            <img :src="client.logo.url" alt="Логотип">
          </td>
          <td class="clients-table__tbody--name">
            <span>{{ client.name }}</span>
          </td>
          <td class="clients-table__tbody--domain">{{ client.domain }}</td>
          <td class="clients-table__tbody--inn">{{ client.inn }}</td>
          <td class="clients-table__tbody--kpp">{{ client.kpp }}</td>
          <td class="clients-table__tbody--idUrPerson">
            <span>{{ client.idUrPerson }}</span>
          </td>
        </tr>
        <tr class="clients-table__tbody--spacer"></tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ClientsTable',
  props: ['clients', 'total', 'limit', 'page']
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.clients-table {
  width: 100%;
  border-spacing: 0;

  &__thead {
    height: 33px;
    font-family: "Proxima Regular",sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: $color-grey;
    background-color: $color-white;
    & td {
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
    }
    & td:first-child {
      padding-left: 32px;
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-radius: 4px 0 0 4px;
    }
    & td:last-child {
      padding-right: 32px;
      border: 1px solid $color-border-grey;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }

  tbody::before {
    content: "@";
    display: block;
    line-height: 12px;
    text-indent: -99999px;
  }
  &__tbody {
    font-size: 16px;
    line-height: 19px;
    color: $color-black;
    &--row {
      height: 98px;
      background: $color-white;
      cursor: pointer;

      &-disabled {
        filter: grayscale(1);
        opacity: 0.5;
      }
    }
    &--spacer {
      height: 16px;
      &:last-child {
        display: none;
      }
    }
    &--logo {
      img {
        vertical-align: middle;
        max-height: 70px;
        max-width: 160px;
      }
    }
    &--name {
      font-weight: bold;
      max-width: 330px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    &--domain {
      max-width: 144px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--inn {
      max-width: 114px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--kpp {
      max-width: 101px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--idUrPerson {
      max-width: 274px;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    & td{
      border: 1px solid $color-border-grey;
      border-right: 0;
      border-left: 0;
      background-color: $color-white;
      padding-right: 24px;
      &:first-child {
        padding-left: 32px;
        border: 1px solid $color-border-grey;
        border-right: 0;
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        padding-right: 32px;
        border: 1px solid $color-border-grey;
        border-left: 0;
        border-radius: 0 8px 8px 0;
      }
    }
  }
  &__tbody tr {
    cursor: pointer;
    &:hover td{
      background-color: $color-light-white;
      //box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2);
    }
  }
}

.pagination {
  margin-top: 32px;
}
</style>
