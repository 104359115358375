<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <v-modal v-if="showModal === 'blocked'" @closeModal="showModal = false" @submitModal="blockAdmin">
    <h3 class="admin-edit-modal__title">Заблокировать администратора?</h3>
    <v-border-button type="button" :size="'m'" text="Да" class="admin-edit-modal__agree" @click="blockAdmin"></v-border-button>
    <v-button type="button" :size="'m'" text="Нет" class="admin-edit-modal__cancel" @click="showModal = null"></v-button>
  </v-modal>
  <v-modal v-if="showModal === 'delete'" @closeModal="showModal = false" @submitModal="deleteAdmin">
    <h3 class="admin-edit-modal__title">Удалить администратора?</h3>
    <v-border-button type="button" :size="'m'" text="Да" class="admin-edit-modal__agree" @click="deleteAdmin"></v-border-button>
    <v-button type="button" :size="'m'" text="Нет" class="admin-edit-modal__cancel" @click="showModal = null"></v-button>
  </v-modal>
  <form class="admin-edit-form" @submit="onSubmitEdit">
    <v-input
      placeholder="Фамилия"
      v-model="secondName"
      :error="sError"
      :handleChange="sChange"
      :mask="nameMask"
      :class-name="'secondName'"
      class="admin-edit-form__input"
      :maxlength="250"
      :disabled="isSubmitting || admin.status === 'blocked'"/>
    <v-input
      placeholder="Имя"
      v-model="firstName"
      :error="fError"
      :handleChange="fChange"
      :mask="nameMask"
      :class-name="'firstName'"
      class="admin-edit-form__input"
      :maxlength="250"
      :disabled="isSubmitting || admin.status === 'blocked'"/>
    <v-input
      placeholder="Отчество"
      v-model="patronymic"
      :error="lError"
      :handleChange="lChange"
      :mask="nameMask"
      :class-name="'patronymic'"
      class="admin-edit-form__input"
      :maxlength="250"
      :disabled="isSubmitting || admin.status === 'blocked'"/>
    <div class="admin-edit-form__email">
      <v-input
        placeholder="E-mail"
        v-model="email"
        :error="eError"
        :handleChange="eChange"
        :mask="emailMask"
        :class-name="'email'"
        class="admin-edit-form__input"
        :maxlength="64"
        :disabled="isSubmitting || admin.status === 'blocked'"/>
      <div :class="['admin-edit-form__repeat', {'admin-edit-form__repeat--block': admin.status === 'blocked'}]" @click="repeatEmail">
        <repeat-icon/>
        <span class="admin-edit-form__repeat--text">Отправить повторно</span>
      </div>
    </div>
    <v-input
      placeholder="Моб. тел."
      v-model="phone"
      :error="pError"
      :handleChange="pChange"
      :mask="allSymbolsMask"
      :maxlength="250"
      :class-name="'phone'"
      class="admin-edit-form__input"
      :disabled="isSubmitting || admin.status === 'blocked'"/>
    <v-error
      class="admin-edit-form__error"
      v-if="sError || fError || lError || eError || pError || serverError"
      :text="textError(sError, fError, lError, eError, pError, serverError)"/>
    <div class="admin-edit-form__buttons">
      <v-button type="submit" :size="'l'" :disabled="isSubmitting || admin.status === 'blocked'" text="Сохранить"
                class="admin-edit-form__save"/>
      <div class="admin-edit-form__control">
        <v-border-button type="button" :size="'m'" :disabled="isSubmitting || admin.status === 'blocked'" :text="!admin?.value?.disabled ? 'Заблокировать' : 'Разблокировать'"
                         class="admin-edit-form__block" @click="onSubmitBlock"/>
        <v-border-button type="button" :size="'m'" :disabled="isSubmitting" text="Удалить"
                  class="admin-edit-form__delete" @click="onSubmitDelete"></v-border-button>
      </div>
    </div>
  </form>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import * as yup from 'yup'

import { useField, useForm } from 'vee-validate'
import RepeatIcon from '@/components/icons/repeat-icon'
import { nameMask, allSymbolsMask, emailMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'

const serverErrorText = 'Ошибка сервера'

export default {
  name: 'AdminEditForm',
  components: { RepeatIcon },
  setup () {
    const showModal = ref(null)
    const serverError = ref(null)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const admin = computed(() => store.getters['admins/getAdmin'])
    const isLoading = computed(() => store.getters['admins/isLoading'])
    onMounted(() => store.dispatch('admins/loadAdminById', route.params.id))
    const { handleSubmit, isSubmitting, setFieldValue } = useForm()
    const setInitialValues = () => {
      setFieldValue('firstName', admin.value?.firstName)
      setFieldValue('secondName', admin.value?.secondName)
      setFieldValue('patronymic', admin.value?.patronymic || '')
      setFieldValue('email', admin.value?.email)
      setFieldValue('phone', admin.value?.phone)
    }
    watch(admin, (values, _) => {
      setInitialValues()
    })
    const { value: secondName, errorMessage: sError, handleChange: sChange } = useField('secondName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите фамилию')
      .min(2, 'Фамилия не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: firstName, errorMessage: fError, handleChange: fChange } = useField('firstName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите имя')
      .min(2, 'Имя не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: patronymic, errorMessage: lError, handleChange: lChange } = useField('patronymic', yup
      .string()
      .trim()
      .notRequired()
      .nullable()
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Отчество не может быть меньше 2 символов'
      }), { validateOnValueUpdate: false })
    const { value: email, errorMessage: eError, setErrors: eSetErrors, handleChange: eChange } = useField('email', yup
      .string()
      .trim()
      .required('Пожалуйста, введите email')
      .matches(/^.+@.+\..+$/, 'Невалидный email')
      .min(6, 'Email не может быть меньше 6 символов'), { validateOnValueUpdate: false })
    const { value: phone, errorMessage: pError, handleChange: pChange } = useField('phone', yup
      .string()
      .trim()
      .required('Пожалуйста, введите номер телефона')
      .min(2, 'Номер телефона не может быть меньше 2 символов'), { validateOnValueUpdate: false })

    const onSubmitBlock = () => {
      showModal.value = 'blocked'
    }
    const onSubmitDelete = () => {
      showModal.value = 'delete'
    }
    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }
    const closePageOrShowError = response => {
      const noError = serverErrorHandler(response)
      noError && router.push({ name: 'admins-list' })
      showModal.value = null
    }
    const onSubmitEdit = handleSubmit(async values => {
      const newAdmin = {
        ...values,
        id: route.params.id
      }
      try {
        await store.dispatch('admins/editAdmin', newAdmin)
        await router.push({ name: 'admins-list' })
      } catch (error) {
        eSetErrors(error)
        console.log(error)
      }
    })
    const blockAdmin = async () => {
      try {
        const response = await store.dispatch('admins/blockAdmin', route.params.id)
        closePageOrShowError(response)
      } catch (error) {
        console.log(error)
      }
    }
    const deleteAdmin = async () => {
      try {
        const response = await store.dispatch('admins/deleteAdmin', route.params.id)
        closePageOrShowError(response)
      } catch (error) {
        console.log(error)
      }
    }
    const repeatEmail = async () => {
      try {
        const response = await store.dispatch('admins/repeatEmail', route.params.id)
        serverErrorHandler(response)
      } catch (error) {
        console.log(error)
      }
    }
    return {
      firstName,
      fError,
      fChange,
      secondName,
      sError,
      sChange,
      patronymic,
      lError,
      lChange,
      email,
      eError,
      eChange,
      phone,
      pError,
      pChange,
      onSubmitEdit, onSubmitBlock, onSubmitDelete, blockAdmin, deleteAdmin, repeatEmail,
      isSubmitting,
      admin, isLoading,
      nameMask, allSymbolsMask, emailMask,
      textError,
      showModal,
      serverError
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/styles/variables";

.admin-edit-modal {
  &__title {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    color: $color-black;
    margin-bottom: 16px;
  }

  &__agree {
    margin-right: 8px;
  }
}

.admin-edit-form {

  &__input {
    width: 550px;
  }
  &__error {
    display: inline-flex;
  }
  &__email {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__repeat {
    margin-left: 20px;
    margin-bottom: 16px;

    &--block {
      filter: grayscale(1);
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }
  }

  &__repeat {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;

    &--text {
      font-weight: bold;
      color: $color-red;
      font-size: 16px;
      line-height: 19px;
      margin-left: 8px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__save, &__control {
    margin-top: 20px;
  }
  &__block {
    margin-right: 12px;
  }

  .v-input.v-input__field {
    margin-bottom: 16px;
  }
}
</style>
