<template>
  <div class="user-info">
    <div class="user-info__container">
      <v-title>Профиль пользователя</v-title>
      <div class="user-info__data">
        <div class="user-info__data-block">
          <p class="user-info__data-title">Фамилия</p>
          <p class="user-info__data-value">{{ user.secondName }}</p>
        </div>
        <div class="user-info__data-block">
          <p class="user-info__data-title">Имя</p>
          <p class="user-info__data-value">{{ user.firstName }}</p>
        </div>
        <div v-if="user.patronymic" class="user-info__data-block">
          <p class="user-info__data-title">Отчество</p>
          <p class="user-info__data-value">{{ user.patronymic }}</p>
        </div>
        <div class="user-info__data-block">
          <p class="user-info__data-title">E-mail</p>
          <p class="user-info__data-value">{{ user.email }}</p>
        </div>
        <div class="user-info__data-block">
          <p class="user-info__data-title">Телефон</p>
          <p class="user-info__data-value">{{ user.phone }}</p>
        </div>
        <div class="user-info__data-block">
          <p class="user-info__data-title">Роль</p>
          <p class="user-info__data-value">{{ user.isCoordinating ? 'Согласующий' : 'Пользователь' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  setup() {
    const user = JSON.parse(localStorage.getItem('user'))

    return { user }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 36px 100px 80px;
    background-color: $color-light-white;
  }

  &__data {
    margin: 30px 20px;

    &-block {
      margin-bottom: 16px;
    }

    &-title {
      font-size: 12px;
      line-height: 14px;
      color: $color-grey;
      margin-bottom: 2px;
    }

    &-value {
      width: 90%;
      font-size: 16px;
      line-height: 21px;
      word-break: break-all;
    }
  }
}
</style>
