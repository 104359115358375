<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <div v-if="!$route.path.split('/')[2] || $route.path.split('/')[2] === 'search'" class="block">
    <div class="block__container">
      <form class="search" @submit.prevent="onSubmit">
        <v-search-input
          v-model.trim="value"
          placeholder="Название товара, имя производителя, артикул (SKU), теги или описание"
        ></v-search-input>
        <v-button class="search__button" type="submit" size="l" text="Найти"></v-button>
      </form>
    </div>
  </div>
  <router-view />
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'CatalogMain',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const value = ref(route.query.query)
    const clearValue = computed(() => store.getters['search/getClearValue'])
    const isLoading = computed(() => store.getters['search/isLoading'])

    const onSubmit = () => {
      if (!isLoading.value && value.value && value.value.length > 2) {
        router.push({ name: 'search', query: { query: value.value } })
        store.commit('search/setAbortDebounce', true)
      }
    }

    onMounted(() => store.dispatch('cart/loadAllItems'))

    watch(clearValue, () => {
      value.value = ''
    })

    watch(value, () => {
      store.commit('search/setValue', value.value)
    }, { deep: true })

    return { value, isLoading, onSubmit }
  }
}
</script>

<style lang="scss" scoped>
.block {
  background-color: $color-grey-bc;
  padding: 16px 0;

  &__container {
    min-width: 900px;
    max-width: 1180px;
    margin: auto;
  }
}

.search {
  display: flex;

  &__button {
    margin-left: 12px;
  }
}
</style>
