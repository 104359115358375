<template>
  <div class="search-card" @click="$router.push(getRoute)">
    <p class="search-card__title" v-html="card.name"></p>
    <p class="search-card__text" v-html="card.shortDescription"></p>
    <div class="search-card__description">
      <span class="red" v-html="card.vendor"></span>
      <div class="search-card__dot" />
      <p>
        <span>Артикул: </span>
        <span v-html="card.sku"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchCard',
  props: ['card'],
  computed: {
    getRoute() {
      if (this.card.familyId) {
        return { name: 'product-family', params: { productFamilyId: this.card.familyId } }
      } else {
        return { name: 'product', params: { productId: this.card.id } }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-card {
  font-size: 16px;
  background-color: $color-white;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    background-color: $color-light-white;
  }

  &__title {
    line-height: 19px;
    font-weight: 700;
  }

  &__text {
    max-height: 42px;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 4px;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__description {
    display: flex;
    color: $color-grey;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: $color-grey;
    margin: auto 5px;
  }
}
</style>
