<template>
  <div class="main-client">
    <header>
      <v-title class="main-client__title">{{client.name}}</v-title>
      <div class="main-client__menu">
        <v-sub-navigation :navs="navs"/>
        <div>
          <v-icon-button v-if="this.$route.name === 'about-client'" text="Редактировать информацию" @click="$router.push({name: 'edit-client'})">
            <edit-big-icon/>
          </v-icon-button>
          <v-icon-button v-if="this.$route.name === 'users-list' && client.status !== 'blocked'" text="Добавить пользователя" @click="$router.push({ name: 'create-user' })">
            <add-icon/>
          </v-icon-button>
        </div>
      </div>
    </header>
    <router-view/>
  </div>
</template>
<script>
import VSubNavigation from '@/components/UI/v-sub-navigation'
import EditBigIcon from '@/components/icons/edit-big-icon'
import addIcon from '@/components/icons/add-icon'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'MainClients',
  components: { EditBigIcon, VSubNavigation, addIcon },
  setup() {
    const store = useStore()
    const route = useRoute()
    const client = computed(() => store.getters['clients/getClient'])
    onMounted(() => store.dispatch('clients/loadClientById', route.params.clientId))
    return {
      client,
      navs: [
        { id: 1, nameLink: 'about-client', text: 'О компании' },
        // { id: 2, nameLink: 'orders-client', text: 'Заказы' },
        { id: 3, nameLink: 'users-list', text: 'Пользователи' },
        { id: 4, nameLink: 'requests-client', text: 'Запросы' }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
 .main-client {
    &__title {
      margin-bottom: 16px;
    }

    &__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    }
 }
</style>
