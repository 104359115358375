<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM21.4 14.952V16.848H17.104V21.72H15.016V16.848H10.696V14.952H15.016V10.272H17.104V14.952H21.4Z" fill="#A4182C"/>
  </svg>
</template>
<script>
export default {
  name: 'addIcon'
}
</script>
