<template>
  <form class="form" @submit.prevent="checkCode">
    <h1>Проверка кода</h1>
    <v-input
      placeholder="Код"
      v-model="password"
      :error="pError || codeError || counterCodeCheck === 3"
      :handleChange="pChange"
      :maxlength="250"
      :mask="codeMask"
      :class-name="'password'"
      :disabled="isSubmitting || counterCodeCheck === 3 || !isCodeActive"/>
    <v-checkbox v-if="!isApproved" v-model="approve" :class-name="'approve'" :size="'m'">
      <p class="form__approve"> Я ознакомился и принимаю</p>
      <a class="form__approve red" :href="require('../assets/docs/Пользовательское_соглашение.pdf')" target="_blank">Пользовательское соглашение</a>
    </v-checkbox>
    <v-error v-if="pError && counterCodeCheck !== 3 && isCodeActive" :text="pError"
             :class="['form__error', {'form__error&#45;&#45;withApprove': isApproved}]"></v-error>
    <v-error v-if="codeError && isCodeActive" :text="codeError"
             :class="['form__error', {'form__error--withApprove': isApproved}]"></v-error>
    <div v-if="counterCodeCheck === 3 || 'Количество попыток закончилось' === codeError" class="form__module">
      <v-button
        type="submit"
        @click="repeatCode"
        disabled
        :size="'l'"
        :text="`Отправить код через ${minutesBlockButton}:${secondsBlockButton}`"
        :class="['form__button', {'form__button--withError': counterCodeCheck}]"/>
    </div>
    <div v-else>
      <div class="form__module" v-if="!isCodeActive">
        <v-error :text="'Срок действия кода истёк'"
                 :class="['form__error', {'form__error--withApprove': isApproved}]"></v-error>
        <v-button
          v-if="!isSubmitting"
          type="submit"
          :class="['form__button', {'form__button--withError': pError || codeError}]"
          :size="'l'"
          @click.prevent="repeatCode"
          text="Отправить повторно"
          :disabled="isSubmitting"/>
      </div>
      <div v-else class="form__module">
        <v-button
          type="submit"
          :class="['form__button', {'form__button--withError': pError || codeError}]"
          :size="'l'"
          @click.prevent="checkCode"
          text="Войти"
          :disabled="!password || !approve || isSubmitting || codeError"/>
        <span v-if="isApproved && timer2 === 0" class="form__button--repeat" @click.prevent="repeatCode">
          <a href="">Отправить повторно</a>
        </span>
        <v-button
          class="form__timer"
          v-if="timer2 !== 0"
          disabled
          :size="'l'"
          :text="`Повторить можно через ${minutesBlockButton2 === '00' && secondsBlockButton2 === '00' ? '' : `${minutesBlockButton2}:${secondsBlockButton2}`}`" />
      </div>
    </div>
  </form>
</template>
<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch } from 'vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { codeMask } from '@/utils/inputMasks'
import { useTimer } from '@/utils/utils'
import { setCookies } from '@/utils/cookie'
import { baseURL } from '@/utils/constants'

export default {
  name: 'codeForm', components: {}, setup () {
    const store = useStore()
    const { isSubmitting, handleSubmit, setFieldValue } = useForm()
    const { value: password, errorMessage: pError, handleChange: pChange } = useField('password', yup
      .string()
      .trim()
      .required('Пожалуйста, введите код')
      .min(7, 'Код авторизации не может быть меньше 6 символов'), { validateOnValueUpdate: false })
    const { value: approve, errorMessage: aError, handleChange: aChange } = useField('approve', yup
      .boolean()
      .required('Пожалуйста, подтвердите Политику'), { validateOnValueUpdate: false })

    const codeError = computed(() => store.getters['auth/getErrors'].codeError)
    const isApproved = computed(() => store.getters['auth/getApprovePolitic'])
    const counterCodeCheck = computed(() => store.getters['auth/getCounter'])

    const checkCode = handleSubmit(async () => {
      if (!pError.value) {
        try {
          await store.dispatch('auth/checkCode', password.value.replace(/\s+/g, ''))
            .then(() => {})
            .catch(error => {
              if (error === 'Количество попыток закончилось') {
                store.commit('auth/setCounterCodeCheck', 3)
                useTimer(119, minutesBlockButton, secondsBlockButton)
              }
            })
          if (store.getters['auth/getRole'] === 'admin') {
            // Если залогинились под админом попадаем на layout админки
            const urlWithSubDomain = process.env.NODE_ENV === 'production' ? `https://portal.${baseURL}` : `https://portal.${baseURL}:8080`
            await saveTokensInCookies()
            localStorage.clear()
            window.location.replace(urlWithSubDomain)
            // await router.push('/admin')
          } else if (store.getters['auth/getRole'] === 'employee') {
            // ЛОГИКА РЕДИРЕКТА НА ПОДДОМЕН
            // получаем поддомен из данных о пользователе и создаём новый url для редиректа клиента на свой поддомен
            await store.dispatch('auth/getInfo')
            const subDomain = store.getters['auth/getUser'].domain
            const urlWithSubDomain = process.env.NODE_ENV === 'production' ? `https://${subDomain}.${baseURL}` : `https://${subDomain}.${baseURL}:8080`
            // сохраняем токены авторизации в кукитцы, чтобы получить к ним доступ на поддомене после редиректа
            await saveTokensInCookies()
            // удаляем состояние, чтобы вовзвращаясь на основной домен не быть залогиненным по данным из localStorage
            localStorage.clear()
            // редирект на новый поддомен
            window.location.replace(urlWithSubDomain)
          }
        } catch (error) {
          console.log('errorCode', error)
        }
      }
    })
    onMounted(() => startLifeCodeTimer())
    let codeTimer
    const isCodeActive = ref(true)
    const minutesBlockButton = ref('02')
    const secondsBlockButton = ref('00')

    const saveTokensInCookies = () => {
      const cookies = new Map([
        ['accessToken', store.getters['auth/getAccessToken']],
        ['refreshToken', store.getters['auth/getRefreshToken']],
        ['domain', store.getters['auth/getUser'].domain || 'portal']
      ])
      setCookies(cookies)
    }
    const startLifeCodeTimer = () => {
      isCodeActive.value = true
      codeTimer = setTimeout(() => { isCodeActive.value = false }, 60 * 60 * 1000)
    }

    const repeatCode = async () => {
      clearTimeout(codeTimer)
      startLifeCodeTimer()
      const email = store.getters['auth/getUser'].email
      await store.dispatch('auth/checkEmail', email)
    }
    watch(counterCodeCheck, (value, _) => {
      if (value === 3) {
        useTimer(119, minutesBlockButton, secondsBlockButton)
      }
    })
    watch(password, (value, _) => {
      if (counterCodeCheck.value && codeError.value) {
        store.commit('auth/setCodeError', null)
      }
    })
    watch([minutesBlockButton, secondsBlockButton], (values, _) => {
      if (values[0] === '00' && values[1] === '00') {
        store.commit('auth/setCodeError', null)
        store.commit('auth/setCounterCodeCheck', 0)
        setFieldValue('password', null)
        minutesBlockButton.value = '02'
        secondsBlockButton.value = '00'
      }
    })

    const timer2 = computed(() => store.getters['auth/getTimerFromServer'])
    const minutesBlockButton2 = ref('')
    const secondsBlockButton2 = ref('')

    watch(timer2, () => {
      useTimer(timer2.value, minutesBlockButton2, secondsBlockButton2)
    }, { immediate: true, deep: true })
    watch([minutesBlockButton2, secondsBlockButton2], () => {
      if (minutesBlockButton2.value === '00' && secondsBlockButton2.value === '00') {
        store.commit('auth/setTimerFromServer', 0)
      }
    })

    return {
      isSubmitting,
      password, pError, pChange, codeMask,
      approve, aError, aChange,
      useTimer,
      codeError,
      counterCodeCheck, isApproved, isCodeActive,
      checkCode, repeatCode,
      minutesBlockButton, secondsBlockButton,
      timer2, minutesBlockButton2, secondsBlockButton2
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/styles/variables";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > :not(&:first-child) {
    align-self: center;
  }

  &__approve {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    white-space: nowrap;

    &.red {
      margin-left: 3px;
      color: $color-red;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__button {
    margin-top: 12px;

    &--withError {
      margin-top: 0;
    }

    &--repeat {
      margin-top: 8px;

      & a {
        text-decoration: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 21px;
        color: $color-red;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__error {
    margin-top: 24px;

    &--withApprove {
      margin-top: 0;
    }
  }

  &__module {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__timer {
    margin-top: 16px;
  }
}

</style>
