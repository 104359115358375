<template>
  <div class="clients-panel">
    <div class="clients-panel__header">
      <v-title>Клиенты</v-title>
      <v-icon-button text="Добавить клиента" @click="$router.push({ name: 'create-client' })">
        <add-icon/>
      </v-icon-button>
    </div>
    <teleport to="body" v-if="isLoading">
      <v-spinner />
    </teleport>
    <clients-table v-else :clients="clients" class="clients-panel__table"/>
    <v-pagination
      class="pagination"
      @changePage=changePage
      @changeLimit=changeLimit
      :total="pages.total"
      :limitList="pages.limitList"
      :limit="$route.query.limit ? +$route.query.limit : pages.limit"
      :page="$route.query.page ? +$route.query.page : pages.page"/>
  </div>
</template>

<script>
import addIcon from '@/components/icons/add-icon'
import ClientsTable from '@/components/ClientsTable'

import { computed, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'ClientsPanel',
  components: { addIcon, ClientsTable },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const clients = computed(() => store.getters['clients/getClients'])
    const isLoading = computed(() => store.getters['clients/isLoading'])
    const pages = computed(() => store.getters['clients/getPages'])

    let isUpdated = false

    onUpdated(() => {
      isUpdated = true
    })

    const changeLimit = value => {
      if (isUpdated) {
        const limit = value !== pages.value.limitList[0] ? value : undefined
        router.push({ query: { ...route.query, limit: limit, page: undefined } })
      }
    }

    const changePage = value => {
      if (isUpdated) {
        const page = value !== 1 ? value : undefined
        router.push({ query: { ...route.query, page: page } })
      }
    }

    watch(
      () => route.query,
      () => {
        if (route.name === 'clients-list') {
          const params = {
            page: route.query.page || 1, limit: route.query.limit || 10
          }
          store.dispatch('clients/loadClients', params)
        }
      },
      { deep: true, immediate: true }
    )
    return {
      clients, pages, isLoading, changeLimit, changePage
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";
.clients-panel {
  min-width: 900px;
  max-width: 1180px;
  padding: 36px 0 81px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  &__table {
   margin-bottom: 48px;
  }
}
</style>
