<template>
  <div class="about">
    <div class="about__left">
      <div class="about__logo line">
        <p class="about__logo-key about__left-key">Логотип</p>
        <img class="about__value about__logo-value" :src="client.logo?.url" alt="">
      </div>
      <div class="about__domain line">
        <p class="about__domain-key about__left-key">Домен</p>
        <p class="about__value about__domain-value">{{ client.domain }}.softline.com</p>
      </div>
      <div class="about__inn line">
        <p class="about__inn-key about__left-key">ИНН</p>
        <p class="about__value about__inn-value">{{ client.inn }}</p>
      </div>
      <div class="about__kpp line">
        <p class="about__kpp-key about__left-key">КПП</p>
        <p class="about__value about__kpp-value">{{ client.kpp }}</p>
      </div>
      <div class="about__idUrPerson line">
        <p class="about__idUrPerson-key about__left-key">ID проекта в MCF</p>
        <p class="about__value about__idUrPerson-value">{{ client.idUrPerson }}</p>
      </div>
      <div class="about__address line">
        <p class="about__address-key about__left-key">Юридический адрес</p>
        <p class="about__value about__address-value">{{ client.address }}</p>
      </div>
      <div class="about__phone line">
        <p class="about__phone-key about__left-key">Номер телефона</p>
        <p class="about__value about__phone-value">{{ client.phone }}</p>
      </div>
    </div>
    <div class="about__right">
      <div class="about__contract line">
        <p class="about__contract-key about__right-key">Номер договора</p>
        <a :href="client.contractFileId?.url" target="_blank" class="about__value about__contract-value" :class="{'about__contract-value_disabled': !client.contractFileId?.url}">{{ client.contract }}</a>
      </div>
      <div class="about__contractTime line">
        <p class="about__contractTime-key about__right-key">Действие договора</p>
        <p class="about__value about__contractTime-value">{{ client.startContract }}-{{ client.endContract }}</p>
      </div>
      <div class="about__paymentType line">
        <p class="about__paymentType-key about__right-key">Тип оплаты</p>
        <p class="about__value about__paymentType-value">{{ PAYMENT_TYPE[client.paymentType] }}</p>
      </div>
      <div class="about__limitPostpay line">
        <p class="about__limitPostpay-key about__right-key">Лимит</p>
        <p class="about__value about__limitPostpay-value">{{ client.limitPostpay }}</p>
      </div>
      <div class="about__limitDays line">
        <p class="about__limitDays-key about__right-key">Постоплата: количество дней</p>
        <p class="about__value about__limitDays-value">{{ declOfNum(client.limitDays ? client.limitDays : 0, ['день', 'дня', 'дней']) }}</p>
      </div>
      <div class="about__mainResponsibles line">
        <p class="about__mainResponsibles-key about__right-key">Ответственный со стороны клиента</p>
        <div class="about__value about__mainResponsibles-value">
          <p class="about__mainResponsibles-item" v-for="item in client.mainResponsibles" :key="item.id">{{ item.name }}</p>
        </div>
      </div>
      <div class="about__mainAdministrator line">
        <p class="about__mainAdministrator-key about__right-key">Ответственный со стороны Softline</p>
        <p class="about__value about__mainAdministrator-value">{{ client.mainAdministrator?.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const PAYMENT_TYPE = {
  prepayment: 'Предоплата',
  postpayment: 'Постоплата'
}

const declOfNum = (n, titles) => {
  return n + ' ' + titles[n % 10 === 1 && n % 100 !== 11
    ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2]
}

export default {
  name: 'AboutClients',
  setup() {
    const store = useStore()
    const route = useRoute()

    const client = computed(() => store.getters['clients/getClient'])

    onMounted(() => store.dispatch('clients/loadClientById', route.params.clientId))

    return { client, PAYMENT_TYPE, declOfNum }
  }
}
</script>

<style lang="scss" scoped>
.about {
  display: flex;
  background: $color-white;
  border: 1px solid $color-border-grey;
  border-radius: 8px;
  padding: 32px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 19px;

  .line {
    display: flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__left,
  &__right {
    min-width: 50%;
    max-width: 50%;
  }

  &__left {
    &-key {
      color: rgba($color: $color-black, $alpha: 0.4);
      margin-right: 16px;
      flex-basis: 18.5%;
    }
  }

  &__right {
    &-key {
      color: rgba($color: $color-black, $alpha: 0.4);
      margin-right: 16px;
      flex-basis: 37%;
    }
  }

  &__value {
    max-width: 74%;
  }

  &__domain {
    color: $color-red;
  }

  &__logo-value {
    height: 30px;
  }

  &__contract-value {
    text-decoration: none;
    color: $color-red;

    &_disabled {
      pointer-events: none;
      color: $color-black;
    }
  }

  &__mainResponsibles-item {
    margin-bottom: 4px;
  }
}
</style>
