<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <modal-create-request v-if="showModal" @closeModal="showModal = false" :productName="data.name" />
  <div class="product">
    <div class="product__container">
      <v-title v-html="data.name"></v-title>
      <div class="product__info">
        <div class="product__images">
          <img v-if="data.images" class="product__images-main" :src="data.images[activeImage] || emptyImage" alt="">
          <div class="product__images-secondary">
            <div class="product__images-small" v-for="(image, index) in data.images" :key="image" @click="changeImage(index)">
              <img :src="image" alt="">
            </div>
          </div>
        </div>
        <div class="product__parameters">
          <div class="product__specification">
            <ul class="product__specification-list">
              <li class="product__specification-item">
                <p class="product__specification-subtitle">Артикул:</p>
                <p v-html="data.sku"></p>
              </li>
              <li class="product__specification-item">
                <p class="product__specification-subtitle">Производитель:</p>
                <p v-html="data.vendor"></p>
              </li>
              <li v-if="data.needDelivery" class="product__specification-item">
                <p class="product__specification-subtitle">Размеры и масса:</p>
                <p v-if="data.width && data.height && data.depth && data.weight">{{ data.width }} мм x {{ data.height }} мм x {{ data.depth }}, {{ data.weight }} г</p>
              </li>
            </ul>
            <p class="product__specification-common" v-html="data.shortDescription"></p>
          </div>
          <div v-if="!isLoading">
            <div v-if="sum" class="product__order">
              <p class="product__sum">{{ currency(sum) }} ₽</p>
              <v-counter-input
                class="product__counter"
                v-model:counter.number="counter"
                :minQuantity="data.minQuantity"
                :maxQuantity="data.maxQuantity"
              />
              <v-button v-if="!isAddedToCart" type="button" :size="'l'" text="В корзину" :disabled="(counter < (data.minQuantity || 1)) || (counter > (data.maxQuantity || 999999)) || disabled" @click="addProductToCart"></v-button>
              <v-green-button v-else type="button" size="l" text="В корзине" :isIcon="true" @click="$router.push({ name: 'cart' })"></v-green-button>
            </div>
            <v-button v-else type="button" :size="'l'" text="Запросить цену" @click.stop="showModal = true"></v-button>
          </div>
        </div>
      </div>
      <v-tabs class="product__tabs" :list="tabs" :active-index="activeTab" @change="changeActiveTab"></v-tabs>
      <div v-if="activeTab === 0" class="product__description" v-html="data.fullDescription"></div>
      <div v-else class="product__description">
        <product-table v-for="table in data.properties" :key="table.id" :table="table"></product-table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import emptyImage from '@/assets/images/empty.jpg'
import ProductTable from '@/components/ProductTable.vue'
import ModalCreateRequest from '@/components/ModalCreateRequest.vue'
import currency from '@/utils/currency-formatter.js'

export default {
  name: 'Product',
  components: { ProductTable, ModalCreateRequest },
  setup() {
    const store = useStore()
    const route = useRoute()

    const showModal = ref(false)
    const counter = ref()
    const activeTab = ref(0)
    const activeImage = ref(0)
    const isAddedToCart = ref(false)
    const disabled = ref(false)

    const data = computed(() => store.getters['products/getData'])
    const language = computed(() => store.getters['products/getLanguage'])
    const isLoading = computed(() => store.getters['products/isLoading'])
    const sum = computed(() => data.value.price ? data.value.price * +counter.value : 0)

    watch(data, () => {
      if (data.value.quantity) {
        counter.value = data.value.quantity
        isAddedToCart.value = true
      } else if (data.value.minQuantity) {
        counter.value = data.value.minQuantity
      } else {
        counter.value = 1
      }
    })

    const changeActiveTab = index => {
      activeTab.value = index
    }

    const changeImage = index => {
      activeImage.value = index
    }

    onMounted(() => { store.dispatch('products/loadProduct', { id: route.params.productId, language: language.value }) })

    const addProductToCart = async () => {
      disabled.value = true
      const response = await store.dispatch('cart/addProduct', { productId: data.value.id, quantity: counter.value })
      if (response.status === 204) {
        isAddedToCart.value = true
        disabled.value = false
      }
    }

    watch(counter, async () => {
      if (counter.value < (data.value.minQuantity || 1) || counter.value > (data.value.maxQuantity || 999999)) {
        return
      }
      if (isAddedToCart.value) {
        await store.dispatch('cart/changeQuantity', { productId: data.value.id, quantity: counter.value })
      }
    })

    return {
      data, isLoading, sum, activeImage, activeTab, currency, showModal, counter, isAddedToCart, emptyImage, disabled,
      addProductToCart, changeActiveTab, changeImage,
      tabs: [
        { id: 1, title: 'Описание' },
        { id: 2, title: 'Характеристики' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.product{
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 272px);
    margin: auto;
    padding: 24px 100px 50px;
    background-color: $color-light-white;
  }

  &__info {
    display: flex;
    margin-bottom: 57px;
  }

  &__images {
    min-width: 308px;
    max-width: 308px;
    margin-right: 48px;

    &-main {
      width: 100%;
      height: 308px;
      margin-bottom: 16px;
      object-fit: contain;
    }

    &-secondary {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 4px;
    }

    &-small {
      position: relative;
      padding-bottom: 100%;
      grid-column: span 1;
      cursor: pointer;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__parameters {
    width: 100%;
    margin-right: 70px;
  }

  &__specification {
    min-height: 308px;
    margin-bottom: 16px;
    padding-top: 8px;

    &-list {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 24px;
    }

    &-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &-subtitle {
      flex-basis: 27%;
      color: $color-grey;
    }

    &-common {
      font-size: 22px;
      line-height: 29px;
    }
  }

  &__order {
    display: flex;
    align-items: center;
  }

  &__sum {
    min-width: 170px;
    font-size: 30px;
    line-height: 36px;
    white-space: nowrap;
  }

  &__counter {
    margin: 0 32px;
  }

  &__tabs {
    margin: 0 auto 24px;
  }

  &__description {
    width: 67%;
    margin: auto;
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
