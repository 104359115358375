<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 11.5017V14.0017H4.5L11.8733 6.62833L9.37333 4.12833L2 11.5017ZM13.8067 4.695C14.0667 4.435 14.0667 4.015 13.8067 3.755L12.2467 2.195C11.9867 1.935 11.5667 1.935 11.3067 2.195L10.0867 3.415L12.5867 5.915L13.8067 4.695Z" fill="#C90D46"/>
  </svg>
</template>
<script>
export default {
  name: 'editIcon'
}
</script>
