<template>
  <div>
    <teleport to="body" v-if="isLoading">
      <v-spinner />
    </teleport>
    <v-modal v-if="idToDelete" @closeModal="idToDelete = null" @submitModal="deleteItem(idToDelete)">
      <h3 class="modal__title">Вы действительно хотите <br /> удалить товар из корзины?</h3>
      <v-border-button type="button" :size="'m'" text="Да" class="modal__agree" @click="deleteItem(idToDelete)"></v-border-button>
      <v-button type="button" :size="'m'" text="Нет" class="modal__cancel" @click="idToDelete = null"></v-button>
    </v-modal>
    <v-modal v-if="isShowClearModal" @closeModal="isShowClearModal = false" @submitModal="clearCart">
      <h3 class="modal__title">Вы действительно хотите <br /> удалить все товары из корзины?</h3>
      <v-border-button type="button" :size="'m'" text="Да" class="modal__agree" @click="clearCart"></v-border-button>
      <v-button type="button" :size="'m'" text="Нет" class="modal__cancel" @click="isShowClearModal = false"></v-button>
    </v-modal>
    <div class="cart">
      <v-title class="cart__title">Корзина</v-title>
      <div v-if="items.length">
        <ul>
          <v-cart-item
            class="cart__item"
            v-for="item in items"
            :key="item.productId"
            :card="item"
            @openModal="id => idToDelete = id"
            @addError="addError(item.productId)"
            @deleteError="deleteError(item.productId)"
          ></v-cart-item>
        </ul>
        <v-pagination
          class="cart__pagination"
          :total="pages.total"
          :page="pages.page"
          :limitList="pages.limitList"
          :limit="+$route.query.limit || pages.limit"
          @changeLimit="changeLimit"
          @changePage="changePage"
        ></v-pagination>
        <div class="cart__divider"></div>
        <div class="cart__total">
          <p class="cart__sum">{{ currency(totalCost) }} ₽</p>
          <p class="cart__vat">{{ totalNds ? `включая НДС ${currency(totalNds)} ₽` : 'Без НДС' }}</p>
        </div>
        <div class="cart__buttons">
          <v-button type="text" :size="'l'" text="Оформить заказ" @click="createOrder" :disabled="orderBlocked || errors.length"></v-button>
          <v-border-button class="cart__clear-button" type="text" size="m" text="Очистить корзину" @click="isShowClearModal = true"></v-border-button>
        </div>
      </div>
      <div v-else class="cart-empty">
        <cart-icon class="cart-empty__icon"></cart-icon>
        <p class="cart-empty__text">Корзина пуста.</p>
        <p class="cart-empty__text">Вы можете добавить товары из каталога или воспользоваться поиском.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import CartIcon from '@/components/icons/cart-icon.vue'
import currency from '@/utils/currency-formatter.js'

export default {
  name: 'Cart',
  components: { CartIcon },
  emits: ['setForm'],
  setup(_, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const idToDelete = ref(null)
    const isShowClearModal = ref(false)
    const errors = ref([])

    const items = computed(() => store.getters['cart/getItems'])
    const allDeliveryTypes = computed(() => store.getters['cart/getAllDeliveryTypes'])
    const isLoading = computed(() => store.getters['cart/getIsLoading'])
    const language = computed(() => store.getters['cart/getLanguage'])
    const totalCost = computed(() => store.getters['cart/getTotalCost'])
    const totalNds = computed(() => store.getters['cart/getTotalNds'])
    const pages = computed(() => store.getters['cart/getPages'])

    const orderBlocked = computed(() => items.value.find(item => item.blocked))

    const changeLimit = limit => {
      router.push({
        query: {
          ...route.query,
          limit: limit === 10 ? undefined : limit
        }
      })
    }

    const changePage = page => {
      router.push({
        query: {
          ...route.query,
          page: page === 1 ? undefined : page
        }
      })
    }

    const deleteItem = id => {
      store.dispatch('cart/deleteProduct', id)
      idToDelete.value = null
    }

    const clearCart = () => {
      store.dispatch('cart/clearCart')
      isShowClearModal.value = false
    }

    const createOrder = () => {
      emit('setForm', getForm())
    }

    const getForm = () => {
      let form = ''
      const product = allDeliveryTypes.value.find(type => type === 'physical')
      const family = allDeliveryTypes.value.find(type => type === 'email')
      if (product && family) {
        form = 'SoftwareHardwareForm'
      } else if (product) {
        form = 'HardwareForm'
      } else {
        form = 'SoftwareForm'
      }
      return form
    }

    const addError = id => {
      errors.value = [...errors.value, id]
    }

    const deleteError = id => {
      errors.value = errors.value.filter(el => el !== id)
    }

    onMounted(() => store.dispatch('cart/loadAllItems'))

    watch(
      () => route.query,
      () => {
        const params = {
          page: route.query.page || 1,
          limit: route.query.limit || 10,
          language: language.value
        }
        store.dispatch('cart/loadItems', params)
      },
      { deep: true, immediate: true }
    )

    return {
      currency, idToDelete, isShowClearModal, isLoading, items, pages, totalCost, totalNds, orderBlocked,
      changeLimit, changePage, deleteItem, clearCart, createOrder, errors, addError, deleteError
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__title {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    color: $color-black;
    margin-bottom: 16px;
  }

  &__agree {
    margin-right: 8px;
  }
}

.cart {
  &__title {
    margin: 24px 0;
  }

  &__item {
    margin-bottom: 17px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__pagination {
    margin-top: 32px;
  }

  &__divider {
    margin-top: 25px;
    border-bottom: 1px solid $color-border-grey;
  }

  &__total {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 16px 0 36px;
  }

  &__sum {
    font-size: 30px;
    line-height: 36px;
  }

  &__vat {
    font-size: 14px;
    line-height: 17px;
  }

  &__buttons {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__clear-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &-empty {
    display: flex;
    height: calc(100vh - 490px);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
      width: 53px;
      height: 53px;
      fill: $color-border-grey;
      margin-bottom: 13px;
    }

    &__text {
      font-size: 22px;
      line-height: 29px;
      color: $color-grey;
    }
  }
}
</style>
