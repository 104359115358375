<template>
  <v-modal @closeModal="$emit('passDecision', null)" @submitModal="clickAgreeButton">
    <div class="modal__container">
      <div class="modal__header">
        <arrow-back-icon class="modal__header-icon" @click="$emit('passDecision', null)" />
        <v-title>Запрос №{{ request.number }}</v-title>
      </div>
      <div class="modal__main">
        <div class="modal__block modal__line">
          <div class="modal__createdDate">
            <p class="modal__title">Дата создания запроса</p>
            <p class="modal__value">{{ request.createdDate }}</p>
          </div>
          <div v-if="request.approvalDate" class="modal__approvalDate">
            <p class="modal__title">Дата согласования (отклонения) запроса</p>
            <p class="modal__value">{{ request.approvalDate }}</p>
          </div>
          <div v-if="request.approvalAuthor" class="modal__approvalAuthor">
            <p class="modal__title">Автор согласования (отклонения) запроса</p>
            <p class="modal__value">{{ request.approvalAuthor }}</p>
          </div>
        </div>
        <div v-if="request.name" class="modal__block">
          <p class="modal__title">Наименование товара</p>
          <p class="modal__value">{{ request.name }}</p>
        </div>
        <div v-if="request.comment" class="modal__block">
          <p class="modal__title">Комментарий к запросу</p>
          <p class="modal__value">{{ request.comment }}</p>
        </div>
        <div class="modal__block">
          <p class="modal__title">Пользователь</p>
          <p class="modal__value">{{ request.userName }}</p>
        </div>
        <div v-if="request.file || request.commercialOffer" class="modal__block modal__line">
          <div v-if="request.file" class="modal__file">
            <p class="modal__title">Файл, содержащий перечень продуктов</p>
            <a :href="request.file" target="_blank" :download="'asadf.docx'" @click.stop>
              <attach-icon class="modal__file-icon" />
              <span class="modal__value modal__file-name">{{ request.filename }}</span>
            </a>
          </div>
          <div v-if="request.commercialOffer" class="modal__file">
            <p class="modal__title">КП</p>
            <a href="#" target="_blank" @click.stop="downloadFile(request.commercialOffer, request.commercialOfferFilename)">
              <attach-icon class="modal__file-icon" />
              <span class="modal__value modal__file-name">{{ request.commercialOfferFilename }}</span>
            </a>
          </div>
        </div>
        <div v-if="request.commentAdmin" class="modal__block">
          <p class="modal__title">Комментарий от администратора</p>
          <p class="modal__value">{{ request.commentAdmin }}</p>
        </div>
      </div>
      <div v-if="status === 'await_approval' && request.isApproval" class="modal__buttons">
        <v-green-button  class="modal__buttons-agree" type="button" size="l" text="Согласовать" @click="clickAgreeButton" />
        <v-border-button type="button" size="l" text="Отклонить" @click="$emit('passDecision', 'reject')" />
      </div>
    </div>
  </v-modal>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import ArrowBackIcon from '@/components/icons/arrow-back-icon'
import AttachIcon from '@/components/icons/attach-icon'

export default {
  name: 'UserDetailModal',
  components: { ArrowBackIcon, AttachIcon },
  props: ['request', 'status', 'choosenId'],
  emits: ['passDecision', 'showNoticeModal'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()

    const clickAgreeButton = async () => {
      try {
        await store.dispatch('requests/agreement', {
          id: props.choosenId,
          decision: 'agree',
          limit: route.query.limit || 10
        })
        emit('passDecision', 'agree')
        emit('showNoticeModal')
      } catch (error) {
        console.log(error)
      }
    }

    const downloadFile = (url, name) => {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.download = name
      console.log(link)
      link.dispatchEvent(new MouseEvent('click'))
    }

    return { clickAgreeButton, downloadFile }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__container {
    padding: 24px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-icon {
      margin-right: 12px;
      cursor: pointer;
    }
  }

  &__main {
    min-width: 600px;
    max-width: 912px;
    padding-left: 20px;
  }

  &__block {
    margin-bottom: 16px;
  }

  &__createdDate {
    width: 240px;
  }

  &__approvalDate {
    width: 270px;
  }

  &__approvalAuthor {
    max-width: 330px;
  }

  &__line {
    display: flex;
  }

  &__file {
    width: 50%;

    & > a {
      width: fit-content;
      display: flex;
      align-items: flex-start;
      text-decoration: none;
      padding-right: 20px;
      cursor: pointer;
    }

    &-icon {
      min-width: 8px;
      fill: $color-red;
      margin-right: 8px;
    }

    &-name {
      word-break: break-all;
      color: $color-black;
    }
  }

  &__title {
    font-size: 12px;
    line-height: 14px;
    color: $color-grey;
  }

  &__value {
    font-size: 16px;
    line-height: 21px;
  }

  &__buttons {
    display: flex;
    margin-top: 26px;

    &-agree {
      margin-right: 16px;
    }
  }
}
</style>
