<template>
  <form class="client-create-form" @submit="onSubmit">
    <div class="client-create-form__all-fields">
      <div class="client-create-form__left">
        <v-input
          class="client-create-form__field"
          placeholder="Название клиента"
          v-model="name"
          :error="nError"
          :handleChange="nChange"
          :mask="allSymbolsMask"
          :class-name="'name'"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <v-input
          class="client-create-form__field"
          placeholder="Домен"
          v-model="domain"
          :error="dError"
          :handleChange="dChange"
          :mask="domainMask"
          :class-name="'domain'"
          :maxlength="250"
          :disabled="isSubmitting"/>
        <div class="client-create-form__field client-create-form__loading client-create-form__loading--logo">
          <v-file-picker
            v-model="logoFile"
            name="logo"
            label="Логотип"
            :error="lfError"
            :handleChange="lfChange"
            :disabled="isSubmitting"
            accept=".jpg, .jpeg, .png"
            description="Горизонтальная версия логотипа для белого фона, jpg или png, будет в шапке сайта, высота — 30 px"
            ref="logoFileRef" />
          <v-button v-if="!logoFile" type="button" :size="'m'" text="Выбрать файл" class="client-create-form__loading--button" @click="$refs.logoFileRef.pickerClickHandler()"></v-button>
          <v-border-button v-else type="button" :size="'m'" text="Удалить файл" class="client-create-form__loading--button" @click="$refs.logoFileRef.deleteFile()"></v-border-button>
        </div>
        <v-input
          class="client-create-form__field"
          placeholder="ИНН"
          v-model="inn"
          :error="innError"
          :handleChange="innChange"
          :mask="numberMask"
          :class-name="'inn'"
          :maxlength="12"
          :disabled="isSubmitting"/>
        <v-input
          class="client-create-form__field"
          placeholder="КПП (не обязательно)"
          v-model="kpp"
          :error="kppError"
          :handleChange="kppChange"
          :mask="numberMask"
          :class-name="'kpp'"
          :maxlength="12"
          :disabled="isSubmitting"/>
        <v-input
          class="client-create-form__field"
          placeholder="ID проекта в MCF"
          v-model="idUrPerson"
          :error="idUrError"
          :handleChange="idUrChange"
          :mask="numberMask"
          :class-name="'idUrPerson'"
          :maxlength="50"
          :disabled="isSubmitting"/>
        <v-input
          class="client-create-form__field"
          placeholder="ID витрины"
          v-model="showcaseId"
          :error="idScError"
          :handleChange="idScChange"
          :mask="allSymbolsMask"
          :class-name="'idUrPerson'"
          :maxlength="50"
          :disabled="isSubmitting"/>
        <v-textarea
          class="client-create-form__field"
          label="Юридический адрес"
          v-model="address"
          :error="aError"
          :handleChange="aChange"
          :mask="allSymbolsMask"
          :maxlength="250"
          :disabled="isSubmitting"/>
      </div>
      <div class="client-create-form__divider"></div>
      <div class="client-create-form__right">
        <v-input
          class="client-create-form__field"
          placeholder="Номер телефона"
          :maxlenght="250"
          v-model="phone"
          :error="pError"
          :handleChange="pChange"
          :mask="allSymbolsMask"
          :class-name="'phone'"
          :disabled="isSubmitting" />
        <v-input
          class="client-create-form__field"
          placeholder="Номер договора"
          v-model="contract"
          :error="cnError"
          :handleChange="cnChange"
          :mask="allSymbolsMask"
          :class-name="'contract'"
          :maxlength="50"
          :disabled="isSubmitting" />
        <div class="client-create-form__field client-create-form__loading client-create-form__loading--file">
          <v-file-picker
            v-model="contractFileId"
            name="file"
            label="Файл договора (не обязательно)"
            :error="cfError"
            :handleChange="cfChange"
            :disabled="isSubmitting"
            accept=".doc, .docx, .pdf"
            description="Формат: doc, pdf. Размер файла: не более 10 мб. "
            ref="contractFileRef"/>
          <v-button v-if="!contractFileId" type="button" :size="'m'" text="Выбрать файл" class="client-create-form__loading--button" @click="$refs.contractFileRef.pickerClickHandler()"></v-button>
          <v-border-button v-else type="button" :size="'m'" text="Удалить файл" class="client-create-form__loading--button" @click="$refs.contractFileRef.deleteFile()"></v-border-button>
        </div>
        <div class="client-create-form__field client-create-form__contract">
          <p class="client-create-form__contract--title">Действие договора:</p>
          <div class="client-create-form__contract--dataPickers">
            <v-calendar
              class="client-create-form__contract--start"
              v-model:date="startContract"
              placeholder="Дата начала"
              className="start"
              :error="scError"
              :handleChange="scChange"
              :disabled="isSubmitting" />
            <span class="client-create-form__contract--divider">—</span>
            <v-calendar
              class="client-create-form__contract--end"
              v-model:date="endContract"
              placeholder="Дата окончания"
              className="end"
              :error="ecError"
              :handleChange="ecChange"
              :disabled="isSubmitting" />
          </div>
        </div>
        <v-radio-group
          class="client-create-form__field client-create-form__radio"
          v-model="paymentType"
          :error="ptError"
          :handleChange="ptChange"
          title="Тип оплаты:"
          :list="paymentList" />
        <v-input
          v-if="paymentType === 'postpayment'"
          class="client-create-form__field"
          placeholder="Лимит"
          v-model.number="limitPostpay"
          :error="lError"
          :handleChange="lChange"
          :mask="notZeroStart"
          :class-name="'limitPostpay'"
          :disabled="isSubmitting" />
        <v-input
          v-if="paymentType === 'postpayment'"
          class="client-create-form__field"
          placeholder="Постоплата: количество дней"
          v-model.number="limitDays"
          :error="ldError"
          :handleChange="ldChange"
          :mask="notZeroStart"
          :class-name="'limitDays'"
          :maxlength="50"
          :disabled="isSubmitting" />
        <v-select
          class="client-create-form__field"
          label="Ответственный со стороны Softline"
          v-model="mainAdministrator"
          :error="maError"
          :handleChange="maChange"
          :options="adminsList"
          :disabled="isSubmitting" />
      </div>
    </div>
    <div class="client-create-form__errors" v-if="allErrors[0]">
      <v-error v-for="(error, index) in allErrors" :key="index" class="client-create-form__error" :text="textError(error)"/>
    </div>
    <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Создать" class="client-create-form__submit" />
  </form>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { numberMask, domainMask, notZeroStart, allSymbolsMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'
import { checkFormats, maxSizeMb } from '@/utils/yup-helpers'

const serverErrorText = 'Ошибка сервера'

const PAYMENT_LIST = [
  { id: 1, name: 'prepayment', value: 'prepayment', text: 'Предоплата' },
  { id: 2, name: 'postpayment', value: 'postpayment', text: 'Постоплата' }
]

export default {
  name: 'ClientCreateForm',
  setup() {
    const store = useStore()
    const router = useRouter()

    const logoFileRef = ref(null)
    const contractFileRef = ref(null)
    const serverError = ref(null)

    const schema = yup.object({
      name: yup.string()
        .trim()
        .min(2, 'Название клиента не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      domain: yup.string()
        .trim()
        .min(2, 'Название домена не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      logoFile: yup.mixed()
        .test('size', 'Максимальный размер не более 5Мб', maxSizeMb(5))
        .test('format', 'Неверный формат файла', checkFormats('.jpg, .png, .jpeg'))
        .required('Заполнены не все обязательные поля'),
      inn: yup.string()
        .trim()
        .required('Заполнены не все обязательные поля'),
      kpp: yup.string()
        .trim(),
      idUrPerson: yup.string()
        .required('Заполнены не все обязательные поля'),
      showcaseId: yup.string()
        .required('Заполнены не все обязательные поля'),
      address: yup.string()
        .trim()
        .min(2, 'Юридический адрес не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      phone: yup.string()
        .min(2, 'Номер телефона не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      contract: yup.string()
        .trim()
        .required('Заполнены не все обязательные поля'),
      contractFileId: yup.mixed()
        .notRequired()
        .test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10))
        .test('format', 'Неверный формат файла', checkFormats('.doc, .docx, .pdf')),
      startContract: yup.number()
        .nullable()
        .required('Заполнены не все обязательные поля')
        .positive('Пожалуйста, введите дату начала договора'),
      endContract: yup.number()
        .nullable()
        .required('Заполнены не все обязательные поля')
        .positive('Пожалуйста, введите дату окончания договора')
        .moreThan(yup.ref('startContract'), 'Дата окончания не может быть меньше начальной'),
      paymentType: yup.string()
        .required('Заполнены не все обязательные поля'),
      limitPostpay: yup.number()
        .when('paymentType',
          {
            is: 'postpayment',
            then: yup.number()
              .nullable()
              .typeError('Заполнены не все обязательные поля')
              .required('Заполнены не все обязательные поля')
              .min(100, 'Лимит не может быть меньше 100')
              .max(100000, 'Лимит не может быть больше 100000')
          }
        ),
      limitDays: yup.number()
        .when('paymentType',
          {
            is: 'postpayment',
            then: yup.number()
              .nullable()
              .typeError('Заполнены не все обязательные поля')
              .required('Заполнены не все обязательные поля')
              .min(1, 'Лимит не может быть меньше 1')
              .max(100000, 'Лимит не может быть больше 100000')
          }
        ),
      mainAdministrator: yup.string()
        .required('Заполнены не все обязательные поля')
    })

    const { handleSubmit, isSubmitting, errors } = useForm({ validationSchema: schema })

    const { value: name, errorMessage: nError, handleChange: nChange } = useField('name', undefined, { validateOnValueUpdate: false })
    const { value: domain, errorMessage: dError, handleChange: dChange } = useField('domain', undefined, { validateOnValueUpdate: false })
    const { value: logoFile, errorMessage: lfError, handleChange: lfChange } = useField('logoFile')
    const { value: inn, errorMessage: innError, handleChange: innChange } = useField('inn', undefined, { validateOnValueUpdate: false })
    const { value: kpp, errorMessage: kppError, handleChange: kppChange } = useField('kpp', undefined, { validateOnValueUpdate: false })
    const { value: idUrPerson, errorMessage: idUrError, handleChange: idUrChange } = useField('idUrPerson', undefined, { validateOnValueUpdate: false })
    const { value: showcaseId, errorMessage: idScError, handleChange: idScChange } = useField('showcaseId', undefined, { validateOnValueUpdate: false })
    const { value: address, errorMessage: aError, handleChange: aChange } = useField('address', undefined, { validateOnValueUpdate: false })
    const { value: phone, errorMessage: pError, handleChange: pChange } = useField('phone', undefined, { validateOnValueUpdate: false })
    const { value: contract, errorMessage: cnError, handleChange: cnChange } = useField('contract', undefined, { validateOnValueUpdate: false })
    const { value: contractFileId, errorMessage: cfError, handleChange: cfChange } = useField('contractFileId', undefined, { validateOnValueUpdate: false })
    const { value: startContract, errorMessage: scError, handleChange: scChange } = useField('startContract', undefined, { validateOnValueUpdate: false })
    const { value: endContract, errorMessage: ecError, handleChange: ecChange } = useField('endContract', undefined, { validateOnValueUpdate: false })
    const { value: paymentType, errorMessage: ptError, handleChange: ptChange } = useField('paymentType', undefined, { validateOnValueUpdate: false })
    const { value: limitPostpay, errorMessage: lError, handleChange: lChange } = useField('limitPostpay', undefined, { validateOnValueUpdate: false })
    const { value: limitDays, errorMessage: ldError, handleChange: ldChange } = useField('limitDays', undefined, { validateOnValueUpdate: false })
    const { value: mainAdministrator, errorMessage: maError, handleChange: maChange } = useField('mainAdministrator', undefined, { validateOnValueUpdate: false })

    const paymentList = ref(PAYMENT_LIST)

    const allErrors = computed(() => [...new Set(Object.values(errors.value)), serverError.value].filter(Boolean))

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }

    const closePageOrShowError = response => {
      const noError = serverErrorHandler(response)
      noError && router.push({ name: 'client', params: { clientId: response.data.id } })
    }

    const onSubmit = handleSubmit(async values => {
      try {
        const response = await store.dispatch('clients/createClient', values)
        closePageOrShowError(response)
      } catch (error) {
        console.log(error)
      }
    })

    onMounted(() => store.dispatch('admins/loadResponsibles'))

    const admins = computed(() => store.getters['admins/getAdmins'])

    const adminsList = computed(() => {
      const list = [{ id: -1, value: 'reset', text: 'Не выбрано' }]
      admins.value.forEach(admin => {
        list.push({ id: admin.id, value: admin.id, text: admin.name })
      })
      return list
    })

    return {
      numberMask, domainMask, notZeroStart, allSymbolsMask, textError,
      isSubmitting, onSubmit, allErrors,
      paymentList, adminsList,
      logoFileRef, contractFileRef,
      name, nError, nChange,
      domain, dError, dChange,
      logoFile, lfError, lfChange,
      inn, innError, innChange,
      kpp, kppError, kppChange,
      idUrPerson, idUrError, idUrChange,
      showcaseId, idScError, idScChange,
      address, aError, aChange,
      phone, pError, pChange,
      contract, cnError, cnChange,
      contractFileId, cfError, cfChange,
      startContract, scError, scChange,
      endContract, ecError, ecChange,
      paymentType, ptError, ptChange,
      limitPostpay, lError, lChange,
      limitDays, ldError, ldChange,
      mainAdministrator, maError, maChange
    }
  }
}
</script>

<style lang="scss" scoped>
.client-create-form {
  &__all-fields {
    display: flex;
    justify-content: space-between;
  }

  &__field {
    width: 550px;
  }

  &__loading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;

    &--button {
      align-self: center;
      margin-left: 8px;
    }
  }

  &__contract,
  &__select,
  &__radio {
    margin-bottom: 16px;
  }

  &__divider {
    width: 1px;
    border: 1px solid $color-border-grey;
  }

  &__contract {
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 21px;
    color: $color-black;

    &--title {
      white-space: nowrap;
    }

    &--dataPickers {
      display: flex;
      align-items: center;
    }

    &--start {
      min-width: 155px;
      max-width: 155px;
    }

    &--end {
      min-width: 182px;
      max-width: 182px;
    }

    &--divider {
      margin: 0 8px;
    }
  }

  &__add {
    width: fit-content;
    font-size: 12px;
    line-height: 14px;
    color: $color-red;
    padding: 4px 0;
    margin: -12px 0 12px;
    cursor: pointer;
  }

  &__errors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
    margin-bottom: -38px;
  }

  &__error {
    white-space: nowrap;
    margin-right: 4px;
  }

  &__submit {
    margin-top: 36px;
    align-self: flex-start;
  }
}
</style>
