<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM10 21V18.5L17.3733 11.1266L19.8733 13.6266L12.5 21H10ZM21.8067 10.7533C22.0667 11.0133 22.0667 11.4333 21.8067 11.6933L20.5867 12.9133L18.0867 10.4133L19.3067 9.19329C19.5667 8.93329 19.9867 8.93329 20.2467 9.19329L21.8067 10.7533Z" fill="#A4182C"/>
  </svg>
</template>

<script>
export default {
  name: 'editBigIcon'
}
</script>
