<template>
  <form class="admin-create-form" @submit="onSubmit">
    <v-input
      placeholder="Фамилия"
      v-model="secondName"
      :error="sError"
      :handleChange="sChange"
      :mask="nameMask"
      :class-name="'secondName'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="Имя"
      v-model="firstName"
      :error="fError"
      :handleChange="fChange"
      :mask="nameMask"
      :class-name="'firstName'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="Отчество"
      v-model="patronymic"
      :error="lError"
      :handleChange="lChange"
      :mask="nameMask"
      :class-name="'patronymic'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="E-mail"
      v-model="email"
      :error="eError"
      :handleChange="eChange"
      :mask="emailMask"
      :class-name="'email'"
      :maxlength="64"
      :disabled="isSubmitting"/>
    <v-input
      placeholder="Моб. тел."
      v-model="phone"
      :error="pError"
      :handleChange="pChange"
      :mask="allSymbolsMask"
      :class-name="'phone'"
      :maxlength="250"
      :disabled="isSubmitting"/>
    <v-error
      class="admin-create-form__error"
      v-if="sError || fError || lError || eError || pError"
      :text="textError(sError, fError, lError, eError, pError)"/>
    <v-button type="submit" :size="'l'" :disabled="isSubmitting" text="Добавить"
              class="admin-create-form__submit"></v-button>
  </form>
</template>
<script>
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { nameMask, allSymbolsMask, emailMask } from '@/utils/inputMasks'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { textError } from '@/utils/utils'

export default {
  name: 'AdminCreateForm',
  setup () {
    const store = useStore()
    const router = useRouter()
    const { handleSubmit, isSubmitting } = useForm()
    const { value: secondName, errorMessage: sError, handleChange: sChange } = useField('secondName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите фамилию')
      .min(2, 'Фамилия не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: firstName, errorMessage: fError, handleChange: fChange } = useField('firstName', yup
      .string()
      .trim()
      .required('Пожалуйста, введите имя')
      .min(2, 'Имя не может быть меньше 2 символов'), { validateOnValueUpdate: false })
    const { value: patronymic, errorMessage: lError, handleChange: lChange } = useField('patronymic', yup
      .string()
      .trim()
      .notRequired()
      .nullable()
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: 'Отчество не может быть меньше 2 символов'
      }), { validateOnValueUpdate: false })
    const { value: email, errorMessage: eError, setErrors: eSetErrors, handleChange: eChange } = useField('email', yup
      .string()
      .trim()
      .required('Пожалуйста, введите email')
      .matches(/^.+@.+\..+$/, 'Невалидный email')
      .min(6, 'Email не может быть меньше 6 символов'), { validateOnValueUpdate: false })
    const { value: phone, errorMessage: pError, handleChange: pChange } = useField('phone', yup
      .string()
      .trim()
      .required('Пожалуйста, введите номер телефона')
      .min(2, 'Номер телефона не может быть меньше 2 символов'), { validateOnValueUpdate: false })

    const onSubmit = handleSubmit(async values => {
      try {
        await store.dispatch('admins/createAdmin', values)
        await router.push({ name: 'admins-list' })
      } catch (error) {
        eSetErrors(error)
        console.log(error)
      }
    })

    return {
      firstName, fError, fChange,
      secondName, sError, sChange,
      patronymic, lError, lChange,
      email, eError, eChange,
      phone, pError, pChange,
      onSubmit, isSubmitting,
      nameMask, allSymbolsMask, emailMask,
      textError
    }
  }
}
</script>
<style scoped lang="scss">
.admin-create-form {
  width: 550px;
  display: flex;
  flex-direction: column;

  &__error{
    align-self: flex-start;
  }

  &__submit {
    margin-top: 20px;
    align-self: flex-start;
  }
}

.v-input.v-input__field {
  margin-bottom: 16px;

}
</style>
