<template>
  <div class="login">
    <img :src="require('../assets/images/logo.svg')" alt="logo" class="login__logo">
    <div class="login__modal">
      <v-spinner v-if="this.$store.state.auth.isLoading"></v-spinner>
      <component :is="this.$store.getters['auth/getForm']"></component>
    </div>
  </div>
</template>
<script>
import emailForm from '../components/EmailForm'
import codeForm from '../components/CodeForm'
export default {
  name: 'Login',
  components: { emailForm, codeForm }
  // setup() {
  //   // Запрещаем доступ к страницам логина из поддоменов
  //   // проверяем есть ли в url поддомен, то редиректим его на основной домен
  //   if (window.location.hostname.split('.').length > 2) {
  //     const baseURL = process.env.NODE_ENV === 'production' ? 'http://dev.e-supply.slweb.ru' : 'http://e-supply.ru:8080'
  //     window.location.replace(baseURL)
  //   }
  // }
}
</script>
<style scoped lang="scss">
@import "../assets/styles/variables";
  .login {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30vh;

    &__logo {
      margin-bottom: 24px;
    }

    &__modal {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      padding: 48px;
      width: 501px;
      min-height: 199px;
      background: $color-white;
      box-shadow: $color-box-shadow;
      border-radius: 16px;
    }
  }
</style>
