<template>
  <div class="table">
    <h3 class="table__title" v-html="table.groupName"></h3>
    <ul class="table__list">
      <li class="table__item" v-for="item in table.properties" :key="item.id">
        <p class="table__name" v-html="item.name"></p>
        <p class="table__value" v-html="bracketConvert(item.value)"></p>
      </li>
    </ul>
  </div>
</template>

<script>
import bracketConvert from '@/utils/bracketConvert'

export default {
  name: 'ProductTable',
  props: ['table'],
  setup() {
    return { bracketConvert }
  }
}
</script>

<style lang="scss" scoped>
.table {
  &__title {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    padding: 0 60px;
  }

  &__list {
    margin-bottom: 30px;
  }

  &__item {
    display: flex;
    font-size: 16px;
    line-height: 21px;
    padding: 8px 60px;
    border-radius: 8px;

    &:nth-child(2n-1) {
      background-color: $color-white;
    }
  }

  &__name {
    flex-basis: 50%;
  }

  &__value {
    flex-basis: 50%;
    line-height: 19px;
    font-weight: 700;
  }
}
</style>
