<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>

  <client-detail-modal v-if="typeof showModal === 'number' && showModal > -1" :request="request" :status="status" @passDecision="value => setDecision(value)" />
  <client-agree-modal v-if="showModal === 'agree'" :decision="decision" :choosenId="choosenId" :page="prevPage" :limit="prevLimit" @resetModal="setDecision(null)" @showDetailModal="showModal = 0" />
  <client-reject-modal v-if="showModal === 'reject'" @passValues="setValues" @resetModal="setDecision(null)" @showDetailModal="showModal = 0" />
  <client-confirm-modal v-if="showModal === 'confirm'" :decision="decision" :fields="fields" :choosenId="choosenId" :page="prevPage" :limit="prevLimit" @resetModal="setDecision(null)" @setChoosenId="openDetailModal" @setModal="modal => showModal = modal" />

  <div class="requests-client">
    <v-tabs class="requests-client__tabs" :list="statusesList" :active-index="activeIndex < 0 ? 0 : activeIndex" @change="changeStatus"></v-tabs>
    <requests-client-table
      :requests="requests"
      :status="status"
      @setChoosenId="openDetailModal"
      @setModal="modal => showModal = modal"
    />
    <v-pagination
      class="requests-client__pagination"
      @changePage="changePage"
      @changeLimit="changeLimit"
      :total="pages.total"
      :limitList="pages.limitList"
      :limit="$route.query.limit ? +$route.query.limit : pages.limit"
      :page="$route.query.page ? +$route.query.page : pages.page"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import RequestsClientTable from '@/components/RequestsClientTable.vue'

import ClientAgreeModal from '@/components/requestModals/ClientAgreeModal.vue'
import ClientRejectModal from '@/components/requestModals/ClientRejectModal.vue'
import ClientConfirmModal from '@/components/requestModals/ClientConfirmModal.vue'
import ClientDetailModal from '@/components/requestModals/ClientDetailModal.vue'

export default {
  name: 'RequestsClient',
  components: { RequestsClientTable, ClientAgreeModal, ClientRejectModal, ClientConfirmModal, ClientDetailModal },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const showModal = ref(null)
    const choosenId = ref(-1)
    const decision = ref(null)
    const fields = ref(null)
    const prevPage = ref(null)
    const prevLimit = ref(null)

    const pages = computed(() => store.getters['requests/getPages'])
    const isLoading = computed(() => store.getters['requests/isLoading'])
    const counters = computed(() => store.getters['requests/getCounters'])
    const requests = computed(() => store.getters['requests/getRequests'])
    const request = computed(() => store.getters['requests/getRequest'])
    const statusesList = computed(() => ([
      { id: 1, status: 'working', title: `В работе (${counters.value.working || 0})` },
      { id: 2, status: 'await_approval', title: `Ожидают согласования (${counters.value.await_approval || 0})` },
      { id: 3, status: 'agreed', title: `Согласованные (${counters.value.agreed || 0})` },
      { id: 4, status: 'rejected', title: `Отклонённые (${counters.value.rejected || 0})` }
    ]))

    const activeIndex = ref(statusesList.value.findIndex(el => el.status === route.query.status))
    const status = ref(statusesList.value[activeIndex.value < 0 ? 0 : activeIndex.value].status)

    const openDetailModal = async id => {
      await store.dispatch('requests/loadRequestById', id)
      choosenId.value = id
      showModal.value = +id
    }

    const changeStatus = index => {
      activeIndex.value = index
      status.value = statusesList.value[index].status
      router.push({
        query: {
          ...route.query,
          status: status.value === 'working' ? undefined : status.value,
          page: undefined
        }
      })
    }

    const changeLimit = limit => {
      router.push({
        query: {
          ...route.query,
          limit: limit === 10 ? undefined : limit,
          page: +route.query.page === 1 ? undefined : route.query.page
        }
      })
    }

    const changePage = page => {
      router.push({
        query: {
          ...route.query,
          page: page === 1 ? undefined : page,
          limit: +route.query.limit === 10 ? undefined : route.query.limit
        }
      })
    }

    const setDecision = modal => {
      if (!modal) {
        const query = { status: route.query?.status === 'working' ? undefined : route.query?.status }
        if (prevPage.value) query.page = prevPage.value
        if (prevLimit.value) query.limit = prevLimit.value
        router.push({ query })
      }
      showModal.value = modal
      decision.value = modal
    }

    const setValues = values => {
      showModal.value = 'confirm'
      fields.value = values
    }

    watch(() => route.query, async (to, prev) => {
      if (showModal.value === null) {
        prevPage.value = route.query.page ? route.query.page : 1
        prevLimit.value = route.query.limit ? route.query.limit : 10
      }
      if (prev && to?.number !== prev?.number) {
        return
      }
      const data = {
        page: route.query.page || 1,
        limit: route.query.limit || 10,
        status: route.query.status || 'working',
        clientId: route.params.clientId,
        isAdmin: true
      }
      if (route.query.number) {
        await openDetailModal(route.query.number)
      }
      await store.dispatch('requests/loadRequests', data)
    }, { deep: false, immediate: true })

    return {
      statusesList, activeIndex, pages, isLoading, requests, request, status, showModal, choosenId, decision, fields, prevPage, prevLimit,
      openDetailModal, changeStatus, changeLimit, changePage, setDecision, setValues
    }
  }
}
</script>

<style lang="scss" scoped>
.requests-client {
  margin-top: 16px;

  &__tabs {
    margin-bottom: 24px;
  }

  &__pagination {
    margin-top: 32px;
  }
}
</style>
