<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <div class="search-page">
    <div v-if="!hints?.length" class="search-page__container">
      <v-title class="search-page__title">Результаты поиска</v-title>
      <div v-if="result?.length && !hints?.length">
        <ul>
          <search-card v-for="card in result" :key="card.id" :card="card"></search-card>
        </ul>
        <v-pagination
          class="search-page__pagination"
          :total="pages.total"
          :page="pages.page"
          :limitList="pages.limitList"
          :limit="+$route.query.limit || pages.limit"
          @changeLimit="changeLimit"
          @changePage="changePage"
        ></v-pagination>
      </div>
      <p v-else-if="!result?.length && !hints?.length && !isLoading" class="search-page__no-result">
        По вашему запросу ничего не найдено. Попробуйте уточнить название товара и повторить поиск или можете
        <span class="search-page__no-result-link" @click="$router.push({ name: 'request-create' })"> отправить нам запрос</span>
      </p>
    </div>
  </div>
  <div class="hints" v-if="hints?.length">
    <ul class="hints__list">
      <search-card v-for="card in hints" :key="card.id" :card="card"></search-card>
    </ul>
  </div>
</template>

<script>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { debounce } from 'debounce'

import SearchCard from '@/components/SearchCard'

export default {
  name: 'Search',
  components: { SearchCard },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const isLoading = computed(() => store.getters['search/isLoading'])
    const result = computed(() => store.getters['search/getResult'])
    const hints = computed(() => store.getters['search/getHints'])
    const pages = computed(() => store.getters['search/getPages'])
    const value = computed(() => store.getters['search/getValue'])
    const language = computed(() => store.getters['catalog/getLanguage'])
    const abortDebounce = computed(() => store.getters['search/getAbortDebounce'])

    const changeLimit = limit => {
      router.push({
        query: {
          ...route.query,
          limit: limit === 10 ? undefined : limit
        }
      })
    }

    const changePage = page => {
      router.push({
        query: {
          ...route.query,
          page: page === 1 ? undefined : page
        }
      })
    }

    const searchDebounce = debounce(cancel => {
      if (cancel) return
      router.push({
        query: undefined
      })
      store.dispatch('search/loadHints', { query: value.value, language: language.value })
    }, 700)

    watch([value, abortDebounce], () => {
      if (value.value.length > 2) {
        searchDebounce(abortDebounce.value)
      } else {
        searchDebounce(true)
      }
    }, { deep: true })

    watch(
      () => route.query,
      () => {
        if (route.fullPath.split('/').length > 2 && route.query.query) {
          const query = {
            query: route.query.query,
            language: language.value,
            page: route.query.page || 1,
            limit: route.query.limit || 10
          }

          store.dispatch('search/loadResult', query)
        }
      },
      { deep: true, immediate: true }
    )

    return {
      isLoading, result, hints, pages,
      changeLimit, changePage
    }
  }
}
</script>

<style lang="scss" scoped>
.search-page {
  &__container {
    min-width: 1100px;
    max-width: 1380px;
    min-height: calc(100vh - 348px);
    margin: auto;
    padding: 24px 100px 80px;
    background-color: $color-light-white;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__pagination {
    margin-top: 20px;
  }

  &__no-result {
    font-size: 22px;
    line-height: 29px;
    margin-top: 12px;

    &-link {
      color: $color-red;
      cursor: pointer;
    }
  }
}

.hints {
  width: 100%;
  min-height: calc(100vh - 348px);
  background-color: rgba($color: $color-black, $alpha: 0.2);
  padding: 16px 0;

  &__list {
    min-width: 900px;
    max-width: 1180px;
    margin: auto;
    padding: 12px 16px;
    background-color: $color-white;
  }
}
</style>
