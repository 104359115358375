const symbols = ['&lt;', '&gt;', '&amp;lt;', '&amp;gt;']

function replacer(symbol) {
  switch (symbol) {
    case symbols[0]:
    case symbols[2]:
      return '<'
    case symbols[1]:
    case symbols[3]:
      return '>'
    default:
      break
  }
}

export default function bracketConvert(text) {
  const regex = new RegExp(symbols.join('|'), 'g')
  return text.replace(regex, replacer)
}
