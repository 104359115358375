<template>
  <teleport to="body" v-if="isLoading">
    <v-spinner />
  </teleport>
  <v-modal v-if="showModal === 'blocked'" @closeModal="showModal = false" @submitModal="blockClient">
    <h3 class="client-edit-modal__title">Заблокировать клиента {{ name }}?</h3>
    <p class="client-edit-modal__subtitle">Пользователи клиента потеряют доступ к порталу, но клиент останется в списке</p>
    <v-border-button type="button" :size="'m'" text="Да" class="client-edit-modal__agree" @click="blockClient"></v-border-button>
    <v-button type="button" :size="'m'" text="Нет" class="client-edit-modal__cancel" @click="showModal = null"></v-button>
  </v-modal>
  <v-modal v-if="showModal === 'delete'" @closeModal="showModal = false" @submitModal="deleteClient">
    <h3 class="client-edit-modal__title">Удалить клиента {{ name }}?</h3>
    <p class="client-edit-modal__subtitle">Это действие не обратимо.</p>
    <v-border-button type="button" :size="'m'" text="Да" class="client-edit-modal__agree" @click="deleteClient"></v-border-button>
    <v-button type="button" :size="'m'" text="Нет" class="client-edit-modal__cancel" @click="showModal = null"></v-button>
  </v-modal>
  <form class="client-edit-form" @submit="onSubmit">
    <div class="client-edit-form__all-fields">
      <div class="client-edit-form__left">
        <v-input
          class="client-edit-form__field"
          placeholder="Название клиента"
          v-model="name"
          :error="nError"
          :handleChange="nChange"
          :mask="allSymbolsMask"
          :class-name="'name'"
          :maxlength="250"
          :disabled="isSubmitting || blocked"/>
        <v-input
          class="client-edit-form__field"
          placeholder="Домен"
          v-model="domain"
          :error="dError"
          :handleChange="dChange"
          :mask="domainMask"
          :class-name="'domain'"
          :maxlength="250"
          :disabled="isSubmitting || blocked"/>
        <div class="client-edit-form__field client-edit-form__loading client-edit-form__loading--logo">
          <v-file-picker
            v-model="logoFile"
            v-model:isChanged="isLogoFileWasChanged"
            name="logo"
            label="Логотип"
            :error="lfError"
            :handleChange="lfChange"
            :disabled="isSubmitting || blocked"
            accept=".jpg, .jpeg, .png"
            description="Горизонтальная версия логотипа для белого фона, jpg или png, будет в шапке сайта, высота — 30 px"
            ref="logoFileRef" />
          <v-button v-if="!logoFile" type="button" :size="'m'" text="Выбрать файл" class="client-edit-form__loading--button" :disabled="isSubmitting || blocked" @click="$refs.logoFileRef.pickerClickHandler()"></v-button>
          <v-border-button v-else type="button" :size="'m'" text="Удалить файл" class="client-edit-form__loading--button" :disabled="isSubmitting || blocked" @click="$refs.logoFileRef.deleteFile()"></v-border-button>
        </div>
        <v-input
          class="client-edit-form__field"
          placeholder="ИНН"
          v-model="inn"
          :error="innError"
          :handleChange="innChange"
          :mask="numberMask"
          :class-name="'inn'"
          :maxlength="12"
          :disabled="isSubmitting || blocked"/>
        <v-input
          class="client-edit-form__field"
          placeholder="КПП (не обязательно)"
          v-model="kpp"
          :error="kppError"
          :handleChange="kppChange"
          :mask="numberMask"
          :class-name="'kpp'"
          :maxlength="12"
          :disabled="isSubmitting || blocked"/>
        <v-input
          class="client-edit-form__field"
          placeholder="ID проекта в MCF"
          v-model="idUrPerson"
          :error="idUrError"
          :handleChange="idUrChange"
          :mask="numberMask"
          :class-name="'idUrPerson'"
          :maxlength="50"
          :disabled="isSubmitting || blocked"/>
        <v-input
          class="client-create-form__field"
          placeholder="ID витрины"
          v-model="showcaseId"
          :error="idScError"
          :handleChange="idScChange"
          :mask="allSymbolsMask"
          :class-name="'idUrPerson'"
          :maxlength="50"
          :disabled="isSubmitting || blocked"/>
        <v-textarea
          class="client-edit-form__field client-edit-form__textarea"
          label="Юридический адрес"
          v-model="address"
          :error="aError"
          :handleChange="aChange"
          :mask="allSymbolsMask"
          :maxlength="250"
          :disabled="isSubmitting || blocked"/>
        <v-input
          class="client-edit-form__field client-edit-form__phone"
          placeholder="Номер телефона"
          :maxlength="250"
          v-model="phone"
          :error="pError"
          :handleChange="pChange"
          :mask="allSymbolsMask"
          :class-name="'phone'"
          :disabled="isSubmitting || blocked" />
      </div>
      <div class="client-edit-form__divider"></div>
      <div class="client-edit-form__right">
        <v-input
          class="client-edit-form__field"
          placeholder="Номер договора"
          v-model="contract"
          :error="cnError"
          :handleChange="cnChange"
          :mask="allSymbolsMask"
          :class-name="'contract'"
          :maxlength="50"
          :disabled="isSubmitting || blocked" />
        <div class="client-edit-form__field client-edit-form__loading client-edit-form__loading--file">
          <v-file-picker
            v-model="contractFileId"
            v-model:isChanged="isContractFileWasChanged"
            name="file"
            label="Файл договора (не обязательно)"
            :error="cfError"
            :handleChange="cfChange"
            :disabled="isSubmitting || blocked"
            accept=".doc, .docx, .pdf"
            description="Формат: doc, pdf. Размер файла: не более 10 мб. "
            ref="contractFileRef" />
          <v-button v-if="!contractFileId" type="button" :size="'m'" text="Выбрать файл" class="client-edit-form__loading--button" :disabled="isSubmitting || blocked" @click="$refs.contractFileRef.pickerClickHandler()"></v-button>
          <v-border-button v-else type="button" :size="'m'" text="Удалить файл" class="client-edit-form__loading--button" :disabled="isSubmitting || blocked" @click="$refs.contractFileRef.deleteFile()"></v-border-button>
        </div>
        <div class="client-edit-form__field client-edit-form__contract">
          <p class="client-edit-form__contract--title">Действие договора:</p>
          <div class="client-edit-form__contract--dataPickers">
            <v-calendar
              class="client-edit-form__contract--start"
              v-model:date="startContract"
              placeholder="Дата начала"
              className="start"
              :error="scError"
              :handleChange="scChange"
              :disabled="isSubmitting || blocked" />
            <span class="client-edit-form__contract--divider">—</span>
            <v-calendar
              class="client-create-form__contract--end"
              v-model:date="endContract"
              placeholder="Дата окончания"
              className="end"
              :error="ecError"
              :handleChange="ecChange"
              :disabled="isSubmitting || blocked" />
          </div>
        </div>
        <v-radio-group
          class="client-edit-form__field client-edit-form__radio"
          v-model="paymentType"
          :error="ptError"
          :handleChange="ptChange"
          title="Тип оплаты:"
          :list="paymentList"
          :disabled="isSubmitting || blocked" />
        <v-input
          v-if="paymentType === 'postpayment'"
          class="client-edit-form__field"
          placeholder="Лимит"
          v-model="limitPostpay"
          :error="lError"
          :handleChange="lChange"
          :mask="notZeroStart"
          :class-name="'limitPostpay'"
          :disabled="isSubmitting || blocked" />
        <v-input
          v-if="paymentType === 'postpayment'"
          class="client-edit-form__field"
          placeholder="Постоплата: количество дней"
          v-model="limitDays"
          :error="ldError"
          :handleChange="ldChange"
          :mask="notZeroStart"
          :class-name="'limitDays'"
          :disabled="isSubmitting || blocked" />
        <v-select
          class="client-edit-form__field client-edit-form__select"
          label="Ответственный со стороны клиента (не обязательно)"
          v-model="clientResponsibles[index]"
          :error="mrError"
          :handleChange="mrChange"
          :options="employeesList"
          :disabled="isSubmitting || blocked"
          v-for="(item, index) in clientResponsibles"
          :key="item"
          :disabledIndexes="clientResponsibles" />
        <p
          v-if="(clientResponsibles[clientResponsibles.length - 1] || clientResponsibles[clientResponsibles.length - 1] === 0) && clientResponsibles.length + 1 !== employeesList.length"
          class="client-edit-form__add"
          @click="clientResponsibles.push('')"
        >+ Добавить ещё ответственного</p>
        <v-select
          class="client-edit-form__field"
          label="Ответственный со стороны Softline"
          v-model="mainAdministrator"
          :error="maError"
          :handleChange="maChange"
          :options="adminsList"
          :disabled="isSubmitting || blocked" />
      </div>
    </div>
    <div class="client-edit-form__errors" v-if="allErrors[0]">
      <v-error v-for="(error, index) in allErrors" :key="index" class="client-edit-form__error" :text="textError(error)"/>
    </div>
    <div class="client-edit-form__buttons">
      <v-button type="submit" :size="'l'" :disabled="isSubmitting || blocked" text="Сохранить"
                class="client-edit-form__save"/>
      <div class="client-edit-form__control">
        <v-border-button type="button" :size="'m'" :disabled="isSubmitting || blocked" :text="!client?.value?.disabled ? 'Заблокировать' : 'Разблокировать'"
                         class="client-edit-form__blocked" @click="onSubmitBlocked"/>
        <!-- <v-border-button type="button" :size="'m'" :disabled="isSubmitting" text="Удалить"
                  class="client-edit-form__delete" @click="onSubmitDelete"></v-border-button> -->
      </div>
    </div>
  </form>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

import { numberMask, domainMask, notZeroStart, allSymbolsMask } from '@/utils/inputMasks'
import { textError } from '@/utils/utils'
import { checkFormats, maxSizeMb } from '@/utils/yup-helpers'
import dateToTimestamp from '@/utils/dateToTimestamp'

const serverErrorText = 'Ошибка сервера'

const PAYMENT_LIST = [
  { id: 1, name: 'prepayment', value: 'prepayment', text: 'Предоплата' },
  { id: 2, name: 'postpayment', value: 'postpayment', text: 'Постоплата' }
]

export default {
  name: 'ClientEditForm',
  setup() {
    const showModal = ref(null)
    const logoFileRef = ref(null)
    const contractFileRef = ref(null)
    const serverError = ref(null)
    const isLogoFileWasChanged = ref(false)
    const isContractFileWasChanged = ref(false)
    const blocked = ref()
    const clientResponsibles = ref([''])
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const client = computed(() => store.getters['clients/getClient'])
    const isLoading = computed(() => store.getters['clients/isLoading'])

    const schema = yup.object({
      name: yup.string()
        .trim()
        .min(2, 'Название клиента не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      domain: yup.string()
        .trim()
        .min(2, 'Название домена не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      logoFile: yup.mixed()
        .required('Заполнены не все обязательные поля')
        .test('size', 'Максимальный размер не более 5Мб', maxSizeMb(5))
        .test('format', 'Неверный формат файла', checkFormats('.jpg, .png, .jpeg')),
      inn: yup.string()
        .trim()
        .required('Заполнены не все обязательные поля'),
      kpp: yup.string()
        .trim(),
      idUrPerson: yup.string()
        .required('Заполнены не все обязательные поля'),
      showcaseId: yup.string()
        .required('Заполнены не все обязательные поля'),
      address: yup.string()
        .trim()
        .min(2, 'Юридический адрес не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      phone: yup.string()
        .min(2, 'Номер телефона не может быть меньше 2 символов')
        .required('Заполнены не все обязательные поля'),
      contract: yup.string()
        .trim()
        .required('Заполнены не все обязательные поля'),
      contractFileId: yup.mixed()
        .notRequired()
        .test('size', 'Максимальный размер не более 10Мб', maxSizeMb(10))
        .test('format', 'Неверный формат файла', checkFormats('.doc, .docx, .pdf')),
      startContract: yup.number()
        .required('Заполнены не все обязательные поля')
        .positive('Пожалуйста, введите дату начала договора'),
      endContract: yup.number()
        .required('Заполнены не все обязательные поля')
        .positive('Пожалуйста, введите дату окончания договора')
        .moreThan(yup.ref('startContract'), 'Дата окончания не может быть меньше начальной'),
      paymentType: yup.string()
        .required('Заполнены не все обязательные поля'),
      limitPostpay: yup.mixed()
        .nullable()
        .when('paymentType',
          {
            is: 'postpayment',
            then: yup.number()
              .nullable()
              .typeError('Заполнены не все обязательные поля')
              .required('Заполнены не все обязательные поля')
              .min(100, 'Лимит не может быть меньше 100')
              .max(100000, 'Лимит не может быть больше 100000')
          }
        ),
      limitDays: yup.mixed()
        .nullable()
        .when('paymentType',
          {
            is: 'postpayment',
            then: yup.number()
              .nullable()
              .typeError('Заполнены не все обязательные поля')
              .required('Заполнены не все обязательные поля')
              .min(1, 'Лимит не может быть меньше 1')
              .max(100000, 'Лимит не может быть больше 100000')
          }
        ),
      mainResponsibles: yup.array()
        .nullable(),
      mainAdministrator: yup.string()
        .required('Заполнены не все обязательные поля')
    })

    const { handleSubmit, isSubmitting, setFieldValue, errors } = useForm({ validationSchema: schema })

    const { value: name, errorMessage: nError, handleChange: nChange } = useField('name', undefined, { validateOnValueUpdate: false })
    const { value: domain, errorMessage: dError, handleChange: dChange } = useField('domain', undefined, { validateOnValueUpdate: false })
    const { value: logoFile, errorMessage: lfError, handleChange: lfChange } = useField('logoFile')
    const { value: inn, errorMessage: innError, handleChange: innChange } = useField('inn', undefined, { validateOnValueUpdate: false })
    const { value: kpp, errorMessage: kppError, handleChange: kppChange } = useField('kpp', undefined, { validateOnValueUpdate: false })
    const { value: idUrPerson, errorMessage: idUrError, handleChange: idUrChange } = useField('idUrPerson', undefined, { validateOnValueUpdate: false })
    const { value: showcaseId, errorMessage: idScError, handleChange: idScChange } = useField('showcaseId', undefined, { validateOnValueUpdate: false })
    const { value: address, errorMessage: aError, handleChange: aChange } = useField('address', undefined, { validateOnValueUpdate: false })
    const { value: phone, errorMessage: pError, handleChange: pChange } = useField('phone', undefined, { validateOnValueUpdate: false })
    const { value: contract, errorMessage: cnError, handleChange: cnChange } = useField('contract', undefined, { validateOnValueUpdate: false })
    const { value: contractFileId, errorMessage: cfError, handleChange: cfChange } = useField('contractFileId', undefined, { validateOnValueUpdate: false })
    const { value: startContract, errorMessage: scError, handleChange: scChange } = useField('startContract', undefined, { validateOnValueUpdate: false })
    const { value: endContract, errorMessage: ecError, handleChange: ecChange } = useField('endContract', undefined, { validateOnValueUpdate: false })
    const { value: paymentType, errorMessage: ptError, handleChange: ptChange } = useField('paymentType', undefined, { validateOnValueUpdate: false })
    const { value: limitPostpay, errorMessage: lError, handleChange: lChange } = useField('limitPostpay', undefined, { validateOnValueUpdate: false })
    const { value: limitDays, errorMessage: ldError, handleChange: ldChange } = useField('limitDays', undefined, { validateOnValueUpdate: false })
    const { value: mainResponsibles, errorMessage: mrError, handleChange: mrChange } = useField('mainResponsibles', undefined, { validateOnValueUpdate: false })
    const { value: mainAdministrator, errorMessage: maError, handleChange: maChange } = useField('mainAdministrator', undefined, { validateOnValueUpdate: false })

    const setInitialValues = () => {
      setFieldValue('name', client.value.name)
      setFieldValue('domain', client.value.domain)
      setFieldValue('logoFile', new File([client.value.logo.id], client.value.logo.filename))
      setFieldValue('inn', client.value.inn)
      setFieldValue('kpp', client.value.kpp)
      setFieldValue('idUrPerson', client.value.idUrPerson)
      setFieldValue('showcaseId', client.value.showcaseId)
      setFieldValue('address', client.value.address)
      setFieldValue('phone', client.value.phone)
      setFieldValue('contract', client.value.contract)
      setFieldValue('contractFileId', client.value.contractFileId.id ? new File([client.value.contractFileId.id], client.value.contractFileId.filename) : '')
      setFieldValue('startContract', dateToTimestamp(client.value.startContract))
      setFieldValue('endContract', dateToTimestamp(client.value.endContract))
      setFieldValue('paymentType', client.value.paymentType)
      setFieldValue('limitPostpay', client.value.limitPostpay)
      setFieldValue('limitDays', client.value.limitDays)
      setFieldValue('mainResponsibles', client.value.mainResponsibles)
      setFieldValue('mainAdministrator', client.value.mainAdministrator.id)
    }

    onMounted(async () => {
      await store.dispatch('clients/loadClientById', route.params.clientId)
      clientResponsibles.value = client.value.mainResponsibles.length > 0 ? client.value.mainResponsibles.map(el => el.id) : ['']
    })
    onBeforeMount(() => store.dispatch('admins/loadResponsibles'))
    onBeforeMount(() => store.dispatch('employees/loadResponsibles', route.params.clientId))

    const admins = computed(() => store.getters['admins/getAdmins'])
    const employees = computed(() => store.getters['employees/getEmployees'])

    const adminsList = computed(() => {
      const list = [{ id: -1, value: 'reset', text: 'Не выбрано' }]
      admins.value.forEach(admin => {
        list.push({ id: admin.id, value: admin.id, text: admin.name })
      })
      return list
    })

    const employeesList = computed(() => {
      const list = [{ id: -1, value: 'reset', text: 'Не выбрано' }]
      employees.value.forEach(employee => {
        list.push({ id: employee.id, value: employee.id, text: employee.name })
      })
      return list
    })

    watch(client, () => {
      setInitialValues()
      blocked.value = client.value.status === 'blocked'
    })

    const paymentList = ref(PAYMENT_LIST)

    const allErrors = computed(() => [...new Set(Object.values(errors.value)), serverError.value].filter(Boolean))

    const serverErrorHandler = response => {
      if (/4\d+/.test(response?.status)) {
        serverError.value = response?.data.message || response?.data.error || serverErrorText
      } else if (/5\d+/.test(response?.status)) {
        serverError.value = serverErrorText
      } else {
        return true
      }
    }

    const closePageOrShowError = response => {
      const noError = serverErrorHandler(response)
      noError && router.push({ name: 'clients' })
    }

    const onSubmit = handleSubmit(async values => {
      try {
        const newValues = {
          ...values,
          id: route.params.clientId,
          status: client.value.status,
          mainResponsibles: clientResponsibles.value.filter(el => el),
          filesInfo: {
            isLogoFileWasChanged: isLogoFileWasChanged.value,
            isContractFileWasChanged: isContractFileWasChanged.value,
            prevLogoId: client.value.logo.id,
            prevContractId: client.value.contractFileId.id
          }
        }
        const clientId = route.params.clientId
        const response = await store.dispatch('clients/editClient', newValues)
        const noError = serverErrorHandler(response)
        noError && router.push({ name: 'client', params: { clientId } })
      } catch (error) {
        console.log(error)
      }
    })

    const blockClient = async () => {
      try {
        const response = await store.dispatch('clients/blockClient', client.value.id)
        closePageOrShowError(response)
      } catch (error) {
        console.log(error)
      }
    }

    const deleteClient = async () => {
      try {
        const response = await store.dispatch('clients/deleteClient', client.value.id)
        closePageOrShowError(response)
      } catch (error) {
        console.log(error)
      }
    }

    const onSubmitBlocked = () => {
      showModal.value = 'blocked'
    }
    const onSubmitDelete = () => {
      showModal.value = 'delete'
    }

    return {
      isLogoFileWasChanged, isContractFileWasChanged,
      client, numberMask, domainMask, notZeroStart, allSymbolsMask, textError,
      clientResponsibles, paymentList, adminsList, employeesList, isLoading, showModal, blocked,
      onSubmit, onSubmitBlocked, onSubmitDelete, allErrors, isSubmitting, blockClient, deleteClient,
      logoFileRef, contractFileRef,
      name, nError, nChange,
      domain, dError, dChange,
      logoFile, lfError, lfChange,
      inn, innError, innChange,
      kpp, kppError, kppChange,
      idUrPerson, idUrError, idUrChange,
      showcaseId, idScError, idScChange,
      address, aError, aChange,
      phone, pError, pChange,
      contract, cnError, cnChange,
      contractFileId, cfError, cfChange,
      startContract, scError, scChange,
      endContract, ecError, ecChange,
      paymentType, ptError, ptChange,
      limitPostpay, lError, lChange,
      limitDays, ldError, ldChange,
      mainResponsibles, mrError, mrChange,
      mainAdministrator, maError, maChange
    }
  }
}
</script>

<style lang="scss" scoped>
.client-edit-modal {
  &__title {
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    color: $color-black;
    margin-bottom: 4px;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 14px;
    color: $color-black;
    margin-bottom: 16px;
  }

  &__agree {
    margin-right: 8px;
  }
}

.client-edit-form {
  &__all-fields {
    display: flex;
    justify-content: space-between;
  }

  &__field {
    width: 550px;
  }

  &__loading {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;

    &--button {
      align-self: center;
      margin-left: 8px;
    }
  }

  &__textarea,
  &__contract,
  &__select,
  &__radio {
    margin-bottom: 16px;
  }

  &__phone {
    margin-bottom: 0;
  }

  &__divider {
    width: 1px;
    border: 1px solid $color-border-grey;
  }

  &__contract {
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 21px;
    color: $color-black;

    &--title {
      margin-right: 34px;
      white-space: nowrap;
    }

    &--dataPickers {
      display: flex;
      align-items: center;
    }

    &--start {
      width: 177px;
    }

    &--divider {
      margin: 0 8px;
    }
  }

  &__add {
    width: fit-content;
    font-size: 12px;
    line-height: 14px;
    color: $color-red;
    padding: 4px 0;
    margin: -12px 0 12px;
    cursor: pointer;
  }

  &__errors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
    margin-bottom: -38px;
  }

  &__error {
    white-space: nowrap;
    margin-right: 4px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 36px;
  }

  &__blocked {
    margin-right: 12px;
  }
}
</style>
